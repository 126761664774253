import React, {useEffect, useState} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
    ChartData
} from "chart.js";
import {Bar} from "react-chartjs-2";
import {isMobile} from "react-device-detect";
import CustomSelect from "../components/common/CustomSelect";
import {allMonths, allYears} from "../mock-data/insuranceTypes";
import {ChartComponentProps, AnalyticsYears} from "../typings/AnalyticsChart";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AnalyticsChartComponent = ({mainText, legendPosition = "top", scalesXText, scalesYText, analytics, datasets}: ChartComponentProps) => {
    const allAnaliticsYears: AnalyticsYears[] = allYears; 
    const currentYear = allAnaliticsYears.find((year) => new Date().getFullYear() === year.value);
    const [selectedYear, setSelectedYear] = useState<AnalyticsYears | undefined>(currentYear);
    const [chartData, setChartData] = useState<ChartData<"bar">>({labels: allMonths, datasets: []});

    const chartOptions: ChartOptions<"bar"> = {
        responsive: true,
        maintainAspectRatio: !isMobile ? true : false,
        plugins: {
            title: {
                display: true,
                text: mainText
            },
            legend: {
                position: legendPosition
            },
            tooltip: {
                enabled: true
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: scalesXText
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1
                },
                title: {
                    display: true,
                    text: scalesYText
                }
            }
        }
    };

    const handleAnalyticsYearChange = (data) => {
        setSelectedYear(data);
    };

    const filterByYear = (year) => {
        const filteredData = allMonths.map((_, index) => {
            const monthData = analytics.find((data) => Number(data.month.split("-")[1]) - 1 === index && data.month.startsWith(year.value));
            return monthData || {};
        });

        const analyticsDatasets = datasets.map((dataset) => ({
            label: dataset.label,
            data: filteredData.map((data) => data[dataset.key]),
            backgroundColor: dataset.backgroundColor
        }));

        const updatedData: ChartData<"bar"> = {
            labels: allMonths,
            datasets: analyticsDatasets
        };

        setChartData(updatedData);
    };

    useEffect(() => {
        filterByYear(selectedYear);
    }, [selectedYear]);

    return (
        <div className="analytics-wrapper">
            <div className="analytics-select">
                <label>Виберіть рік</label>
                <CustomSelect
                    options={allYears}
                    handleChange={handleAnalyticsYearChange}
                    value={selectedYear}
                    isCreatable={false}
                    isDisabled={false}
                    placeholder="Натисніть тут"
                />
            </div>
            <div className="analytics-chart">
                <Bar data={chartData} options={chartOptions} />
            </div>
        </div>
    );
};

export default AnalyticsChartComponent;