export type companyImage = {
  id: number,
  key: string
}

export const companyImages = [
  {
      id: 68,
      key: "35.webp"
  },
  {
      id: 31,
      key: "5.webp"
  },
  {
    id: 254,
    key: "4.webp"
  },
  {
    id: 85,
    key: "2.webp"
  },
  {
    id: 75,
    key: "20.webp"
  },
  {
    id: 61,
    key: "37.webp"
  },
  {
    id: 456,
    key: "26.webp"
  },
  {
    id: 67,
    key: "27.webp"
  },
  {
    id: 65,
    key: "33.webp"
  },
  {
    id: 73,
    key: "6.webp"
  },
  {
    id: 66,
    key: "11.webp"
  },
  {
    id: 90,
    key: "8.webp"
  },
  {
    id: 52,
    key: "10.webp"
  },
  {
    id: 221,
    key: "16.webp"
  },
  {
    id: 88,
    key: "28.webp"
  },
  {
    id: 8,
    key: "1.webp"
  },
  {
    id: 10,
    key: "17.webp"
  },
  {
    id: 47,
    key: "15.webp"
  },
  {
    id: 38,
    key: "21.webp"
  },
  {
    id: 35,
    key: "14.webp"
  },
  {
    id: 27,
    key: "9.webp"
  },
  {
    id: 182,
    key: "29.webp"
  },
  {
    id: 79,
    key: "34.webp"
  },
  {
    id: 69,
    key: "22.webp"
  },
  {
    id: 55,
    key: "36.webp"
  },
  {
    id: 491,
    key: "7.webp"
  },
  {
    id: 461,
    key: "12.webp"
  },
  {
    id: 181,
    key: "13.webp"
  },
  {
    id: 59,
    key: "18.webp"
  },
  {
    id: 417,
    key: "19.webp"
  },
  {
    id: 245,
    key: "24.webp"
  },
  {
    id: 469,
    key: "25.webp"
  },
  {
    id: 492,
    key: "30.webp"
  },
  {
    id: 496,
    key: "31.webp"
  },
  {
    id: 91,
    key: "32.webp"
  },
  {
    id: 28,
    key: "38.webp"
  },
  {
    id: 224,
    key: "39.webp"
  },
  {
    id: 89,
    key: "40.webp"
  }
]