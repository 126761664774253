export const patternEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const getInstanceError = (errors: any) => {
  const getMessageError = (name: string) => {
    if (!errors[name]) {
        return null;
    }

    return errors[name]!.message || (errors[name].types && errors[name].types.message);
  };

  const getClassError = (name: string, touch: boolean) => {
    if (getMessageError(name)) {
        return "form-group form-group-error"
    } else {
        if (touch) {
            return "form-group form-group-success"
        } else {
            return "form-group"
        }
    }
  };

  return {
    getMessageError,
    getClassError
  }
};