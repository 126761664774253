import {IFeatureVehicle} from "../redux-data/insurance/insuranceTypes";
import {vehicleTypes} from "./greenCardData";

export const typeVehicle = [
  {
    id: 1,
    value: 1,
    label: "Легкове авто",
    name: "B",
    kind: "CAR"
  },
  {
    id: 2,
    value: 2,
    label: "Вантажне авто",
    name: "C",
    kind: "FREIGHT"
  },
  {
    id: 3,
    value: 3,
    label: "Автобус",
    name: "D",
    kind: "PASSENGER"
  },
  {
    id: 4,
    value: 4,
    label: "Мотоцикл",
    name: "A",
    kind: "MOTO"
  },
  {
    id: 5,
    value: 5,
    label: "Причіп",
    name: "",
    kind: "TRAILER" 
  }
];

export const featureVehicle: IFeatureVehicle = {
  "1" : {
    name: "Обсяг",
    options: [
      {
        value: "1",
        label: "до 1600 куб.см"
      },
      {
        value: "2",
        label: "1601-2000 куб.см"
      },
      {
        value: "3",
        label: "2001-3000 куб.см"
      },
      {
        value: "4",
        label: "вiд 3000 куб.см"
      },
      {
        value: "5",
        label: "Легковий електромобіль"
      }
    ]
  },
  "2" : {
    name: "Вантажопідйомність",
    options: [
      {
        value: "1",
        label: "до 2-х тонн"
      },
      {
        value: "2",
        label: "вiд 2-х тонн"
      },
    ]
  },
  "3" : {
    name: "Кількість пасажирів",
    options: [
      {
        value: "1",
        label: "до 20 людей"
      },
      {
        value: "2",
        label: "від 20 людей"
      },
    ]
  },
  "4" : {
    name: "Обсяг",
    options: [
      {
        value: "1",
        label: "до 300 куб.см"
      },
      {
        value: "2",
        label: "вiд 300 куб.см"
      },
    ]
  },
  "5" : {
    name: "До якого автомобілю причіп",
    options: [
      {
        value: "F",
        label: "до легкових ТЗ"
      },
      {
        value: "E",
        label: "до вантажних ТЗ"
      },
    ]
  }
};

export const vehicleCategories = [
  {
    id: 1,
    label: "Мотоцикл",
    kind: "MOTO",
    name: "Обсяг",
    options: [
      {
        value: "A1",
        label: "до 300 куб.см"
      },
      {
        value: "A2",
        label: "вiд 300 куб.см"
      },
    ]
  },
  {
    id: 2,
    label: "Легкове авто",
    kind: "CAR",
    name: "Обсяг",
    options: [
      {
        value: "B1",
        label: "до 1600 куб.см"
      },
      {
        value: "B2",
        label: "1601-2000 куб.см"
      },
      {
        value: "B3",
        label: "2001-3000 куб.см"
      },
      {
        value: "B4",
        label: "вiд 3000 куб.см"
      },
      {
        value: "B5",
        label: "Легковий електромобіль"
      }
    ]
  },
  {
    id: 3,
    label: "Вантажне авто",
    kind: "FREIGHT",
    name: "Вантажопідйомність",
    options: [
      {
        value: "C1",
        label: "до 2-х тонн"
      },
      {
        value: "C2",
        label: "вiд 2-х тонн"
      },
    ]
  },
  {
    id: 4,
    label: "Автобус",
    kind: "PASSENGER",
    name: "Кількість пасажирів",
    options: [
      {
        value: "D1",
        label: "до 20 людей"
      },
      {
        value: "D2",
        label: "від 20 людей"
      },
    ]
  },
  {
    id: 5,
    label: "Причіп",
    kind: "TRAILER",
    name: "До якого автомобілю причіп",
    options: [
      {
        value: "F",
        label: "до легкових ТЗ"
      },
      {
        value: "E",
        label: "до вантажних ТЗ"
      },
    ] 
  }
]

export const getTypeAutoByName = (name: string) => {
  return typeVehicle.find(item => item.name === name)!;
};

export const getTypeAutoByValue = (value: number) => {
  return typeVehicle.find(item => item.value === value)!;
};

export const getOptionByValue = (value: string, options: any[]) => {
  return options.find(item => item.value === value)!;
};

export const getNameById = (id: any) => {
  const type = typeVehicle.find(item => item.id === id)!;
  return type.name;
};

export const getLabelById = (id: any) => {
  const type = typeVehicle.find(item => item.id === id)!;
  return type.label;
};

export const getKindById = (id: any) => {
  const type = typeVehicle.find(item => item.id === id)!;
  return type.kind;
};

export const getOptionsByKind = (kind: any) => {
  const vehicleCategory = vehicleCategories.find((category) => category.kind === kind)!;
  return vehicleCategory.options;
};

export const getKindByType = (type: any) => {
  return vehicleCategories.find(category => category.options.find((option) => type === option.value))!.kind;
};

export const getTypeLetter = (type: any) => {
  return type[0];
};

export const getGreenCardType = (type: any) => {
  const vehicleType = getTypeLetter(type);
  return vehicleTypes.find(type => type.subcategories!.find((subcategory) => subcategory === vehicleType));
};

export const getVehicleCategoryByType = (type: any) => {
  return vehicleCategories.find((category) => category.kind === type.value);
};

//{
//  name: "Автобус",
//  label: "до 20 людей",
//}
export const getInfoByType = (type: string) => {  
  if (type.length === 2) {
      const symbol = type[0];
      const number = type[1];

      const {id: idTypeAuto, label} = getTypeAutoByName(symbol);
      const feature = featureVehicle[idTypeAuto];
      const option = getOptionByValue(number, feature.options);
      
      if (option) {
          return {
            name: feature.name,
            label: option.label,
            type_auto: label,
          }
      } else {
          return {
            name: feature.name,
            label: "Такої категорії не знайдено",
            type_auto: label,
          }
      }
  } else if (type.length === 1) {
      const symbol = type[0];
      const number = 5;
      const feature = featureVehicle[number];
      const option = getOptionByValue(symbol, feature.options);
      const { label } = getTypeAutoByValue(number);

      if (option) {
          return {
            name: feature.name,
            label: option.label,
            type_auto: label
          }
      } else {
          return {
            name: feature.name,
            label: "Такої категорії не знайдено",
            type_auto: label
          }
      }
  }

  throw new Error("Incorrect type. It must be 2 symbols")
};
