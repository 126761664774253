export const insuranceTypes = [
  {value: "epolicy", label: "Автоцивілка"},
  {value: "greencard", label: "Зелена карта"}
];

export const websiteDocuments = [
    {value: "PASSPORT", label: "Паспорт", isDisabled: false},
    {value: "ID_PASSPORT", label: "ID карта", isDisabled: false},
    {value: "DRIVING_LICENSE", label: "Посвідчення водія", isDisabled: false},
    {value: "EXTERNAL_PASSPORT", label: "Закордонний паспорт", isDisabled: false}
];

export const diiaDocuments = [
    {value: "internal-passport", label: "ID карта", isDisabled: false},
    {value: "driver-license", label: "Посвідчення водія", isDisabled: false},
    {value: "foreign-passport", label: "Закордонний паспорт", isDisabled: false},
    {value: "vehicle-license", label: "Техпаспорт", isDisabled: false}
];

export const bankIDDocuments = [
    {value: "passport", label: "Паспорт", isDisabled: false},
    {value: "idpassport", label: "ID карта", isDisabled: false},
    {value: "zpassport", label: "Закордонний паспорт", isDisabled: false}
];

export const privilegeTypes = [
    {value: "PENSION_CERTIFICATE", label: "Пенсійне посвідчення", description: "PENSIONER", isDisabled: false},
    {value: "E_PENSION_CERTIFICATE", label: "Електронне пенсійне посвідчення", description: "PENSIONER", isDisabled: false},
    {value: "DISABILITY_CERTIFICATE", label: "Посвідчення про інвалідність", description: "DISABLED", isDisabled: false},
    {value: "VETERAN_CERTIFICATE", label: "Посвідчення учасника війни", description: "PENSIONER", isDisabled: false},
    {value: "CHERNOBYLETS", label: "Чорнобильське посвідчення", description: "CHERNOBYLETS", isDisabled: false}
];

export const allPolicyStatus = [
    {value: "DRAFT", label: "Чернетка", isDisabled: false},
    {value: "REQUEST", label: "Заявлений", isDisabled: false},
    {value: "SENDING_OTP", label: "Відправлення коду", isDisabled: false},
    {value: "SENDED_OTP", label: "Код відправлено", isDisabled: false},
    {value: "PAY_OFFER", label: "Оплата поліса", isDisabled: false},
    {value: "PAID_OFFER", label: "Поліс оплачено", isDisabled: false},
    {value: "EMITTED", label: "Укладений", isDisabled: false},
];

export const insuranceSteps = [
    {value: 1, label: "Введіть дані автомобіля"},
    {value: 2, label: "Виберіть пропозицію"},
    {value: 3, label: "Виберіть документ"},
    {value: 4, label: "Заповніть документ"},
    {value: 5, label: "Сплачуйте онлайн"}
];

export const documentServices = [
    {value: "BANKID", label: "БанкІД"},
    {value: "DIIA", label: "Дія"},
    {value: "PESS", label: "Сайт"}
];

export const allYears = [
    {value: 2024, label: "2024", isDisabled: false}, 
    {value: 2025, label: "2025", isDisabled: false}, 
    {value: 2026, label: "2026", isDisabled: true}, 
    {value: 2027, label: "2027", isDisabled: true}, 
    {value: 2028, label: "2028", isDisabled: true}
];

export const allMonths = ["Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"];