import React from "react";

const RefreshDiiaDeepLink = ({diiaClassName, isMobileDevice, onRefreshDeepLink}) => {
    return (
        <div className={diiaClassName}>
            <button type="button" className="insurance-btn" onClick={onRefreshDeepLink}><i className="fa-regular fa-arrows-rotate notification-icon"></i>{isMobileDevice ? "Оновити посилання" : "Оновити QR-код"}</button>
        </div>
    );
};

export default RefreshDiiaDeepLink;