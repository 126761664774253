import React, {useState, useRef, useEffect} from "react";
import ICustomTextarea from "../../typings/ICustomTextarea";
import CustomTooltip from "../Forms/common/CustomTooltip";
import CustomSelect from "../common/CustomSelect";
import {bbcodeColors} from "../../mock-data/bbcodeTypes";
import ImageZoomComponent from "../Forms/common/ImageZoomComponent";
import {insuranceProxyAxiosInstance} from "../../core/configInsuranceProxyAxios";

const CustomTextarea = ({name, label, isDisabled, value = "", placeholder = "", isBBCode, isFocus, rows, isCopy, setDataCopiedToClipboard, setDataChanged, isClear, setDataCleared, ...rest}: ICustomTextarea) => {
    const [touch, setTouch] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const [textareaValue, setTextareaValue] = useState("");
    const [selectedColor, setSelectedColor] = useState(bbcodeColors[0]);
    const [selectedImages, setSelectedImages] = useState<any>([]);
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);
    const [selectedImagesErrorMsg, setSelectedImagesErrorMsg] = useState("");
    const [selectedImagesSuccessMsg, setSelectedImagesSuccessMsg] = useState("");

    useEffect(() => {
        setTextareaValue(value);

        if (isFocus && textareaRef.current) {
            textareaRef.current.focus();

            if (textareaRef.current.value.length === 0) {
                textareaRef.current.select();
            }
        }

        if (isCopy && textareaRef.current) {
            if (textareaRef.current.value.length > 0) {
                textareaRef.current.select();
                navigator.clipboard.writeText(textareaRef.current.value);
                setDataCopiedToClipboard?.();
            }
        }

        if (isClear && textareaRef.current) {
            if (textareaRef.current.value.length > 0) {
                textareaRef.current.value = "";
                setTextareaValue("");
                checkTextareaData(textareaRef.current.value);
                setDataCleared?.();
            }
        }
    }, [value, isFocus, isCopy, isClear]);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification, hideSuccessNotification]);

    if (isDisabled) {
        return null;
    }

    const handelBlur = () => {
        checkTextareaData(textareaRef.current?.value);
    };

    const checkTextareaData = (data) => {
        setTouch(true);

        if (data.trim().length === 0) { 
            setErrorMsg("Це поле обов'язкове");
        } else {
            setErrorMsg("");
        }
    };

    const handleChange = (event) => {
        const data = event.target.value;
        setTextareaValue(data);
        checkTextareaData(data);
        setDataChanged?.(data);
    };

    const handleBBCodeButtonClick = (bbcodeTag, additionalTag = "") => {    
        textareaRef.current?.focus();
        const start = textareaRef.current?.selectionStart ?? 0;
        const end = textareaRef.current?.selectionEnd ?? 0;
        const text = textareaRef.current?.value ?? "";

        let wordStart = start;
        while (wordStart > 0 && text.charAt(wordStart - 1).match(/\b\S/)) {
            wordStart--;
        }
        
        let wordEnd = end;
        while (wordEnd < text.length && text.charAt(wordEnd).match(/\b\S/)) {
            wordEnd++;
        }

        const selectedText = text.substring(wordStart, wordEnd);
        let newData = "";
        
        if (bbcodeTag === "hr") {
            newData = text.substring(0, wordStart) + `[${bbcodeTag}]` + selectedText + text.substring(wordEnd);
        } else if (additionalTag) {
            newData = text.substring(0, wordStart) + `[${bbcodeTag}=${additionalTag}]` + selectedText + `[/${bbcodeTag}]` + text.substring(wordEnd);
        } else {
            newData = text.substring(0, wordStart) + `[${bbcodeTag}]${selectedText}[/${bbcodeTag}]` + text.substring(wordEnd);
        }

        setTextareaValue(newData);
        checkTextareaData(newData);
        setDataChanged?.(newData);
    };

    const handleBBCodeColorChange = (color) => {
        setSelectedColor(color);
        handleBBCodeButtonClick("color", color.value);
        setSelectedColor(bbcodeColors[0]);
    };

    const handleImageUpload = async (event) => {
        try {
            const selectedFiles = Array.from(event.target.files) as File[];
            const formData = new FormData();

            selectedFiles.forEach((file) => {
                formData.append("files[]", file);
            });

            const res = await insuranceProxyAxiosInstance.post("/upload/images", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            const imagesData = res.data;

            if (imagesData) {
                setSelectedImages((images) => [...images, imagesData.files]);
                const bbCodeImageData = imagesData.files.map((image) => {
                    return `[img=Заголовок зображення]${image.url}[/img]`;
                });
                setTextareaValue((value) => `${value}\n\n${bbCodeImageData.join("\n\n")}`);
                setSelectedImagesSuccessMsg(imagesData.files[0].successMsg);
                setSelectedImagesErrorMsg("");
                setSuccessNotificationHidden(false);
                setErrorNotificationHidden(true);
                checkTextareaData(textareaRef.current?.value);
                setDataChanged?.(textareaRef.current?.value);
            } else {
                setSelectedImagesSuccessMsg("");
                setSelectedImagesErrorMsg("Помилка! Не вдалось завантажити зображення.");
                setSelectedImages([]);
                setSuccessNotificationHidden(true);
                setErrorNotificationHidden(false);
            }
        } catch (error: any) {
            setSelectedImagesSuccessMsg("");
            setSelectedImagesErrorMsg("Помилка! Не вдалось завантажити зображення.");
            setSelectedImages([]);
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(false);
        } finally {
            event.target.value = null;
        }
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
    };

    const handleImageDeleted = (status) => {
        if (status.isDeleted) {
            setSuccessNotificationHidden(false);
            setErrorNotificationHidden(true);
            setSelectedImagesSuccessMsg(status.successMsg);
            setSelectedImagesErrorMsg("");
            setTextareaValue(textareaValue.replace(/\[img=.+?\](.+?)\[\/img\]/g, (match, content) => {
                if (content.includes(status.fileUrl)) {
                    return "";
                }
                
                return match;
            }));
            setSelectedImages(selectedImages.flat().filter((image) => image.url !== status.fileUrl));
            checkTextareaData(textareaRef.current?.value);
        } else {
            setErrorNotificationHidden(false);
            setSuccessNotificationHidden(true);
            setSelectedImagesErrorMsg(status.errorMsg);
            setSelectedImagesSuccessMsg("");
        }
    };

    const handleImageAdded = (item) => {
        setTextareaValue((value) => `${value}\n\n${item}\n\n`);
        checkTextareaData(item);
        setSuccessNotificationHidden(false);
        setErrorNotificationHidden(true);
        setSelectedImagesSuccessMsg("Зображення додано у текстове поле.");
    };

    return (
       <>
        <div className={`${errorMsg && touch ? "form-group form-group-error" : !errorMsg && touch ? "form-group form-group-success" : "form-group"}`}>
            {label && <label>{label}</label>}
            <textarea 
                name={name} 
                placeholder={placeholder}
                value={textareaValue}
                ref={textareaRef}
                onBlur={handelBlur}
                onChange={handleChange}
                rows={rows}
                {...rest}
            />
            {errorMsg && <p>{errorMsg}</p>}
        </div>
        {isBBCode && 
            <div className="bbcode-container">
                <CustomTooltip msg="Жирний"><button className="bbcode-btn" id="bold-btn" type="button" name="codeB" onClick={() => handleBBCodeButtonClick("b")}>B</button></CustomTooltip>
                <CustomTooltip msg="Курсив"><button className="bbcode-btn" id="italic-btn" type="button" name="codeI" onClick={() => handleBBCodeButtonClick("i")}>i</button></CustomTooltip>
                <CustomTooltip msg="Підкреслити"><button className="bbcode-btn" id="underline-btn" type="button" name="codeU" onClick={() => handleBBCodeButtonClick("u")}>u</button></CustomTooltip>
                <CustomTooltip msg="Перечеркнути"><button className="bbcode-btn" id="linethrough-btn" type="button" name="codeS" onClick={() => handleBBCodeButtonClick("s")}>S</button></CustomTooltip>
                <CustomTooltip msg="Ліворуч"><button className="bbcode-btn" id="left-btn" type="button" name="codeL" onClick={() => handleBBCodeButtonClick("left")}>L</button></CustomTooltip>
                <CustomTooltip msg="По центру"><button className="bbcode-btn" id="center-btn" type="button" name="codeC" onClick={() => handleBBCodeButtonClick("center")}>C</button></CustomTooltip>
                <CustomTooltip msg="Праворуч"><button className="bbcode-btn" id="right-btn" type="button" name="codeR" onClick={() => handleBBCodeButtonClick("right")}>R</button></CustomTooltip>
                <CustomTooltip msg="В лінію"><button className="bbcode-btn" id="inline-btn" type="button" name="codeInline" onClick={() => handleBBCodeButtonClick("inline")}>В лінію</button></CustomTooltip>
                <CustomTooltip msg="Зображення"><button className="bbcode-btn" id="img-btn" type="button" name="codeImg" onClick={() => handleBBCodeButtonClick("img", "Заголовок зображення")}>Зображення</button></CustomTooltip>
                <CustomTooltip msg="Цитата"><button className="bbcode-btn" id="quote-btn" type="button" name="codeQuote" onClick={() => handleBBCodeButtonClick("quote")}>Цитата</button></CustomTooltip>
                <CustomTooltip msg="Посилання"><button className="bbcode-btn" id="url-btn" type="button" name="codeUrl" onClick={() => handleBBCodeButtonClick("url", "Посилання")}>Посилання</button></CustomTooltip>
                <CustomTooltip msg="Код"><button className="bbcode-btn" id="code-btn" type="button" name="codeCode" onClick={() => handleBBCodeButtonClick("code")}>Код</button></CustomTooltip>
                <CustomTooltip msg="Спойлер"><button className="bbcode-btn" id="spoiler-btn" type="button" name="codeSpoiler" onClick={() => handleBBCodeButtonClick("spoiler", "Заголовок")}>Спойлер</button></CustomTooltip>
                <CustomTooltip msg="Список"><button className="bbcode-btn" id="list-btn" type="button" name="codeList" onClick={() => handleBBCodeButtonClick("li")}>Список</button></CustomTooltip>
                <div className="bbcode-color-select">
                    <CustomTooltip msg="Колір">
                        <CustomSelect
                            options={bbcodeColors}
                            handleChange={handleBBCodeColorChange}
                            value={selectedColor}
                            isCreatable={false}
                            isDisabled={false}
                            placeholder="Колір"
                            isOptionStyle={true}
                        />
                    </CustomTooltip>
                </div>
                <CustomTooltip msg="Горизонтальна лінія"><button className="bbcode-btn" id="hr-btn" type="button" name="codeHR" onClick={() => handleBBCodeButtonClick("hr")}>-</button></CustomTooltip>
                <CustomTooltip msg="YouTube"><button className="bbcode-btn" id="youtube-btn" type="button" name="codeYouTube" onClick={() => handleBBCodeButtonClick("youtube")}>YouTube</button></CustomTooltip>
                <CustomTooltip msg="Виберіть зображення">
                    <label className="file-upload">
                        <input type="file" name="image-files" accept="image/*" className="image-files-select" onChange={handleImageUpload} multiple /><i className="fa fa-cloud-upload-alt notification-icon"></i>Виберіть зображення...
                    </label>
                </CustomTooltip>
                <div className="image-notifications">
                    {!hideSuccessNotification && selectedImagesSuccessMsg && <div className="admin-success-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{selectedImagesSuccessMsg}</div>}
                    {!hideErrorNotification && selectedImagesErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{selectedImagesErrorMsg}</div>}
                </div>
                <div className="image-preview-group">
                    {selectedImages && selectedImages.flat().map((image, index) => (
                        <div className="preview-item">
                            <ImageZoomComponent key={index} type={image.type} content={image.content} caption={image.fileName} size={image.size} alt={image.fileName} path={image.path} url={image.url} setImageDeleted={handleImageDeleted} setImageAdded={handleImageAdded} isLocalImage={true} />
                        </div>
                    ))}
                </div>
            </div>
        }
       </>    
    );
};

export default CustomTextarea;