import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {setCurrentUser, setData} from "../../../redux-data/user/userReducer";
import serviceOsago from "../../../service/serviceOsago";
import webConfig from "../../../../src/config";
import {formatPhoneNumber, formatEmail, dialPhoneNumber} from "../../../utils/utilsGeneral";
import CustomLoader from "../../Forms/common/CustomLoader";
import CustomTooltip from "../../Forms/common/CustomTooltip";
import {patternEmail} from "../../../utils/getInstanceError";
import EmailTemplate from "../../../views/dashboard/newsletters/EmailTemplate";
import TabsComponent from "../../../utils/TabsComponent";
import {TabProp} from "../../../utils/tab";
import PartnerLoginComponent from "../PartnerLoginComponent";
import VerificationCode from "../VerificationCode";
import {setEagentToken} from "../../../redux-data/misc/miscReducer";

Modal.setAppElement("#modal-root");

interface IPublicModal {
  isOpen: boolean;
  onCLose: (event?: any) => void;
  type: string;
  title?: string;
  data?: string;
  onEmailContentReady?: (content: any) => void;
  onVerifCodeResend?: () => void;
  onVerifComplete?: (code: string) => void;
  isVerifError?: boolean;
  verifErrorMsg?: string;
  onCloseCustomerErrorNotifMsg?: () => void; 
}

const PublicModal = ({isOpen, onCLose, type, title = "", data = "", onEmailContentReady, onVerifCodeResend, onVerifComplete, isVerifError, verifErrorMsg, onCloseCustomerErrorNotifMsg}: IPublicModal) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState<any>("");
  const [errorMsg, setErrorMsg] = useState<any>();
  const [isLogingIn, setLogingIn] = useState(false);
  const [htmlPreviewContent, setHtmlPreviewContent] = useState("");
  const adminPhoneNumbers = webConfig.adminPhoneNumbers!.split(", ");

  useEffect(() => {
      const errorNotifTimer = setTimeout(() => {
        setErrorMsg("");
      }, 5000);

      return () => clearTimeout(errorNotifTimer);
  }, [errorMsg]);

  const handleEmailContent = (content) => {
    setHtmlPreviewContent(content);
    onEmailContentReady?.(content);
  };

  const handleDlgClose = () => {
    window.scrollTo(0, 0);
    onCLose();
  };

  if (type === "public") {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onCLose}
        style={{
          overlay: {
            zIndex: 2000
          },
          content: {
            width: "auto",
            margin: "auto",
            padding: "24px",
            inset: "10px",
            maxWidth: "1000px",
            height: "auto",
            maxHeight: "600px"
          },
        }}>
        <div className="dialog-wrapper">
        <CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark dialog-close-icon" onClick={() => onCLose()}></i></CustomTooltip>
        <h2>Терміни</h2>
        <div>
          <div>
            <b>Сайт</b> – публічний, відкритий для вільного доступу і доступний всім користувачам інтернету веб-сайту{' '}
            <a href={webConfig.pessWebsite}>{webConfig.pessWebsite}</a>.
          </div>
          <br />
          <div>
            <b>Користувач</b> – фізична особа або організація, що використовують Сайт для виконання певної мети, а саме
            підбору, розрахунку, оформлення та замовлення послуг страхування, що надаються партнерами сайту, страховими
            компаніями.
          </div>
          <br />
          <div>
            <b>Оферта</b> – пропозиція певній стороні укласти договір з урахуванням викладених умов.
          </div>
          <br />
          <div>
            <b>Акцепт</b> - згода на оплату або гарантування оплати документів.
          </div>
          <br />
          <div>
            <b>Прийняття оферти</b> – повна і беззастережна згода з умовами Оферти і прийняття Оферти Користувачем для
            можливості використання всіх функцій Сайту з ціллю пошуку, розрахунку, підбору, оформлення та оплати Послуг
            страхування, що надаються постачальниками Послуг.
          </div>
          <br />
          <div>
            <b>Послуга</b> – комплекс дій, що надаються Постачальником Послуг Покупцю/Користувачу, після оплати. Обсяг
            Послуги та початок її надання обираються Користувачем самостійно, при створені Замовлення, з представлених
            на сайті варіантів. Згідно умов даної Оферти, Постачальники послуг не зобов'язані надавати Клієнтам
            будь-яких інших послуг, крім заявлених при створені Замовлення на Сайті. Перелік послуг, що надаються
            Покупцю/Користувачу в рамках замовлення може бути змінено постачальником послуг в односторонньому порядку,
            без відома Покупця/Користувача. Pess.org.ua надає Послуги в відповідності з умовами
            партнерських/агентських/субагентських договорів і угод з Постачальниками Послуг.
          </div>
          <br />
          <div>
            <b>Електронний поліс</b> – повноцінний аналог звичного “паперового” полісу, тільки надається клієнту в
            електронному вигляді за допомогою електронних каналів зв'язку.
          </div>
          <br />
          <div>
            <b>Постачальник послуги</b> – страхові компанії та інші організації, зареєстровані належним чином на
            території України і мають діючу ліцензію на надання відповідних послуг, розміщених на сайті.
          </div>
          <br />
          <div>
            <b>Замовлення послуги</b> – оформлений на Сайті запит Користувача на отримання Послуг, самостійно обраних на
            Сайті, що передбачає закріплення Користувачем обраної на Сайті послуги за конкретною особою (чи особами).
            Оформлення може припускати повну оплату Послуги.
          </div>
          <br />
          <div>
            <b>Адміністрація сайту</b> не несе відповідальності за точність і правильність інформації, що надається
            Користувачем під час реєстрації.
          </div>
          <br />
          <br />
          <div>
            <h2>Замовлення послуги</h2>
          </div>
          <div>
            Сайт надає можливість Користувачам розрахувати вартість Послуги за вказаними параметрами: вартість послуги в
            різних страхових компаніях, порівняння вартості послуг, вибір варіантів франшизи, додаткових послуг, програм
            страхування.
          </div>
          <br />
          <div>
            Вказана інформація розміщується на Сайті в зручному вигляді для порівняння та аналізу Послуг. Здійснюючи
            Замовлення послуги через Сайт, Користувач зобов'язаний надати персональну інформацію необхідну для
            оформлення обраної послуги.
          </div>
          <br />
          <div>
            При Замовлені послуги Користувач повинен заповнити необхідні поля на сайті. Якщо Користувач замовив Послугу
            і не сплатив її протягом граничного терміну, таке замовлення буде автоматично відмінено протягом
            встановленого Постачальником Послуги терміну. Ніяких штрафів за відміну неоплаченої Послуги не передбачено.
          </div>
          <br />
          <br />
          <div>
            <h2>Оплата послуги</h2>
          </div>
          <div>
            Послуги по оплаті за допомогою платіжних карт міжнародних платіжних систем VISA/MASTERCARD/ПРОСТІР надаються
            за допомогою інтернет еквайрингу від провідних постачальників ПриватБанк.
          </div>
          <br />
          <div>
            Користувач несе повну, одноосібну відповідальність за всі комісійні витрати та інші, передбачені банком
            витрати, які можуть бути додані до вартості емітентів банківської карти. Користувач дає згоду на обробку
            своїх персональних платіжних даних.
          </div>
          <br />
          <div>
            При обраному способі оплати банківською картою Покупець вказує платіжну інформацію банківської карти, а
            саме: номер карти, термін дії, CVV-код. Дана інформація вводиться на захищеній сторінці інтернет еквайрингу
            та не передається сайту Pess.org.ua
          </div>
          <div>Оплата здійснюється на банківський рахунок ФОП Молчанова Г. І. (страховий агент).</div>
          <br />
          <div>
            Шахрайство (або його спроби) з банківськими платіжними картами тягнуть за собою відповідальність, відповідно
            до діючого Законодавства.
          </div>
          <br />
          <br />
          <div>
            <h2>Умови повернення коштів</h2>
          </div>
          <div>
            Повернення грошових коштів клієнтам здійснюється за допомогою повернення вартості сплаченого на Сайті продукту шляхом перерахування на банківську карту з якої була проведена оплата (карта повинна бути емітована на території України; 
            валюта карти - гривня).
          </div>
          <br />
          <div>Терміни та сума платежу залежать від страхової компанії та здійснюються в термін, встановлений законодавством України.</div>
          <br />
          <div>Поверненню не підлягають комісії та еквайринг з боку банку, платіжних систем та обслуговування банківської карти.</div>
          <br />
          <br />
          <div>
            <h2>Тарифи</h2>
          </div>
          <div>
            Користувач самостійно обирає спосіб оплати Послуги, що йому підходить серед можливих для даного виду
            Послуги. Пропонується перелік страхових послуг у ціновому діапазоні від 350 грн до 7000 грн.
          </div>
          <br />
          <br />
          <div>
            <h2>Права, обов'язки і відповідальність сторін</h2>
          </div>
          <br />
          <div>
            <b>Користувач має право: </b>
          </div>
          <br />
          <div>
            Користуватися доступними сервісами та інформаційними розділами Сайту з ціллю аналізу, вибору, оформлення,
            замовлення, оплати або вивчення доступних Послуг. Здійснювати Замовлення послуги для  третіх осіб або від їх
            імені , тільки якщо він був уповноважений даними особами на здійснення вибору та Замовлення послуги, а також
            на надання персональних даних, що вимагаються для Замовлення послуги.
          </div>
          <br />
          <div>
            <b>Користувач зобов'язаний: </b>
          </div>
          <br />
          <div>
            Виконувати умови Замовлення послуги, встановлені будь-яким з Постачальників Послуг, з якими він буде
            співпрацювати в рамках обраної Послуги, включаючи, але не обмежуючись,  сплатою всіх страхових платежів в
            відповідний час, і в відповідності з встановленими тарифами, правилами та обмеженнями Постачальника послуги.{' '}
          </div>
          <br />
          <div>
            <b>Користувачі несуть відповідальність, повідомлені та усвідомлюють, що: </b>
          </div>
          <div>
            Всі етапи створення Замовлення послуги: дані, що вказані для розрахунку вартості, вибір Постачальника
            послуги, внесення даних Користувача, вибір методів оплати та інше - це виключно самостійний вибір
            Користувача. Користувач несе повну відповідальність за коректність даних, що вказані ним при Замовленні
            послуги. Внесена Користувачем інформація автоматично фіксується на Сайті, та у внутрішній базі Постачальника
            послуги для подальшого оформлення Послуги. Користувачі самі несуть відповідальність за несвоєчасне звернення
            з претензіями або недотриманням передбачених формальностей.
          </div>
          <br />
          <div>
            <b>Адміністрація сайту має право: </b>
          </div>
          <br />
          <div>
            На свій розсуд, в будь-який момент вносити будь-які зміни на Сайті без спеціального повідомлення або
            попередження Користувачів, додавати нові чи скорочувати  наявні Послуги Постачальників послуг, доступні на
            Сайті сервіси ти функціонал, змінювати дизайн, правила бонусної програми, додавати/скорочувати методи оплати
            доступні Користувачам для оплати Послуг на Сайті, змінювати вартість Послуг що надаються.
          </div>
          <br />
          <div>
            Без повідомлення Користувачів Призупинити надання Послуг на Сайті з ціллю проведення технічних робіт.
          </div>
          <br />
          <div>
            На свій власний розсуд, відмовляти Користувачам в доступі до Сайту та пропонованим Послугам, в будь-який час
            і з будь-яких причин, без пояснення і повідомлення Користувача.
          </div>
          <br />
          <div>
            Збирати інформацію про замовлення Користувача на сайті з ціллю повідомлення Користувача про спеціальні
            пропозиції, акції та додаткові Послуги, доступні на сайті.
          </div>
          <br />
          <div>
            <b>Адміністрація сайту зобов'язана: </b>
          </div>
          <br />
          <div>Докласти максимум зусиль для обслуговування користувачів Сайту належним чином.</div>
          <br />
          <div>
            Інформувати Користувачів про зміни переліку Послуг або про неможливість виконати оплачену послугу, за умови,
            що Постачальник послуги своєчасно проінформував про це Адміністрацію Сайту.
          </div>
          <br />
          <br />
          <div>
            <h2>Відмова від відповідальності</h2>
          </div>
          <div>
            Адміністрація сайту не виступає в якості со-постачальника з Постачальником послуг, Послуги яка обрана
            Користувачем та не бере участі в спорах між Користувачем та Постачальником Послуги.
          </div>
          <br />
          <br />
          <div>
            <h2>Захист, зберігання та обробка персональних даних</h2>
          </div>
          <div>
            Адміністрація сайту використовує загальноприйняті стандарти захисту інформації та персональних даних від
            втрати, неправильного використання, зміни або знищення.
          </div>
          <br />
          <div>
            На виконання вимог Закону України “Про захист персональних даних” №2297-VI від 01.06.2010 (далі - Закон),
            при заключені цього Договору публічної оферти (акцепт оферти), Користувач надає Адміністрації сайту свою
            повну, необмежену терміном, безвідкличну згоду на обробку його персональних даних* будь-яким способом,
            передбаченим Законом, з ціллю здійснення своєї діяльності, пов'язаної з нею фінансово-господарською
            діяльністю, а також відмовляється від письмового повідомлення про включення інформації про нього в базу
            персональних даних Сайту/Адміністрації сайту та обраного Постачальника послуги, передачу або надання доступу
            третім особам без отримання додаткової згоди Користувача. Користувач підтверджує, що ознайомлений з правами
            як суб'єкта персональних даних, в відповідності з Законом. Користувач надає свою згоду на отримання SMS
            повідомлень, електронних повідомлень в  популярні месенджери та електронних/паперових листів від
            Сайту/Адміністрації сайту та його партнерів, які здійснюють оформлення послуг.
          </div>
          <br />
          <div>
            <i>
              * В розумінні Закону під персональними даними розуміється будь-яка інформація про Користувача, в тому
              числі, але не виключено, прізвище, ім'я, по-батькові, дата та місце народження, адреса, телефон, паспортні
              дані, та дані інших документів (необхідних для Замовлення послуги), ідентифікаційний номер, професія,
              інформація про належне йому майно, а також інші відомості, що надаються при Замовленні послуги.
            </i>
          </div>
          <br />
          <br />
          <div>
            <h2>Розторгнення договору публічної оферти</h2>
          </div>
          <div>
            Адміністрація сайту вправі, в будь-який час, на свій особистий розсуд, змінювати або редагувати дані
            Договору публічної оферти без попередження про це Користувача. Подальше ж використання Користувачем  цього
            сайту означає, що Користувач автоматично прийняв зміни або відредагований Договір публічної оферти.
          </div>
          <br />
          <div>
            Цей договір публічної оферти може бути розірвано в будь-який час за ініціативою Користувача або
            Адміністрації сайту, шляхом направлення іншій стороні  відповідного повідомлення листом на пошту.
          </div>
          <br />
          <br />
          <div>
            <h2>Дія Договору публічної оферти</h2>
          </div>
          <div>
            Договір вступає в силу з моменту його заключення (акцепту оферти) і діє протягом невизначеного терміну.{' '}
          </div>
        </div>
        <div className="dialog-controls">
          <button type="button" className="dialog-btn" onClick={() => onCLose()}><i className="fa-solid fa-xmark notification-icon"></i>Закрити</button>
        </div>
      </div>
      </Modal>
    );
  } else if (type === "about") {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onCLose}
        style={{
          overlay: {
            zIndex: 2000
          },
          content: {
            width: "auto",
            padding: "24px",
            inset: "10px",
            margin: "auto",
            maxWidth: "1000px",
            height: "auto",
            maxHeight: "600px"
          },
        }}>
        <div className="dialog-wrapper">
          <CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark dialog-close-icon" onClick={() => onCLose()}></i></CustomTooltip>
          <div>
            Компанія «Перший електронний страховий сервіс» - це унікальна страхова платформа, котра надає можливість
            швидко та зручно оформити страхування вашого транспортного засобу всього за декілька хвилин!
          </div>
          <br />
          <h3>Про можливості та переваги електронного страхування</h3>
          <br />
          <div>
            Починаючи з 2018 року українським законодавством передбачена можливість оформлювати страхові поліси
            транспортних засобів в електронній формі.
          </div>
          <br />
          <div>
            Це дає змогу уникнути зайвої бюрократичної роботи зі старими паперовими полісами , значно заощадити Ваш час,
            а також завжди мати страховий поліс у Вашому смартфоні!
          </div>
          <br />
          <h3>Широкий асортимент страхових компаній</h3>
          <br />
          <div>
            Одна з головних переваг електронного страхування на нашій платформі - можливість самостійно обирати страхову
            компанію, котрій Ви довіряєте найбільше і чиї умови та вартість Вас влаштує!
          </div>
          <br />
          <div>Платформа пропонує до Вашого вибору більше 20-ти страхових компаній.</div>
          <br />
          <h3>Гарантія та форма полісу</h3>
          <br />
          <div>
            Поліс формується в форматі PDF і володіє унікальним номером, який фіксується в базі МТСБУ. Після покупки
            діюча страховка висилається на e-mail
          </div>
          <br />
          <h3>Як це працює?</h3>
          <br />
          <div>
            Для оформлення страхового полісу клієнту потрібно лише ввести реєстраційний номер автомобіля, після чого
            сервіс самостійно обробляє всі данні Вашого транспортного засобу. Після цього залишиться ввести деякі
            персональні контактні данні, оплатити страховий поліс та отримати його на електронну адресу. Весь процес
            займає не більше декількох хвилин!
          </div>
          <br />
          <h3>Оплата</h3>
          <br />
          <div>
            Оплата електронного страхового полісу здійснюється після того, як клієнт обирає страховий поліс та
            компанію-страховика і вказує свої контактні дані.
          </div>
          <br />
          <div>Оплатити страховий поліс можна за допомогою банківської карти.</div>
          <br />
          <h3>Зворотній зв’язок</h3>
          <br />
          <div>
            Фахівці страхової платформи «Перший електронний страховий сервіс» готові з ентузіазмом допомагати Вам як
            онлайн, так і в телефонному режимі у випадку виникнення у Вас будь-яких складнощів при оформленні страхового
            полісу!
          </div>
          <br />
          <h3>Будь у тренді!</h3>
          <br />
          <div>
            Ми живемо в еру високих цифрових технологій, а відтак давно вже час забути про паперові страхові поліси та
            почати користуватися новими можливостями!
          </div>
          <div className="dialog-controls">
            <button type="button" className="dialog-btn" onClick={() => onCLose()}><i className="fa-solid fa-xmark notification-icon"></i>Закрити</button>
          </div>
        </div>
      </Modal>
    );
  } else if (type === "faq") {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onCLose}
        style={{
          overlay: {
            zIndex: 2000
          },
          content: {
            width: "auto",
            margin: "auto",
            padding: "24px",
            inset: "10px",
            height: "auto",
            maxWidth: "1000px",
            maxHeight: "400px"
          }
        }}>
        <div className="dialog-wrapper">
          <CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark dialog-close-icon" onClick={() => onCLose()}></i></CustomTooltip>
          <div>Цей розділ знаходиться в розробці!</div>
          <div className="manage-dialog-controls">
            <button type="button" className="dialog-btn" onClick={() => onCLose()}><i className="fa-solid fa-xmark notification-icon"></i>Закрити</button>
          </div>
        </div>
        </Modal>
    );
  } else if (type === "login") {
    const onSubmit = async () => {
      if (email.length > 0 && patternEmail.test(email) && password.length > 0) {
          try {
            setLogingIn(true);
            setErrorMsg("");
            const value = await serviceOsago.getLoginUser({email, password});
            const userData = await serviceOsago.getUserInfo(value.user.id);
                dispatch(setCurrentUser(value));
                dispatch(setData(userData));
                setErrorMsg("");
                dispatch(setEagentToken(`Basic ${btoa(`${email}:${password}`)}`));
                onCLose();
                navigate("/");
            } catch (error: any) {
                setLogingIn(false);
                const userDataError = error.toJSON();
                setErrorMsg(error.response?.data?.message || userDataError.message || "Unknown error");
            } finally {
                setEmail("");
                setPassword("");
                setLogingIn(false);
            }
      } else {
          setLogingIn(false);
          setErrorMsg("Введіть Ваш email і пароль правильно!");
      }
    };

    const closeNotificationMsg = () => {
      setErrorMsg("");
    };

    const loginTabs: TabProp[] = [
      {
        index: 1,
        label: "Вхід для партнерів",
        isDisabled: false,
        children: <PartnerLoginComponent handleSubmit={onSubmit} handleClose={onCLose} errorMsg={errorMsg} handleCloseNotifMsg={closeNotificationMsg} emailPattern={patternEmail} handleEmail={setEmail} handlePassword={setPassword} />
      },
      {
        index: 2,
        isDisabled: true,
        label: "Вхід для клієнтів",
        children: <div>Розділ в розробці!</div>
      }
    ];

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onCLose}
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: {
            zIndex: 2000
          },
          content: {
            maxWidth: "max-content",
            maxHeight: "max-content",
            width: "auto",
            height: "auto",
            margin: "auto",
            padding: "24px",
            inset: "10px"
          },
        }}>
        {isLogingIn ?
          <CustomLoader className="account-logging-in" isSquare={true} isInfoOn={true} isLoading={isLogingIn} infoMsg="Вхід у особистий кабінет" />
        :
          <>
            <TabsComponent tabs={loginTabs} defaultTab={1} orientation={isMobile ? "vertical" : "horizontal"} tabsDisabledMsg="Вхід в систему тимчасово недоступний!" isContentPadding={false} />
          </>
        }
      </Modal>
    );
  } else if (type === "contact") {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onCLose}
        style={{
          overlay: {
            zIndex: 2000
          },
          content: {
            width: "auto",
            margin: "auto",
            padding: "24px",
            inset: "10px",
            maxWidth: "1000px",
            height: "auto",
            maxHeight: "520px"
          },
        }}>
        <div className="dialog-wrapper">
          <CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark dialog-close-icon" onClick={() => onCLose()}></i></CustomTooltip>
          <h3>Адреса:</h3>
          <br />
          <div>03055, проспект Перемоги 33, офіс 2, м. Київ, Україна</div>
          <br />
          <h3>Телефон: </h3>
          <br />
          <div>
            <div dangerouslySetInnerHTML={{__html: dialPhoneNumber(formatPhoneNumber(adminPhoneNumbers[0]))}} />
            <br />
            <div dangerouslySetInnerHTML={{__html: dialPhoneNumber(formatPhoneNumber(adminPhoneNumbers[1]))}} />
            <br />
            <div dangerouslySetInnerHTML={{__html: dialPhoneNumber(formatPhoneNumber(adminPhoneNumbers[2]))}} />
          </div>
          <br />
          <h3>E-mail:</h3>
          <br />
          <div dangerouslySetInnerHTML={{__html: formatEmail("pessorgua@gmail.com")}} />
          <br />
          <h3>Графік роботи:</h3>
          <br />
          <div>Понеділок - П'ятниця з 09:00 до 18:00</div>
          <br />
          <div>Субота та Неділя - вихідні</div>
          <div className="manage-dialog-controls">
            <button type="button" className="dialog-btn" onClick={() => onCLose()}><i className="fa-solid fa-xmark notification-icon"></i>Закрити</button>
          </div>
        </div>
      </Modal>
    );
  } else if (type === "email-preview") {
    return (
      <Modal
        contentLabel={title}
        isOpen={isOpen}
        onRequestClose={onCLose}
        style={{
          overlay: {
            zIndex: 2000
          },
          content: {
            width: "auto",
            margin: "auto",
            padding: "24px",
            inset: "10px",
            height: "auto",
            maxWidth: "1000px",
            maxHeight: "600px"
          }
        }}>
        <div className="dialog-wrapper">
          <CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark dialog-close-icon" onClick={() => onCLose()}></i></CustomTooltip>
          <p className="dialog-title">{title}</p>
          <div dangerouslySetInnerHTML={{__html: htmlPreviewContent}} />
          <EmailTemplate content={data} setEmailContent={handleEmailContent} isEmail={true} isModalOpen={isOpen} />
          <div className="dialog-controls">
            <button type="button" className="dialog-btn" onClick={() => onCLose()}><i className="fa-solid fa-xmark notification-icon"></i>Закрити</button>
          </div>
        </div>
      </Modal>
    );
  } else if (type === "diia") {
    return (
      <Modal
        contentLabel={title}
        isOpen={isOpen}
        onRequestClose={onCLose}
        style={{
          overlay: {
            zIndex: 2000
          },
          content: {
            width: "auto",
            margin: "auto",
            padding: "24px",
            inset: "10px",
            height: "auto",
            maxWidth: "500px",
            maxHeight: "300px"
          }
        }}>
        <div className="dialog-wrapper">
          <CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark dialog-close-icon" onClick={handleDlgClose}></i></CustomTooltip>
          <p className="dialog-title">{title}</p>
          <div className="dialog-content">
            <p>Копії документів успішно отримано</p>
          </div>
          <div className="manage-dialog-controls">
            <button type="button" className="dialog-btn" onClick={handleDlgClose}><i className="fa-solid fa-xmark notification-icon"></i>Закрити</button>
          </div>
        </div>
      </Modal>
    );
  } else if (type === "customer-identification") {
    const expirationTime = 2 * 60 * 1000;
    const allowedCharacters = new RegExp("^[0-9]+$").source;
    return (
      <Modal
        contentLabel={title}
        isOpen={isOpen}
        style={{
          overlay: {
            zIndex: 2000
          },
          content: {
            width: "auto",
            margin: "auto",
            padding: "24px",
            inset: "10px",
            height: "auto",
            maxWidth: "500px",
            maxHeight: "400px"
          }
        }}>
        <div className="dialog-wrapper">
          <CustomTooltip msg="Або можна заповнити дані власноруч"><i className="fa-solid fa-xmark dialog-close-icon" onClick={handleDlgClose}></i></CustomTooltip>
          <p className="dialog-title">{title}</p>
          <div className="dialog-content">
            {isVerifError && verifErrorMsg && <div className="dialog-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={onCloseCustomerErrorNotifMsg}></i></CustomTooltip>{verifErrorMsg}</div>}
            <VerificationCode title="На Ваш email відправлено код авторизації:" inputType="number" inputMode="numeric" allowedChars={allowedCharacters} count={Number(webConfig.customerVerificationCodeLength)} name="customerIdentificationCode" expTime={expirationTime} 
              onVerificationCodeResend={onVerifCodeResend} onVerificationComplete={onVerifComplete} />
          </div>
          <div className={isVerifError ? "dialog-controls" : "manage-dialog-controls"}>
            <button type="button" className="dialog-btn" onClick={handleDlgClose}><i className="fa-solid fa-xmark notification-icon"></i>Або можна заповнити дані власноруч</button>
          </div>
        </div>
      </Modal>
    );
  } else if (type === "bankid-error") {
    return (
      <Modal
        contentLabel={title}
        isOpen={isOpen}
        onRequestClose={onCLose}
        style={{
          overlay: {
            zIndex: 2000
          },
          content: {
            width: "auto",
            margin: "auto",
            padding: "24px",
            inset: "10px",
            height: "auto",
            maxWidth: "500px",
            maxHeight: "300px"
          }
        }}>
        <div className="dialog-wrapper">
          <CustomTooltip msg="Заповнити дані власноруч"><i className="fa-solid fa-xmark dialog-close-icon" onClick={handleDlgClose}></i></CustomTooltip>
          <p className="dialog-title-error">{title}</p>
          <div className="dialog-content">
            <i className="fa-light fa-circle-xmark dialog-error-icon"></i>
            <p>Не вдалось отримати дані авторизації BankID!</p>
          </div>
          <div className="manage-dialog-controls">
            <button type="button" className="dialog-btn" onClick={handleDlgClose}><i className="fa-solid fa-xmark notification-icon"></i>Заповнити дані власноруч</button>
          </div>
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onCLose}
        style={{
          overlay: {
            zIndex: 2000
          },
          content: {
            width: "auto",
            margin: "auto",
            padding: "24px",
            maxWidth: "1000px",
            inset: "10px",
            height: "auto",
            maxHeight: "400px"
          },
        }}>
        <div className="dialog-wrapper">
          <CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark dialog-close-icon" onClick={() => onCLose()}></i></CustomTooltip>
          Вибачте за тимчасові незручності! Свої відгуки та пропозиції просимо надсилати на наш E-mail: <a href="mailto:pessorgua@gmail.com">pessorgua@gmail.com</a>
          <div className="manage-dialog-controls">
            <button type="button" className="dialog-btn" onClick={() => onCLose()}><i className="fa-solid fa-xmark notification-icon"></i>Закрити</button>
          </div>
        </div>
      </Modal>
    );
  }
};

export default PublicModal;
