import React from "react";
import Countdown from "react-countdown";

interface ITimerComponent {
    expiryTimestamp: string | number | Date;
    onTimerComplete: () => void;
    onTimerRenderContent: (minutes: number, seconds: number) => JSX.Element | string;
}

const TimerComponent = ({expiryTimestamp, onTimerRenderContent, onTimerComplete}: ITimerComponent) => {
    return (
        <Countdown key={expiryTimestamp.toString()} date={expiryTimestamp} autoStart={true} renderer={({minutes, seconds}) => onTimerRenderContent(minutes, seconds)} onComplete={onTimerComplete} />
    );
};

export default TimerComponent;