import {RootState} from "../index";

export const getSalePointId = (state: RootState) => {
    return state.user.data!.salePoint.id;
};

export const getSalePointCompanyId = (state: RootState) => {
    return state.user.data!.salePoint.company.id;
};

export const getUserId = (state: RootState) => {
    return state.user.data!.id;
}
export const getUserPrivilegeType = (state: RootState) => {
    if (state.user.privilegeType) {
        return state.user.privilegeType;
    }
}

