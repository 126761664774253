import React, {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {getOfferId, StatusOffer, validateOfferStatus} from "../../redux-data/offer/offerReducer";
import serviceOsago from "../../service/serviceOsago";
import LiqPayPage from "../../views/LiqPayPage";
import CustomLoader from "../Forms/common/CustomLoader";
import {IGreencardCashback} from "../../typings/IDataOffers";
import {dbAxiosInstance} from "../../core/configDBAxios";
import CashbackCardComponent from "../Forms/common/CashbackCardComponent";
import {getSelectedItem, getReportId, getAnalyticsId} from "../../redux-data/insurance/insuranceSelector";
import CustomTooltip from "../Forms/common/CustomTooltip";
import {handleInsuranceNav, navToHomePage, parseEUAErrorMessageByResponse, isSettingItemActive} from "../../utils/utilsGeneral";
import webConfig from "../../../src/config";
import {TabProp} from "../../utils/tab";
import TabsComponent from "../../utils/TabsComponent";
import WayForPayPage from "../../views/WayForPayPage";
import {insuranceProxyAxiosInstance} from "../../core/configInsuranceProxyAxios";
import axios, {CancelTokenSource} from "axios";
import VerificationCode from "../common/VerificationCode";

const StepFive = ({type}: {type: "epolicy" | "greencard"}) => {
  const policy = useSelector(getSelectedItem);
  const reportID = useSelector(getReportId);
  const analyticsID = useSelector(getAnalyticsId);
  const offerID = useSelector(getOfferId)!;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cancelWfpTokenSourceRef = useRef<CancelTokenSource | null>(null);
  const [status, setStatus] = useState(StatusOffer.REQUEST);
  const [number, setNumber] = useState<number | null>(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [isError, setError] = useState(false);
  const [liqPayTabDisabled, setLiqPayTabDisabled] = useState(false);
  const [wfpTabDisabled, setWfpTabDisabled] = useState(false);
  const [wfpWidgetEnabled, setWfpWidgetEnabled] = useState(false);
  const [greencardCompanyData, setGreencardCompanyData] = useState<IGreencardCashback>({
    id: 0,
    name: "",
    companyID: 0,
    productCode: 0,
    isCashbackEnabled: false,
    isHidden: false,
    cashbackPercentageValue: 0
  });
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const expirationTime = 10 * 1000;
  const allowedCharacters = new RegExp("^[0-9]+$").source;

  useEffect(() => {
    if (!offerID) {
        return;
    }

    cancelWfpTokenSourceRef.current = axios.CancelToken.source();
    handleRequest();

    if (type === "greencard") {
        const fetchGreencardCompanyData = async () => {
          try {
              const res = await dbAxiosInstance.get(`/greencard/fetch-company-data/${encodeURIComponent(policy!.data[0].productCode)}/${encodeURIComponent(policy!.companyId)}`);
              const dbGreencardData = res.data;

              if (dbGreencardData) {
                  if (dbGreencardData.length > 0) {
                      const [greencardCompany] = dbGreencardData;
                      setGreencardCompanyData({id: greencardCompany.id, name: greencardCompany.name, companyID: greencardCompany.company_id, productCode: greencardCompany.product_code, 
                                               isCashbackEnabled: Boolean(greencardCompany.is_cashback_enabled), cashbackPercentageValue: greencardCompany.cashback_percentage, isHidden: Boolean(greencardCompany.is_hidden)});
                  } else {
                      setGreencardCompanyData((prev) => ({prev, id: 0, name: "", companyID: 0, productCode: 0, isCashbackEnabled: false, isHidden: false, cashbackPercentageValue: 0}));
                  }
              } else {
                  setGreencardCompanyData((prev) => ({prev, id: 0, name: "", companyID: 0, productCode: 0, isCashbackEnabled: false, isHidden: false, cashbackPercentageValue: 0}));
              }
          } catch (error: any) {
              setGreencardCompanyData((prev) => ({prev, id: 0, name: "", companyID: 0, productCode: 0, isCashbackEnabled: false, isHidden: false, cashbackPercentageValue: 0}));
          }
        };

        fetchGreencardCompanyData();
    }

    const liqPayTabMode = async () => {
     const isLiqPayTabDisabled = await isSettingItemActive(webConfig.disableLiqPayTabID);
     setLiqPayTabDisabled(isLiqPayTabDisabled);
    };

    const checkWfpSettings = async () => {
      const isWfpTabDisabled = await isSettingItemActive(webConfig.disableWfpTabID);
      setWfpTabDisabled(isWfpTabDisabled);
      const isWfpWidgetEnabled = await isSettingItemActive(webConfig.enableWfpWidgetID);
      setWfpWidgetEnabled(isWfpWidgetEnabled);

      if (!isWfpTabDisabled && !isWfpWidgetEnabled) {
          wfpPaymentPageStatus();
      }
    };

    const wfpPaymentPageStatus = async () => {
      try {
          const res = await insuranceProxyAxiosInstance.get(`/wfp/order/payment-data/${encodeURIComponent(offerID)}`, {
            cancelToken: cancelWfpTokenSourceRef.current?.token
          });
          const {isSuccess, wfpOrderReference, wfpStatus, wfpReason, wfpReasonCode, msg} = res.data;

          if (isSuccess && wfpOrderReference && wfpStatus === webConfig.wfpTransactionStatus && wfpReason === webConfig.wfpReason && wfpReasonCode === Number(webConfig.wfpReasonCode)) {
              setIsPaymentComplete(true);
              setStatus(StatusOffer.PAID_OFFER);
              editReportStatus(reportID, validateOfferStatus(StatusOffer.PAID_OFFER));
              const offerStatus = StatusOffer.EMITTED;
              const res = await serviceOsago.manageOffer(offerID, validateOfferStatus(offerStatus));

              if (res.success) {
                  setStatus(offerStatus);
                  setError(false);
                  setErrorMsg("");
                  editReportStatus(reportID, validateOfferStatus(offerStatus));
              } else {
                  setError(true);
                  setErrorMsg(`Помилка! Не вдалось укласти договір. Код помилки: ${res.code}`);
              }
          } else {
              setError(true);
              setErrorMsg(`Помилка! ${msg}`);
          }
      } catch (error: any) {
          if (!axios.isCancel(error)) {
              setError(true);
              setErrorMsg("Помилка! Не вдалось здійснити платіж.");
          }
      }
    };

    liqPayTabMode();
    checkWfpSettings();
  }, []);

  const cancelWfpRequest = () => {
    if (cancelWfpTokenSourceRef.current) {
        cancelWfpTokenSourceRef.current.cancel("WayForPay запит скасовано користувачем через оплату через LiqPay!");
    }
  };

  const editReportStatus = async (id, status) => {
    await dbAxiosInstance.put(`/update-report/status/${encodeURIComponent(id)}`, {offerStatus: status});
  };

  const updateAnalyticsData = async () => {
    try {
      const analytics = {insuranceStep: 5};
      await dbAxiosInstance.put(`/update-analytics/insurance/step/${encodeURIComponent(analyticsID!)}`, analytics);
    } catch (error: any) {}
  };

  const handleRequest = async () => {
    editReportStatus(reportID, validateOfferStatus(status));
    const res = await serviceOsago.manageOffer(offerID, validateOfferStatus(status));

    if (res.success) {
        setNumber(res.data.number);
        setStatus(StatusOffer.SENDING_OTP);  
        handleSendOTP();
        editReportStatus(reportID, validateOfferStatus(StatusOffer.SENDING_OTP));
        setError(false);
        setErrorMsg("");
    } else {
        setError(true);
        setErrorMsg(`Помилка! ${parseEUAErrorMessageByResponse(res)}`);
    }
  };

  const handleSendOTP = async () => {
    const res = await serviceOsago.sendOPT(offerID);

    if (res.success) {
        setStatus(StatusOffer.SENDED_OTP);
        editReportStatus(reportID, validateOfferStatus(StatusOffer.SENDED_OTP));
        setError(false);
        setErrorMsg("");
    } else {
        setError(true);
        setErrorMsg(`Помилка! Не вдалось відправити код підтвердження. Код помилки: ${res.code}`);
    }
  };

  const onSubmit = async (code: string) => {
    const res = await serviceOsago.confirmOPT({
      id: offerID,
      password: code
    });

    if (res.success && res.data === "OK") {
        setStatus(StatusOffer.PAY_OFFER);
        editReportStatus(reportID, validateOfferStatus(StatusOffer.PAY_OFFER));
        setError(false);
        setErrorMsg("");
    } else {
        setError(true);
        setErrorMsg("Ви ввели невірний пароль підтвердження!");
    }
  };

  const handleCloseLiqpay = (data: any) => {
    setOfferAccepted(data);
  };

  const handleReadyLiqpay = (data: any) => {};

  const handleCallbackLiqpay = (data: any) => {
    setOfferAccepted(data);
  };

  const setOfferAccepted = async (data: any) => {
    try {
        cancelWfpRequest();
        setIsPaymentComplete(true);

        if (data) {
            if (data.status === "success") {
                let offerStatus = StatusOffer.PAID_OFFER;
                setStatus(offerStatus);
                editReportStatus(reportID, validateOfferStatus(offerStatus));
                offerStatus = StatusOffer.EMITTED;
                const res = await serviceOsago.manageOffer(offerID, validateOfferStatus(offerStatus));

                if (res.success) {
                    setStatus(offerStatus);
                    setError(false);
                    setErrorMsg("");
                    editReportStatus(reportID, validateOfferStatus(offerStatus));
                    updateAnalyticsData();
                } else {
                    setError(true);
                    setErrorMsg(`Помилка! Не вдалось укласти договір. Код помилки: ${res.code}`);
                }
            } else if (data.status === "wait_secure") {
                setError(true);
                setErrorMsg("Платіж на перевірці! Очікуйте на Ваш поліс протягом кількох хвилин.");
            } else {
                setError(true);
                setErrorMsg(`Помилка! Не вдалось здійснити платіж. Статус: ${data.status}`);
            }
        } else {
            setError(true);
            setErrorMsg("Помилка! Не вдалось здійснити платіж.");
        }
    } catch (e: any) {
        setError(true);
        setErrorMsg(e.mesage);
    }
  };

  const closeNotificationMsg = () => {
    setErrorMsg("");
  };

  if (!offerID) {
      return <div>Щось пiшло не так</div>;
  }

  const handleApproved = async (data) => {
    const {orderReference, transactionStatus, reason, reasonCode} = data;

    if (orderReference && transactionStatus === webConfig.wfpTransactionStatus && reason === webConfig.wfpReason && reasonCode === Number(webConfig.wfpReasonCode)) {
        setIsPaymentComplete(true);
        const offerStatus = StatusOffer.EMITTED;
        const res = await serviceOsago.manageOffer(offerID, validateOfferStatus(offerStatus));

        if (res.success) {
            setStatus(offerStatus);
            setError(false);
            setErrorMsg("");
            editReportStatus(reportID, validateOfferStatus(offerStatus));
            updateAnalyticsData();
        } else {
            setError(true);
            setErrorMsg(`Помилка! Не вдалось укласти договір. Код помилки: ${res.code}`);
        }
    } else {
        setError(true);
        setErrorMsg("Помилка! Не вдалось здійснити платіж.");
    }
  };

  const handleWfpPaymentState = (data) => {
    const {orderReference, reasonCode} = data;
    setError(true);
    setErrorMsg(`Помилка! Замовлення №${orderReference}. Код неуспішного платежу: ${reasonCode}!`);
  };

  const handleDeclined = (data) => {
    handleWfpPaymentState(data);
  };

  const handlePending = (data) => {
    handleWfpPaymentState(data);
  };

  const handleUnknown = (data) => {
    handleWfpPaymentState(data);
  };

  const handleError = (error) => {
    const wfpError = error.message || error;
    setError(true);
    setErrorMsg(`Помилка! ${wfpError}`);
  };

  const paymentEngines: TabProp[] = [
    {
      index: 1,
      label: "Оплата через WayForPay",
      isDisabled: wfpTabDisabled,
      children: <WayForPayPage isWidget={wfpWidgetEnabled} type={type} onApproved={handleApproved} onDeclined={handleDeclined} onPending={handlePending} onUnknown={handleUnknown} onError={handleError} />
    },
    {
      index: 2,
      isDisabled: liqPayTabDisabled,
      label: "Оплата через LiqPay",
      children: <LiqPayPage type={type} onCallback={handleCallbackLiqpay} onClose={handleCloseLiqpay} onReady={handleReadyLiqpay} />
    }
  ];

  return (
    <div>
      {number && (
        <p
          style={{
            textAlign: "center",
            padding: "2rem 0",
          }}>
          <a
            href={webConfig.mtsbuPolicyValidationLink}
            target="_blank"
            style={{
              fontWeight: "bold",
              marginRight: "1rem",
            }}>
            Перевірка чинності полісу внутрішнього страхування
          </a>
          № {number}
        </p>
      )}
      {status === StatusOffer.REQUEST && !isError && (
        <CustomLoader className="status-request" isSquare={true} isInfoOn={true} isLoading={true} infoMsg="Заявка в обробцi" />
      )}
      {isError && (
        <div>
          {errorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{errorMsg}</div>}
          <div className="insurance-error-container">
            <button type="button" className="insurance-btn" onClick={() => handleInsuranceNav("epolicy", dispatch, navigate)}>Автоцивілка</button>
            <button type="button" className="insurance-btn" onClick={() => handleInsuranceNav("greencard", dispatch, navigate)}>Зелена карта</button>
            <button type="button" className="insurance-btn" onClick={() => navToHomePage(navigate)}>На головну</button>
          </div>
        </div>
      )}
      {status === StatusOffer.SENDED_OTP && (
        <div className="status-sended">
          <VerificationCode name="code" count={6} title="Введіть код вiдправлений на Ваш телефон" expTime={expirationTime} inputType="number" inputMode="numeric" allowedChars={allowedCharacters} onVerificationCodeResend={handleSendOTP} onVerificationComplete={onSubmit} />
        </div>
      )}
      {status === StatusOffer.PAY_OFFER && !isPaymentComplete && (
        <div>
          <TabsComponent tabs={paymentEngines} defaultTab={1} orientation="horizontal" tabsDisabledMsg="Платіжні системи тимчасово недоступні!" isContentPadding={true} />
        </div>
      )}
      {status === StatusOffer.PAID_OFFER && isPaymentComplete && !isError && (
        <CustomLoader className="status-request" isSquare={false} isInfoOn={false} isLoading={true} />
      )}
      {(status === StatusOffer.EMITTED && isPaymentComplete) && (
        <>
        {
          type === "greencard" && greencardCompanyData.isCashbackEnabled && <CashbackCardComponent id={reportID} />
        }
        <div className="status-completed">
          <div>
            <h3>Страховий поліс успішно укладений і надісланий на Ваш email.</h3>
          </div>
          <Link
            style={{
              marginTop: "4rem",
            }}
            to="/"
            className="btn btn-insurance-link btn-edit">
            На головну
          </Link>
        </div>
        </>
      )}
    </div>
  );
};

export default StepFive;