import {RootState} from "../index";
import {CreatorReducer} from "../base/base";
import {ITokenActions, ITokenState} from "./tokenTypes";
import serviceOsago from "../../service/serviceOsago";

const initState: ITokenState = {
  error: false,
  token: ""
};

const creator = new CreatorReducer<ITokenActions, ITokenState>("token");
creator.addAction("SET_TOKEN",
  ((state, action) => {
    return {...state, token: action.payload}
  }));
creator.addAction("SET_ERROR",
  ((state, action) => {
    return {token: "", dbToken: "", error: action.payload}
  }));
creator.addAction("CLEAR",
  state => {
    return state;
  });

const {setError, setToken, clear} = creator.createActions();

const getTokenFetch = () => async (dispatch: any) => {
  try {
    const res = await serviceOsago.getToken();

    if (!res.headers["authorization"]) {
        throw new Error("Not token");
    }

    dispatch(setToken(res.headers["authorization"]));
  } catch (e) {
    dispatch(setError(e));
  }
};

const getToken = (state: RootState) => {
  return state.tokens.token;
};

export {getTokenFetch, getToken, clear}
export default creator.createReducer(initState);
