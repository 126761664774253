import {applyMiddleware, compose, createStore, Store} from "redux";
import reducers from "./redux-data";
import thunkMiddleware from "redux-thunk";
import {persistReducer, persistStore} from "redux-persist";
import localForage from "localforage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const persistConfig = {
  key: "pessDB", // Key for persisting, for example: root
  storage: localForage, // Default localForage storage
  stateReconciler: autoMergeLevel2, // How to reconcile initial and persisted states
  keyPrefix: "",
  serialize: true,
  debug: false
};

const persistedReducer = persistReducer(persistConfig, reducers as any);

export default function configureStore(initialState = {}): {store: Store; persistor: any} {
  const middlewares = [thunkMiddleware];
  const composeEnhancers = (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const enhancers = composeEnhancers(applyMiddleware(...middlewares));
  const store = createStore(persistedReducer, initialState, enhancers);
  const persistor = persistStore(store);

  (store as any).asyncReducers = {}; // Async reducer registry

  return {store, persistor};
}
