import React, {useEffect, useState} from "react";
import {useNavigate, Link, useParams} from "react-router-dom";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {useSelector} from "react-redux";
import {checkAuthToken, countRecords, filterRecords, isMatchCaseSearch} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import webConfig from "../../../config";
import FilterPagination from "../../../utils/FilterPagination";
import DataFilter from "../../../utils/DataFilter";
import {getEagentToken} from "../../../redux-data/misc/miscReducer";

const GreencardPage = (props) => {
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const eAgentToken = useSelector(getEagentToken);
    const dashboardNavigate = useNavigate();
    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();
    const [greencardData, setGreencardData] = useState<any[]>([]);
    const [greencardErrorMsg, setGreencardErrorMsg] = useState("");
    const [authInProgress, setAuthInProgress] = useState(false);
    const [greencardCompaniesCount, setGreencardCompaniesCount] = useState(0);
    const [recordsOnPage, setRecordsOnPage] = useState<any[]>([]);
    const [hiddenState, setHiddenState] = useState<any[]>([]);
    const [filteredItems, setFilteredItems] = useState<any[]>([]);
    const [filterCompleted, setFilterCompleted] = useState(false);
    const [processingFilter, setProcessingFilter] = useState(false);
    const [isMatchCaseFilter, setMatchCaseFilter] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken(eAgentToken)) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardNavigate("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardNavigate("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        const fetchGreencardProducts = async () => {
            try {
                const res = await dbAxiosInstance.get("/greencard/companies");
                const dbGreencardData = res.data;

                if (dbGreencardData) {
                    if (dbGreencardData.length > 0) {
                        setGreencardData(dbGreencardData);
                        setFilteredItems(dbGreencardData);
                        const isCompanyHidden = dbGreencardData.map((item, index) => {
                            return Boolean(item.is_hidden);
                        });
                        setHiddenState(isCompanyHidden);
                        setGreencardErrorMsg("");
                    } else {
                        setGreencardData([]);
                        setFilteredItems([]);
                        setGreencardErrorMsg("Немає доданих страхових продуктів зеленої карти. Натисніть на кнопку придбати зелену карту і загрузіть пропозиції, після чого страхові продукти зеленої карти будуть доступні в панелі адміністратора.");
                    }
                } else {
                    setGreencardData([]);
                    setFilteredItems([]);
                    setGreencardErrorMsg("Помилка БД! Не вдалося завантажити страхові продукти зеленої карти.");
                }
            } catch (error: any) {
                setGreencardData([]);
                setFilteredItems([]);
                setGreencardErrorMsg("Помилка БД! Не вдалося завантажити страхові продукти зеленої карти.");
            }
        };

        const countAllCompanies = async () => {
            try {
                const res = await dbAxiosInstance.get("/greencard/count-companies");
                const dbCountGreencardCompaniesData = res.data;

                if (dbCountGreencardCompaniesData) {
                    if (dbCountGreencardCompaniesData.length > 0) {
                        setGreencardCompaniesCount(dbCountGreencardCompaniesData[0].counter);
                    } else {
                        setGreencardCompaniesCount(0);
                    }
                } else {
                    setGreencardCompaniesCount(0);
                }
            } catch (error: any) {
                setGreencardCompaniesCount(0);
            }
        };

        const checkSearchType = async () => {
            const isOn = await isMatchCaseSearch();
            setMatchCaseFilter(isOn);
        };

        fetchGreencardProducts();
        countAllCompanies();
        checkSearchType();
    }, []);

    const handleItemDelete = async (id) => {
        try {
            await dbAxiosInstance.delete(`/greencard/company/${encodeURIComponent(id)}`);
            setRecordsOnPage(filterRecords(recordsOnPage, id));
            const allRecords = countRecords(greencardCompaniesCount);
            setGreencardCompaniesCount(allRecords);

            if (allRecords === 0) {
                setGreencardErrorMsg("Немає доданих страхових продуктів зеленої карти. Натисніть на кнопку придбати зелену карту і завантажте пропозиції, після чого страхові продукти зеленої карти будуть доступні в панелі адміністратора.");
            }
        } catch (error: any) {
            const userDataError = error.toJSON();
            setGreencardErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setGreencardData([]);
        }
    };

    const handleItemVisibility = async (position, id) => {
        try {
            const updatedHiddenState = hiddenState.map((item, index) => {
                return index === position ? !item : item;
            });
            await dbAxiosInstance.put(`/greencard/edit-company-visibility/${encodeURIComponent(id)}`, {isHidden: updatedHiddenState[position]});
            setHiddenState(updatedHiddenState);
        } catch (error: any) {
            const userDataError = error.toJSON();
            setGreencardErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setGreencardData([]);
        }
    };

    const handleRecordsOnPage = (records) => {
        setRecordsOnPage(records);
    };

    return (
         <div>
            <div className="container">
                <div className="section-white">
                    {authInProgress ?
                       <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                     :
                       <>
                        <div className="back-icon">
                            <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                        </div>
                        <div className="dashboard-container">
                            <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo; Зелена карта</h1>                            
                            <div className="itemsCounter">
                                <p>Всього: <span className="font-bold">{greencardCompaniesCount}</span></p>
                            </div>
                            <DataFilter items={greencardData} matchCase={isMatchCaseFilter} placeholderText="Введіть дані страхової компанії для пошуку..." onFilterCompleted={(state) => setFilterCompleted(state)} onProcessingFilter={(state) => setProcessingFilter(state)} onFilteredItems={(filteredItems) => setFilteredItems(filteredItems)} itemNotFoundText="За Вашим запитом такої компанії Зеленої карти не знайдено!" />
                            <div className="dashboard">
                                <ul className="dashboard-items">
                                    {greencardCompaniesCount > 0 && recordsOnPage.length > 0 ? recordsOnPage.map((greencardCompany, index) =>
                                        <li key={index}>
                                            <div className="manage-item-wrapper">
                                                <div className="manage-item-container">
                                                    <CustomTooltip msg="Редагувати">
                                                        <Link className="edit-item" to={{pathname: `/admin/dashboard/${id}/${externalID}/greencard/edit-company/${greencardCompany.product_code}/${greencardCompany.company_id}/${greencardCompany.id}`}}>
                                                            <i className="fa fa-pencil-alt"></i>
                                                        </Link>
                                                    </CustomTooltip>
                                                    <CustomTooltip msg={`${hiddenState[index] ? "Показати" : "Приховати"}`}><a className="hide-item" onClick={() => handleItemVisibility(index, greencardCompany.id)}><i className={`fa-solid ${hiddenState[index] ? "fa-eye-slash" : "fa-eye"}`}></i></a></CustomTooltip>
                                                    <CustomTooltip msg="Видалити"><a className="delete-item" onClick={() => handleItemDelete(greencardCompany.id)}><i className="fa fa-trash"></i></a></CustomTooltip>
                                                </div>
                                            </div>
                                            <div className="dashboard-item">                     
                                                <Link to={{pathname: `/admin/dashboard/${id}/${externalID}/greencard/view-company/${greencardCompany.product_code}/${greencardCompany.company_id}/${greencardCompany.id}`}} key={greencardCompany.id}>{greencardCompany.name}</Link>
                                            </div>
                                        </li>) : <li><div className="dashboard-error">{greencardErrorMsg}</div></li>
                                    }
                                </ul>
                            </div>
                            <FilterPagination data={filteredItems} recordsPerPage={Number(webConfig.contentPerPage)} dataOnPage={handleRecordsOnPage} isDataLoaded={filterCompleted} setDataLoaded={(state) => setFilterCompleted(state)} isFilterProcessing={processingFilter} setFilterState={(state) => setProcessingFilter(state)} />
                        </div>
                       </>
                    }
                </div>
            </div>
        </div>
    );
};

export default GreencardPage;