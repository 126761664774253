import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import serviceOsago from "../../service/serviceOsago";
import {dbAxiosInstance} from "../../core/configDBAxios";
import webConfig from "../../config";
import Pagination from "../../utils/Pagination";
import {getCurrentUser, getCurrentUserInfo, clearUserInfo} from "../../redux-data/user/userReducer";
import {useSelector, useDispatch} from "react-redux";
import {checkAuthToken, filterRecords, formatEmail} from "../../utils/utilsGeneral";
import CustomLoader from "../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../components/Forms/common/CustomTooltip";
import {getEagentToken, clearEagentToken} from "../../redux-data/misc/miscReducer";
import {ICurrentUser} from "../../typings/IUser";

const DashboardPage = (props) => {
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const eAgentToken = useSelector(getEagentToken);
    const dashboardNavigate = useNavigate();
    const dispatch = useDispatch();
    const {id, externalID} = useParams<{
        id: string; 
        externalID: string;
    }>();
    const [balanceError, setBalanceError] = useState("");
    const [balanceNotification, setBalanceNotification] = useState("");
    const [hideBalanceNotification, setBalanceNotificationHidden] = useState(false);
    const [dashboardItems, setDashboardItems] = useState<any[]>([]);
    const [dashboardErrorMsg, setDashboardErrorMsg] = useState("");
    const [adminAccountData, setAdminAccountData] = useState<Pick<ICurrentUser, "fundBalance" | "creditLimit">>({
        fundBalance: currentUser?.fundBalance || 0,
        creditLimit: currentUser?.creditLimit || 0
    });
    const [recordsOnPage, setRecordsOnPage] = useState<any[]>([]);
    const [authInProgress, setAuthInProgress] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken(eAgentToken)) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (currentUser.sessionId && currentUserInfo?.permissions.salePointAdmin) {
                        setAdminAccountData((prev) => ({...prev,
                            fundBalance: currentUser?.fundBalance,
                            creditLimit: currentUser?.creditLimit
                        }));
                    } else {
                        dashboardNavigate("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardNavigate("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        const fetchAllDashboardItems = async () => {
            try {
                const res = await dbAxiosInstance.get("/dashboard-items");
                const dbDashboardData = res.data;

                if (dbDashboardData) {
                    if (dbDashboardData.length > 0) {
                        setDashboardItems(dbDashboardData);
                        setDashboardErrorMsg("");
                    } else {
                        setDashboardItems([]);
                        setDashboardErrorMsg("Немає доданих елементів панелі керування. Натисніть на кнопку, щоб додати елемент панелі керування.");
                    }
                } else {
                    setDashboardItems([]);
                    setDashboardErrorMsg("Помилка БД! Не вдалося завантажити елементи панелі керування.");
                }
            } catch (error: any) {
                setDashboardItems([]);
                setDashboardErrorMsg("Помилка БД! Не вдалося завантажити елементи панелі керування.");
            }
        };

        fetchAllDashboardItems();
    }, []);

    useEffect(() => {
        const balanceTimer = setTimeout(() => {
            setBalanceNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(balanceTimer);
    }, [hideBalanceNotification]);

    const handleExit = () => {
        setAdminAccountData((prev) => ({...prev,
            fundBalance: 0,
            creditLimit: 0,
        }));
        dispatch(clearEagentToken());
        dispatch(clearUserInfo());
        dashboardNavigate("/");
    };

    const handleBalanceUpdate = async () => {
        try {
            setAdminAccountData((prev) => ({...prev,
                fundBalance: "оновлення...",
                creditLimit: "оновлення...",
            }));
            const data = await serviceOsago.getCurrentUser();
            setAdminAccountData((prev) => ({...prev,
                fundBalance: data.fundBalance,
                creditLimit: data.creditLimit
            }));
            setBalanceError("");
            setBalanceNotificationHidden(false);
            setBalanceNotification("Баланс успішно оновлено.");
        } catch (e: any) {
            const balanceError = e.toJSON();
            setAdminAccountData((prev) => ({...prev,
                fundBalance: "Помилка оновлення",
                creditLimit: "Помилка оновлення"
            }));
            setBalanceError(e.response?.data?.message || balanceError.message || "Unknown error");
            setBalanceNotificationHidden(true);
            setBalanceNotification("");
        }
    };

    const handleItemDelete = async (id) => {
        try {
            await dbAxiosInstance.delete(`/dashboard-items/${encodeURIComponent(id)}`);
            const recordsRes = filterRecords(recordsOnPage, id);
            setRecordsOnPage(recordsRes);

            if (recordsRes.length === 0) {
                setDashboardErrorMsg("Немає доданих елементів панелі керування. Натисніть на кнопку, щоб додати елемент панелі керування.");
            }
        } catch (error: any) {
            const userDataError = error.toJSON();
            setDashboardErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setDashboardItems([]);
        }
    };

    const closeNotificationMsg = () => {
        setBalanceNotificationHidden(true);
    };

    const handleRecordsOnPage = (records) => {
        setRecordsOnPage(records);
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                    {authInProgress ?
                        <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                     :
                        <>
                        <div className="back-icon">
                            <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                        </div>
                        <div className="dashboard-container">
                            <h1>Привіт, {currentUserInfo?.firstName}!</h1>
                            {balanceError && 
                                <div className="admin-error-notification"><i className="fa fa-exclamation-triangle notification-icon"></i>{balanceError}</div>
                            }
                            {!hideBalanceNotification && balanceNotification &&
                                <div className="admin-success-notification"><i className="fa-regular fa-check notification-icon"></i><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{balanceNotification}</div>
                            }
                            <p><span className="admin-item-title">Мій email:</span> <span dangerouslySetInnerHTML={{__html: formatEmail(currentUserInfo?.email!)}} /></p>
                            <div className="admin-balance">
                                <p><span className="admin-item-title">Баланс:</span> {adminAccountData.fundBalance} ₴</p>
                                <p><span className="admin-item-title">Кредитний ліміт:</span> {adminAccountData.creditLimit} ₴</p>
                                <CustomTooltip msg="Оновити баланс"><a onClick={handleBalanceUpdate}><i className="fa-regular fa-arrows-rotate"></i></a></CustomTooltip>
                            </div>
                            <div className="dashboard">
                                <ul className="dashboard-items">
                                    <li>
                                        <div className="dashboard-item">
                                            <CustomTooltip msg="Додати"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/add-dashboard-item`}}><i className="fa fa-plus"></i></Link></CustomTooltip>
                                        </div>
                                    </li>
                                    {recordsOnPage.length > 0 ? recordsOnPage.map((dashboardItem, index) => (
                                        dashboardItem.name === "Вихід" ?
                                        <li key={index}>
                                            <div className="manage-item-wrapper">
                                                <div className="manage-item-container">
                                                    <CustomTooltip msg="Редагувати">
                                                        <Link className="edit-item" to={{pathname: `/admin/dashboard/${id}/${externalID}/edit-dashboard-item/${dashboardItem.url}/${dashboardItem.id}`}}>
                                                            <i className="fa fa-pencil-alt"></i>
                                                        </Link>
                                                    </CustomTooltip>
                                                    <CustomTooltip msg="Видалити"><a className="delete-item" onClick={() => handleItemDelete(dashboardItem.id)}><i className="fa fa-trash"></i></a></CustomTooltip>
                                                </div>
                                            </div>
                                            <div className="dashboard-item">
                                                <a className="dashboard-link" onClick={handleExit}>Вихід</a>
                                            </div>
                                        </li>
                                        :
                                        <li key={index}>
                                            <div className="manage-item-wrapper">
                                                <div className="manage-item-container">
                                                    <CustomTooltip msg="Редагувати">
                                                        <Link className="edit-item" to={{pathname: `/admin/dashboard/${id}/${externalID}/edit-dashboard-item/${dashboardItem.url}/${dashboardItem.id}`}}>
                                                            <i className="fa fa-pencil-alt"></i>
                                                        </Link>
                                                    </CustomTooltip>
                                                    <CustomTooltip msg="Видалити"><a className="delete-item" onClick={() => handleItemDelete(dashboardItem.id)}><i className="fa fa-trash"></i></a></CustomTooltip>
                                                </div>
                                            </div>
                                            <div className="dashboard-item">
                                                <Link to={{pathname: `/admin/dashboard/${id}/${externalID}/${dashboardItem.url}`}} key={dashboardItem.id}>{dashboardItem.name}</Link>
                                            </div>
                                        </li>)) : <li><div className="dashboard-error">{dashboardErrorMsg}</div></li>
                                    }
                                </ul>
                            </div>
                            <Pagination data={dashboardItems} recordsPerPage={Number(webConfig.contentPerPage)} dataOnPage={handleRecordsOnPage} />
                        </div>
                      </>
                    }
                </div>    
            </div>
        </div>
    );
};

export default DashboardPage;