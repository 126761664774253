import {RootState} from "../index";
import {CreatorReducer} from "../base/base";
import {MiscActions, MiscState} from "./miscTypes";

const initState: MiscState = {
  eAgentToken: ""
};

const creator = new CreatorReducer<MiscActions, MiscState>("misc");
creator.addAction("SET_EAGENT_TOKEN",
((state, action) => {
  return {...state, eAgentToken: action.payload}
}));
creator.addAction("CLEAR_EAGENT_TOKEN",
  (state) => {
    return {...initState};
});

const {setEagentToken, clearEagentToken} = creator.createActions();

const getEagentToken = (state: RootState) => state.misc.eAgentToken;

export {setEagentToken, clearEagentToken, getEagentToken};
export default creator.createReducer(initState);