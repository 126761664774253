import React from "react";
import {validateHtml} from "../../../utils/utilsGeneral";
import webConfig from "../../../config";
import isWebConfigOn from "to-boolean";

const BBCodeComponent = ({content, isEmail, isModalOpen}) => {
    const parseBBCode = (text, isEmail) => {
        const parsedText = text
        .replace(/\[b\]([\s\S]*?)\[\/b\]/g, "<strong>$1</strong>")
        .replace(/\[i\]([\s\S]*?)\[\/i\]/g, "<em>$1</em>")
        .replace(/\[u\]([\s\S]*?)\[\/u\]/g, "<u>$1</u>")
        .replace(/\[s\]([\s\S]*?)\[\/s\]/g, "<s>$1</s>")
        .replace(/\[inline\]([\s\S]*?)\[\/inline\]/g, "<div class=\"inline-tag\" align=\"center\">$1</div>")
        .replace(/\[color=(#[0-9A-F]{6}|[a-z]+|[rgb(\d{1,3},\d{1,3},\d{1,3}(\s?))]+)\]([\s\S]*?)\[\/color\]/g, "<span style=\"color: $1\">$2</span>")
        .replace(/\[left\]([\s\S]*?)\[\/left\]/g, "<div align=\"left\">$1</div>")
        .replace(/\[center\]([\s\S]*?)\[\/center\]/g, "<div align=\"center\">$1</div>")
        .replace(/\[right\]([\s\S]*?)\[\/right\]/g, "<div align=\"right\">$1</div>")
        .replace(/\[img=(.*?)\]([\s\S]*?)\[\/img\]/g, (match, title, src) => {
            return `<div class="tooltip-wrapper" data-title="${title}" data-src="${src}"><img class="image-tag" src="${src}" alt="${title}"></div>`;
        })
        .replace(/\[quote\]([\s\S]*?)\[\/quote\]/g, (match, content) => {
            const websiteUrl = isWebConfigOn(webConfig.isDev) ? webConfig.pessLocal : webConfig.pessWebsite;
            return isEmail ? `<blockquote class="email-quote-tag"><div class="email-quote-open" align="left"><img src="${websiteUrl}/img/quote-left.png" alt="quote-open"></div>${content.trim()}<div class="email-quote-close" align="right"><img src="${websiteUrl}/img/quote-right.png" alt="quote-close"></div></blockquote>` : `<blockquote class="quote-tag">${content.trim()}</blockquote>`;
        })
        .replace(/\[url=(.*?)\]([\s\S]*?)\[\/url\]/g, "<a class=\"url-tag\" href=\"$1\">$2</a>")
        .replace(/\[code\]([\s\S]*?)\[\/code\]/g, (match, content) => {
            return `<pre class="code-tag">${validateHtml(content.trim())}</pre>`;
        })
        .replace(/\[spoiler=(.*?)\]([\s\S]*?)\[\/spoiler\]/g, (match, title, content) => {
            return `<div class="spoiler-wrapper" data-title="${title}" data-content="${validateHtml(content.trim())}"></div>`;
        })
        .replace(/\[hr\]/g, "<hr class=\"horizontal-line-tag\">")
        .replace(/\[li\]([\s\S]*?)\[\/li\]/g, "<li class=\"list-tag\">$1</li>")
        .replace(/\[youtube\]((?:https?:\/\/)?(?:www\.)?(youtu\.be\/|youtube\.com\/watch\?v=)([^\s(&|?)]+)(?:[&|?]t=([^&\s]+))?)\[\/youtube\]/g, (match, url, domain, videoID, startTime) => {
            let youtube = "";

            if (startTime) {
                const start = startTime.replace("s", "");
                youtube = `<div class="youtube-wrapper"><iframe class="youtube-container" src="https://www.youtube.com/embed/${videoID}?start=${start}&enablejsapi=1&origin=${webConfig.pessWebsite}" loading="lazy" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`;
            } else {
                youtube = `<div class="youtube-wrapper"><iframe class="youtube-container" src="https://www.youtube.com/embed/${videoID}?enablejsapi=1&origin=${webConfig.pessWebsite}" loading="lazy" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`;
            }

            return youtube;
        })
        .replace(/\n/g, "<br>");
        return parsedText;
    };
    
    return <div dangerouslySetInnerHTML={{__html: parseBBCode(content, isEmail)}} />;
};

export default BBCodeComponent;