import {IOfferData, IOfferState} from "./offerTypes";
import {CreatorReducer} from "../base/base";
import {IBaseActions} from "../base/baseTypes";
import {RootState} from "../index";
import {ConvertToOffer} from "../../utils/ConvertToOffer";
import serviceOsago from "../../service/serviceOsago";
import {clear} from "../token/tokenReducer";

const init: IOfferState = {
  data: null,
  mark: null,
  model: null,
  error: null,
  offerId: null,
  isLoading: false
};

interface IOfferActions extends IBaseActions {
  setOfferMark: any;
  setOfferModel: any;
  setOfferId: any;
  setOfferEmail: any;
  setOfferDontHaveInn: any;
  setOfferDocumentType: any;
  setOfferDocumentServiceSelected: any;
  setOfferBankIdServiceInitialized: any;
  setOfferBankIdServiceAuthorized: any;
  setOfferIsTestBankIdDomain: any;
}

const creator = new CreatorReducer<IOfferActions, IOfferState>("offer");
creator.addAction("SET_OFFER_MARK",
  (state, action) => {
      return {...state, mark: action.payload}
  });
creator.addAction("SET_OFFER_MODEL",
  (state, action) => {
    return {...state, model: action.payload}
  });
creator.addAction("SET_OFFER_ID",
  (state, action) => {
    return {...state, offerId: action.payload}
  });
creator.addAction("SET_OFFER_EMAIL",
  (state, action) => {
    return {...state, data: {...state.data as IOfferData, email: action.payload}}
  });
creator.addAction("SET_OFFER_DONT_HAVE_INN",
  (state, action) => {
    return {...state, data: {...state.data as IOfferData, dontHaveCode: action.payload}}
  });
creator.addAction("SET_OFFER_DOCUMENT_TYPE", 
  (state, action) => {
    return {...state, data: {...state.data as IOfferData, documentType: action.payload}}
  });
creator.addAction("SET_OFFER_DOCUMENT_SERVICE_SELECTED", 
  (state, action) => {
    return {...state, data: {...state.data as IOfferData, documentServiceSelected: action.payload}}
  });
creator.addAction("SET_OFFER_BANK_ID_SERVICE_INITIALIZED", 
  (state, action) => {
    return {...state, data: {...state.data as IOfferData, bankIdServiceInitialized: action.payload}}
  });
creator.addAction("SET_OFFER_BANK_ID_SERVICE_AUTHORIZED",
  (state, action) => {
    return {...state, data: {...state.data as IOfferData, bankIdServiceAuthorized: action.payload}}
  });
creator.addAction("SET_OFFER_IS_TEST_BANK_ID_DOMAIN", 
  (state, action) => {
    return {...state, data: {...state.data as IOfferData, isTestBankIdDomain: action.payload}}
  });
creator.addAction(clear,
  (state) => {
    return {...init};
  });

const {
  setOfferModel, 
  setOfferId, 
  setOfferMark, 
  setOfferEmail, 
  setError, 
  setData, 
  setLoading, 
  setOfferDontHaveInn, 
  setOfferDocumentType, 
  setOfferDocumentServiceSelected, 
  setOfferBankIdServiceInitialized, 
  setOfferBankIdServiceAuthorized,
  setOfferIsTestBankIdDomain
} = creator.createActions();

const createOfferFetch = (type: "epolicy" | "greencard", data: IOfferData) => async (dispatch: any, getStore: any) => {
  dispatch(setLoading(true));
  try {
    const convert = new ConvertToOffer(data, getStore());
    const convertData = convert.convert(type);
    const res = await serviceOsago.createOffer(convertData);

    if (res.success) {
        dispatch(setOfferId(res.id));
        dispatch(setOfferEmail(data.email));
        return "";
    } else {
        return res.message;
    }
  } catch (e: any) {
      console.error(">>> ERROR: ", e.message);
      dispatch(setError(e.message));
      return "Будь ласка перевiрте введенi данi";
  } finally {
      dispatch(setLoading(false));
  }
};

const getOfferMark = (state: RootState) => state.offer.mark;
const getOfferModel = (state: RootState) => state.offer.model;
const getOfferMarkName = (state: RootState) => {
  const mark = getOfferMark(state);
  if(!mark) return null;
  return mark.name
};

const getOfferModelName = (state: RootState) => {
  const model = getOfferModel(state);
  if(!model) return null;
  return model.label
};


const getOfferData = (state: RootState) => state.offer.data;
const getCheckModel = (state: RootState) => {
  const mark = getOfferMark(state);
  const model = getOfferModel(state);
  return !(!mark || !model);
};

const getOfferId = (state: RootState) => state.offer.offerId;
const getOfferEmail = (state: RootState) => state.offer.data!.email;
const getOfferError = (state: RootState) => {
  return state.offer.error;
};

const getOfferDocumentType = (state: RootState) => state.offer.data?.documentType;
const getOfferDocumentServiceSelected = (state: RootState) => state.offer.data?.documentServiceSelected;
const getOfferBankIDServiceInitialized = (state: RootState) => state.offer.data?.bankIdServiceInitialized;
const getOfferBankIDServiceAuthorized = (state: RootState) => state.offer.data?.bankIdServiceAuthorized;
const getOfferIsTestBankIDDomain = (state: RootState) => state.offer.data?.isTestBankIdDomain;

enum StatusOffer {
  DRAFT,
  REQUEST,
  SENDING_OTP,
  SENDED_OTP,
  PAY_OFFER,
  PAID_OFFER,
  EMITTED
}

const validateOfferStatus = (status: number) => {
  let currentStatus = "";

  switch (status) {
    case StatusOffer.DRAFT:
         currentStatus = "DRAFT";
         break;
    case StatusOffer.REQUEST:
         currentStatus = "REQUEST";
         break;
    case StatusOffer.SENDING_OTP:
         currentStatus = "SENDING_OTP";
         break;
    case StatusOffer.SENDED_OTP:
         currentStatus = "SENDED_OTP";
         break;
    case StatusOffer.PAY_OFFER:
         currentStatus = "PAY_OFFER";
         break;
    case StatusOffer.PAID_OFFER:
         currentStatus = "PAID_OFFER";
         break;
    case StatusOffer.EMITTED:
         currentStatus = "EMITTED";
         break;
    default:
         currentStatus = "Unknown";
  }

  return currentStatus;
};

export {
  setData,
  setOfferModel,
  setOfferMark,
  setError,
  setLoading,
  getCheckModel,
  getOfferModel,
  getOfferMark,
  getOfferData,
  createOfferFetch,
  getOfferId,
  getOfferMarkName,
  getOfferModelName,
  StatusOffer,
  validateOfferStatus,
  getOfferEmail,
  setOfferDontHaveInn,
  setOfferDocumentType,
  setOfferDocumentServiceSelected,
  setOfferBankIdServiceInitialized,
  setOfferBankIdServiceAuthorized,
  setOfferIsTestBankIdDomain,
  getOfferDocumentType,
  getOfferDocumentServiceSelected,
  getOfferBankIDServiceInitialized,
  getOfferBankIDServiceAuthorized,
  getOfferIsTestBankIDDomain,
  getOfferError
};

export default creator.createReducerFetch(init);