import React from "react";
import SquareLoader from "react-spinners/SquareLoader";
import ScaleLoader from "react-spinners/ScaleLoader";

const CustomLoader = ({className = "", isSquare, isInfoOn, infoMsg = "", isLoading, color = "", css = ""}) => {
    const options = {
        height: 20,
        width: 20,
        radius: 0,
        margin: 10,
        color: "#1BA876",
    };

    return (
        <div className={className}>
            {isInfoOn && <p>{infoMsg}</p>}
            <>
                {isSquare ? <SquareLoader color={color ? color : options.color} size={options.width} loading={isLoading} css={css} /> : <ScaleLoader loading={isLoading} {...options} />}
            </>
        </div>
    );
};

export default CustomLoader;