import React from "react";

const TabList = ({tabs, currentTab, orientation, tabSelected}) => {
    const handleTabClick = (tabID) => {
        tabSelected(tabID);
    };

    return (
        tabs.map((tab) => {
            return <button key={tab.index} className={`${!tab.isDisabled && currentTab === `tab-${tab.index}` ? "active" : ""} ${tab.isDisabled ? "disabled" : ""} ${orientation}`} onClick={() => !tab.isDisabled && handleTabClick(`tab-${tab.index}`)} disabled={tab.isDisabled}>{tab.label}</button>;
        })
    );
};

export default TabList;