import React from "react";
import {insuranceSteps} from "../../../mock-data/insuranceTypes";
import {findInsuranceStepLabelByValue} from "../../../utils/utilsGeneral";

const InsuranceStep = ({step}) => {
    return (
        <>
        {
            step === insuranceSteps[0].value && <span className="insurance-step-one">{findInsuranceStepLabelByValue(step)}</span>
        }
        {
            step === insuranceSteps[1].value && <span className="insurance-step-two">{findInsuranceStepLabelByValue(step)}</span>
        }
        {
            step === insuranceSteps[2].value && <span className="insurance-step-three">{findInsuranceStepLabelByValue(step)}</span>
        }
        {
            step === insuranceSteps[3].value && <span className="insurance-step-four">{findInsuranceStepLabelByValue(step)}</span>
        }
        {
            step === insuranceSteps[4].value && <span className="insurance-step-five">{findInsuranceStepLabelByValue(step)}</span>
        }
        </>
    );
};

export default InsuranceStep;