import React from "react";
import {getPolicyExpirationDate} from "../../../utils/utilsDate";

const PolicyExpirationDate = ({date, isStyle}) => {
    const expirationDate = getPolicyExpirationDate(date);

    return (
        <>
            {expirationDate && expirationDate > 0 ? <span className={`${isStyle ? "font-bold" : "font-regular"}`}>{expirationDate} днів</span> : <span className="policy-expired-status">Поліс недійсний ({expirationDate} днів)</span>}
        </>
    );
};

export default PolicyExpirationDate;