import React from "react";
import CustomField from "../CustomField";

const chernobylCertificate = (name: string) => `chernobylCertificate.${name}`;

const ChernobylCertificateForm = (props) => {
  return (
    <>
      <div className={props.seriesClass}>
        <CustomField
          register={props.register({
            required: "Це поле обов'язкове",
            pattern: {
              value: /^[А-Я-ІЄЇ-а-я-ієї]{1}$/,
              message: "Серія складається з однієї літери кирилиці"
            },
          })}
          errors={props.errors}
          name={chernobylCertificate("series")}
          label="Серія"
          placeholder="А"
          readOnly={props.isReadOnly}
          defaultValue={props.documentSeriesValue}
          onChange={props.onDocumentSeriesChange}
        />
      </div>
      <div className={props.numberClass}>
        <CustomField
          register={props.register({
            required: "Це поле обов'язкове",
            pattern: {
              value: /\d{6}/,
              message: "Номер складається з шести цифр"
            },
            maxLength: {
              value: 6,
              message: "Номер складається з шести цифр"
            },
          })}
          errors={props.errors}
          name={chernobylCertificate("number")}
          label="Номер"
          placeholder="123456"
          readOnly={props.isReadOnly}
          defaultValue={props.documentNumberValue}
          onChange={props.onDocumentNumberChange}
        />
      </div>
      <div className={props.issuedDateClass}>
        <CustomField
          register={props.register({
            required: "Це поле обов'язкове"
          })}
          errors={props.errors}
          name={chernobylCertificate("date")}
          label="Коли видано"
          placeholder="РРРР.ММ.ДД"
          type="date"
          autoComplete="off"
          dateReadOnly={props.isReadOnly}
          dateValue={props.documentIssuedDateValue}
          onDateChange={props.onDocumentIssuedDateChange}
        />
      </div>
      <div className={props.issuedByClass}>
        <CustomField
          register={props.register({
            required: "Це поле обов'язкове",
          })}
          errors={props.errors}
          name={chernobylCertificate("issued_by")}
          label="Ким видано"
          placeholder="Адміністрація міста Києва"
          readOnly={props.isReadOnly}
          defaultValue={props.documentIssuedByValue}
          onChange={props.onDocumentIssuedByChange}
        />
      </div>
    </>
  );
};

export default ChernobylCertificateForm;
