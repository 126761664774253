import {RootState} from "../index";
import {CreatorReducer} from "../base/base";
import {StepsState, StepsActions} from "./stepsTypes";

const initState: StepsState = {
  idToInsuranceStep: {},
  insuranceStepToId: {}
};

const creator = new CreatorReducer<StepsActions, StepsState>("steps");
creator.addAction("SET_ID_TO_INSURANCE_STEP",
((state, action) => {
  return {...state, idToInsuranceStep: action.payload}
}));
creator.addAction("SET_INSURANCE_STEP_TO_ID", 
((state, action) => {
  return {...state, insuranceStepToId: action.payload}
}));
creator.addAction("CLEAR_STEPS", 
((state, action) => {
  return initState;
}));

const {setIdToInsuranceStep, setInsuranceStepToId, clearSteps} = creator.createActions();

const getIDToInsuranceStep = (state: RootState) => state.steps.idToInsuranceStep;

const getInsuranceStepToID = (state: RootState) => state.steps.insuranceStepToId;

export {setIdToInsuranceStep, setInsuranceStepToId, clearSteps, getIDToInsuranceStep, getInsuranceStepToID};
export default creator.createReducer(initState);