import serviceOsago from "../service/serviceOsago";
import {insuranceTypes, allPolicyStatus, privilegeTypes, websiteDocuments, diiaDocuments, insuranceSteps, bankIDDocuments, documentServices} from "../mock-data/insuranceTypes";
import {ISimpleData} from "../redux-data/offer/offerTypes";
import {ICustomerBirthDate} from "../redux-data/user/userType";
import {getDiscountPercentage, getCashback} from "../redux-data/insurance/insuranceSelector";
import webConfig from "../config";
import {dbAxiosInstance} from "../core/configDBAxios";
import {v4} from "uuid";
import {clear} from "../redux-data/token/tokenReducer";
import {BankIDData} from "../typings/IBankID";
import {validateAndFormatDate, validateAndFormatDateTime} from "./utilsDate";
const INN_MULTIPLIERS = [-1, 5, 7, 9, 4, 6, 10, 5, 7];

const filterRecords = (data, id) => {
    return data.filter((record) => record.id !== id);
};

const countRecords = (counter: number) => {
    return counter - 1;
}

const formatPhoneNumber = (number: string) => {
    return number.replace(/(\+\d{2})(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 ($2) $3 $4 $5");
};

const formatCardNumber = (number: string) => {
    return number.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, "$1 $2 $3 $4");
};

const formatEmail = (email: string) => {
    return `<a href="mailto:${email}">${email}</a>`;
};

const getInsuranceType = (type: string) => {
    return insuranceTypes.find((insuranceType) => insuranceType.value === type)!;
};

const getInsuranceTypeName = (type: string) => {
    return insuranceTypes.find((insuranceType) => insuranceType.value === type)?.label;
};

const getPrivilegeType = (type: string) => {
    return privilegeTypes.find((privilegeType) => privilegeType.value === type);
};

const checkEditAction = (action: string) => {
    return action === "edit" ? true : false;
};

const hasInsurance = (value: string) => {
  return insuranceTypes.some((item) => item.value === value);
};

const refreshPage = () => {
    window.location.reload();
};

const findVehicleModel = (models, modelName) => {
    return models.find((item: any) => item.label.toLowerCase() === modelName.toLowerCase());
};

const findDBVehicleModel = (models, modelName) => {
    const model = models.find((model: any) => model.name.toLowerCase() === modelName.toLowerCase());
    return model ? {label: model.name, value: model.id} : null;
};

const fetchVehicleModelByBrandID = async (brandID: string) => {
    const models = await dbAxiosInstance.get(`/all-vehicle/models/${encodeURIComponent(brandID)}`);
    return models.data;
};

const checkValue = (value) => {
    return value ? value.trim() : "";
};

const navToNewletter = (navigate, id, externalID) => {
    navigate({pathname: `/admin/dashboard/${id}/${externalID}/newsletter`});
};

const navToAdminPanel = (navigate, id, externalID) => {
    navigate({pathname: `/admin/dashboard/${id}/${externalID}`});
};

const navToCustomersPage = (navigate, id, externalID) => {
    navigate({pathname: `/admin/dashboard/${id}/${externalID}/customers`});
};

const navToCustomersEpolicyPage = (navigate, id, externalID) => {
    navigate({pathname: `/admin/dashboard/${id}/${externalID}/customers/epolicy`});
};

const navToCustomersGreencardPage = (navigate, id, externalID) => {
    navigate({pathname: `/admin/dashboard/${id}/${externalID}/customers/greencard`});
};

const navToGreencardCompaniesPage = (navigate, id, externalID) => {
    navigate({pathname: `/admin/dashboard/${id}/${externalID}/greencard`});
};

const navToEpolicyCompaniesPage = (navigate, id, externalID) => {
    navigate({pathname: `/admin/dashboard/${id}/${externalID}/epolicy`});
};

const navToReportsPage = (navigate, id, externalID) => {
    navigate({pathname: `/admin/dashboard/${id}/${externalID}/reports`});
};

const navToReportsEpolicyPage = (navigate, id, externalID) => {
    navigate({pathname: `/admin/dashboard/${id}/${externalID}/reports/epolicy`});
};

const navToReportsGreencardPage = (navigate, id, externalID) => {
    navigate({pathname: `/admin/dashboard/${id}/${externalID}/reports/greencard`});
};

const navToVehiclesPage = (navigate, id, externalID) => {
    navigate({pathname: `/admin/dashboard/${id}/${externalID}/vehicles`});
};

const navToAnalyticsPage = (navigate, id, externalID) => {
    navigate({pathname: `/admin/dashboard/${id}/${externalID}/analytics`});
};

const navToEpolicyCompany = (navigate, id, externalID, productID, epolicyID) => {
    navigate({pathname: `/admin/dashboard/${id}/${externalID}/epolicy/view-company/${productID}/${epolicyID}`});
};

const navToHomePage = (navigate) => {
    navigate({pathname: "/"});
};

const navToEpolicyPage = (navigate) => {
    navigate({pathname: "/epolicy"});
};

const navToGreencardPage = (navigate) => {
    navigate({pathname: "/greencard"});
};

const checkEuaVehicleBrand = async (brandName, name) => {
    const marks = await serviceOsago.getMarkCodeByName(brandName);
    const mark = marks && marks.length > 0 ? marks.find((m: any) => m.name === name) : null;
    return mark;
};

const fetchVehicleBrandByName = async (name: string) => {
    const brands = await dbAxiosInstance.get(`/vehicles/state-number/brands/${encodeURIComponent(name)}`);
    return brands.data;
};

const checkVehicleBrand = async (name) => {
    const brands = await fetchVehicleBrandByName(name);
    const brand = brands.find((brand: any) => JSON.parse(brand.auto_maker).name === name);
    const parsedBrand = brand?.auto_maker ? JSON.parse(brand.auto_maker) : null;
    return parsedBrand ? {id: parsedBrand.id, auto_maker: parsedBrand, kind: brand.kind, name: parsedBrand.name, last_modified: brand.last_modified} : null;
};

const findVehicle = async (brand, model) => {
    const marks = await fetchVehicleBrandByName(brand);
    const mark = marks && marks.length > 0 ? marks.find((vehicle) => JSON.parse(vehicle.auto_maker).name.toLowerCase().includes(brand.toLowerCase()) && vehicle.name.toLowerCase() === model.toLowerCase()) : null;
    return mark;
};

const fetchEuaVehicleBrands = async () => {
    const marks = await serviceOsago.getMarkCode();
    return marks;
};

const findEuaVehicleBrandByModel = async (modelText: string) => {
    let vehicleBrand = "";

    if (webConfig.doubleBrandNames!.toLowerCase().includes(`${modelText.split(" ")[0]} ${modelText.split(" ")[1]}`.toLowerCase())) {
        vehicleBrand = `${modelText.split(" ")[0]} ${modelText.split(" ")[1]}`;
    } else {
        vehicleBrand = modelText.split(" ")[0];
    }

    const marks = await serviceOsago.getMarkCodeByName(modelText);
    const mark = marks && marks.length > 0 ? marks.find((m: any) => m.name.toLowerCase().includes(vehicleBrand.toLowerCase())) : null;
    return mark;
};

const findVehicleBrandByModel = async (modelText: string) => {
    let vehicleBrand = "";

    if (isDoubleBrandNames(modelText)) {
        vehicleBrand = `${modelText.split(" ")[0]} ${modelText.split(" ")[1]}`;
    } else {
        vehicleBrand = modelText.split(" ")[0];
    }

    const marks = await fetchVehicleBrandByName(vehicleBrand);
    const mark = marks && marks.length > 0 ? marks.find((brand: any) => JSON.parse(brand.auto_maker).name.toLowerCase() === vehicleBrand.toLowerCase()) : null;
    return mark;
};

const isDoubleBrandNames = (modelText: string) => {
    return webConfig.doubleBrandNames!.toLowerCase().includes(`${modelText.split(" ")[0]} ${modelText.split(" ")[1]}`.toLowerCase());
};

const fetchVehicleData = (brand, model) => {
    const vehicleData: ISimpleData = {
        name: brand,
        id: "",
        mtsbuId: "",
        value: "",
        label: model
    };

    return vehicleData;
};

const fetchVehicleBrand = (brand) => {
    const vehicleBrand: any = {
        name: brand,
        id: "",
        mtsbuId: "",
        value: "",
        label: ""
    };

    return vehicleBrand;
};

const fetchVehicleDataByParams = (brand, brandID, cdbMtibuCode, modelID, model) => {
    const vehicleData: ISimpleData = {
        name: brand,
        id: brandID,
        mtsbuId: cdbMtibuCode,
        value: modelID,
        label: model
    };

    return vehicleData;
};

const validateINNCode = (characters) => {
  let sum = 0;
  const lastCharacterIndex = characters.length - 1;

  for (let index = 0; index < lastCharacterIndex; index++) {
       sum += parseInt(characters[index], 10) * INN_MULTIPLIERS[index];
  }

  const checkDigit = (sum % 11) % 10;
  return parseInt(characters[lastCharacterIndex], 10) === checkDigit;
};

const getJsDateFromExcel = (excelDate) => {
    return new Date(((excelDate - (25567 + 1)) * 86400 * 1000));
};

const convertINNToBirthDate = (innCode) => {
  const customerBirthDate: ICustomerBirthDate = {
    fullDate: null,
    month: "",
    day: "",
    year: "",
    gender: "",
    error: ""
  };
  let k1 = 0; // розрахунок контрольної цифри
  let k2 = 0; // розрахунок контрольної цифри
  let k3 = 0; // контрольна цифра розрахунку
  let k4 = 0; // остання цифра ІПН для порівняння
  let k5 = 0; // розрахунок передостанньої цифри (парна-непарна)
  let k6 = 0; // стать 0 парне жіноче 1 непарне чоловіче
  let k7 = 0; // 5 цифр дати

  k1 = (innCode.charAt(0) * (-1)) + (innCode.charAt(1) * 5) + (innCode.charAt(2) * 7) + (innCode.charAt(3) * 9) + (innCode.charAt(4) * 4) + (innCode.charAt(5) * 6) + (innCode.charAt(6) * 10) + (innCode.charAt(7) * 5) + (innCode.charAt(8) * 7);
  k2 = k1 - (11 * Math.floor(k1 / 11));
  k4 = innCode.charAt(9);
  k5 = innCode.charAt(8);
  k6 = (k5 - (2 * Math.floor(k5 / 2)));

  if (k2 == 10) {
      k3 = 0;
  } else {
      k3 = k2;
  }

  if (k4 == k3) {
      if (innCode.length == 10) {
          k7 = innCode.substring(0, 5);
          k7 = k7 * 1;
      } else {
          k7 = innCode.substring(0, 4);
          k7 = k7 * 1;
      }

      const birthDate = getJsDateFromExcel(k7);
      let day = "";
      let month = "";

      if (birthDate.getDate() < 10) {
          day = "0" + birthDate.getDate();
      } else {
          day = birthDate.getDate().toString();
      }

      if ((birthDate.getMonth() + 1) < 10) {
          month = "0" + (birthDate.getMonth() + 1);
      } else {
          month = (birthDate.getMonth() + 1).toString();
      }

      customerBirthDate.fullDate = birthDate;
      customerBirthDate.month = month;
      customerBirthDate.day = day;
      customerBirthDate.year = birthDate.getFullYear().toString();
      customerBirthDate.error = "";

      if (k6 == 0) {
          customerBirthDate.gender = "Жінка";
      } else {
          customerBirthDate.gender = "Чоловік";
      }
  } else {
      customerBirthDate.error = "Невірно вказаний ІПН";
  }

  return customerBirthDate;
};

const validatePhoneNumber = (number: string) => {
    return number.slice(3);
};

const validateOrderData = (data) => {
    return data ? data : false;
};

const navToGeneralSettings = (navigate, id, externalID) => {
    navigate({pathname: `/admin/dashboard/${id}/${externalID}/general-settings`});
};

const capitalizeFirstLetter = (data: string) => {
    return data.charAt(0).toUpperCase() + data.slice(1);
};

const checkAuthToken = (eAgentToken) => {
    let hasToken = false;

    if (eAgentToken) {
        hasToken = true;
    } else {
        hasToken = false;
    }

    return hasToken;
};

const checkOrderDiscount = (type, policy) => {
    return type === "epolicy" ? getDiscountPercentage(policy.amount, policy.discountedAmount) : policy.cashbackPercentage;
};

const checkOrderCoverageArea = (type, coverageArea) => {
    return type === "epolicy" ? "Україна" : coverageArea.label;
};

const checkOrderCashback = (policy) => {
    return getCashback(policy!.amount, policy!.cashbackPercentage).toFixed(2);
};

const checkOrderPeriod = (type, period) => {
    return type === "epolicy" ? "12 місяців" : period.label;
};

const clearCardNumber = (number: string) => {
    return number.replace(/\D+/g, "");
};

const getPolicyStatus = (status) => {
    return allPolicyStatus.find((pStatus) => pStatus.value === status)!;
};

const removeWhitespace = (data: string) => {
    return data.replace(/\s+/g, "");
};

const checkRequestStatus = (status) => {
    return status === 200 ? true : false;
};

const checkAnalyticsData = (data: string) => {
    return data ? true : false;
};

const isMatchCaseSearch = async (): Promise<boolean> => {
    try {
        const res = await dbAxiosInstance.get(`/general-setting-item/${encodeURIComponent(webConfig.matchCaseSearchItemID!)}`);
        const dbSettingsData = res.data;

        if (dbSettingsData) {
            if (dbSettingsData.length > 0) {
                return Boolean(dbSettingsData[0].is_active);
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (error: any) {
        return false;
    }
};

const isCompaniesSearchVisible = async (): Promise<boolean> => {
    try {
        const res = await dbAxiosInstance.get(`/general-setting-item/${encodeURIComponent(webConfig.displayCompaniesSearchID!)}`);
        const dbSettingsData = res.data;

        if (dbSettingsData) {
            if (dbSettingsData.length > 0) {
                return Boolean(dbSettingsData[0].is_active);
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (error: any) {
        return false;
    }
};

const parseEUAErrorMessageByResponse = (res) => {
    let errorMessage = "";
    const euaParsedError = JSON.parse(res.error.response.data.message);
    
    if (euaParsedError.constraintViolations && euaParsedError.constraintViolations.length > 0) {
        errorMessage = euaParsedError.constraintViolations[0].message;
    }

    return errorMessage;
};

const parseEUAErrorMessage = (error) => {
    let errorMessage = "";
    const euaParsedError = JSON.parse(error.response.data.message);
    
    if (euaParsedError.constraintViolations && euaParsedError.constraintViolations.length > 0) {
        errorMessage = euaParsedError.constraintViolations[0].message;
    }

    return errorMessage;
};

const handleInsuranceNav = (value: "epolicy" | "greencard", dispatch, navigate) => {
    dispatch(clear());
    navigate(`/${value}`, {replace: true});
};

const navToEpolicyValidation = () => {
    window.location.href = `${webConfig.mtsbuPolicyValidationLink}#tab1`;
};

const navToGreencardValidation = () => {
    window.location.href = `${webConfig.mtsbuPolicyValidationLink}#tab2`;
};

const isSettingItemActive = async (itemID: string): Promise<boolean> => {
    try {
        const res = await dbAxiosInstance.get(`/general-setting-item/${encodeURIComponent(itemID)}`);
        const dbSettingsData = res.data;
        
        if (dbSettingsData) {
            if (dbSettingsData.length > 0) {
                return Boolean(dbSettingsData[0].is_active);
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (error: any) {
        return false;
    }
};

const validateHtml = (data: string) => {
  return data
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
};

const validateQuotes = (data: string) => {
    return data.replace(/"/g, "&quot;");
};

const getCompanyYear = () => {
    const currentYear = new Date().getFullYear();
    return currentYear;
};

const htmlToText = (html: string) => {
    return html.replace(/<[^>]*>/g, "");
};

const bbCodeToText = (bbCode: string) => {
    return bbCode.replace(/\[([^\[\]]+)\](.*?)\[\/\1\]/g, "$2");
};

const generateUUID = () => {
    return v4();
};

const isVehicleOtkRequired = (vehicleKind, vehicleType) => {
    const otkVehicleKinds = webConfig.otkVehicleKinds?.split(", ");
    const otkTrailerKind = otkVehicleKinds![otkVehicleKinds!.length - 1];
    const otkKinds = otkVehicleKinds?.filter((kind) => kind !== otkTrailerKind);
    return (otkKinds?.includes(vehicleKind) || (vehicleKind === otkTrailerKind && vehicleType === webConfig.otkVehicleType));
};

const checkDiiaCustomerDocuments = (docs) => {
    return docs.filter((documentType) => documentType !== "vehicle-license").sort((a, b) => {
        if (a < b) {
            return -1;
        }

        if (a > b) {
            return 1;
        }

        return 0;
    });
};

const checkDocumentLabel = (diiaDocType) => {
    let documentLabel = "";

    switch (diiaDocType) {
        case "internal-passport":
            documentLabel = websiteDocuments[1].label;
            break;
        case "driver-license":
            documentLabel = websiteDocuments[2].label;
            break;
        case "foreign-passport":
            documentLabel = websiteDocuments[3].label;
            break;
        default:
            documentLabel = "";
    }

    return documentLabel;
};

const checkWebsiteDocumentByBankID = (bankIDDocType) => {
    let websiteDocType = "";

    switch (bankIDDocType) {
        case bankIDDocuments[0].value:
            websiteDocType = websiteDocuments[0].value;
            break;
        case bankIDDocuments[1].value:
            websiteDocType = websiteDocuments[1].value;
            break;
        case bankIDDocuments[2].value:
            websiteDocType = websiteDocuments[3].value;
            break;
        default:
            websiteDocType = "";
    }

    return websiteDocType;
};


const checkWebsiteDocumentByDiia = (diiaDocType) => {
    let websiteDocType = "";

    switch (diiaDocType) {
        case diiaDocuments[0].value:
            websiteDocType = websiteDocuments[1].value;
            break;
        case diiaDocuments[1].value:
            websiteDocType = websiteDocuments[2].value;
            break;
        case diiaDocuments[2].value:
            websiteDocType = websiteDocuments[3].value;
            break;
        default:
            websiteDocType = "";
    }

    return websiteDocType;
};

const checkDiiaDocumentType = (websiteDocType) => {
    let diiaDocType = "";

    switch (websiteDocType) {
        case websiteDocuments[1].value:
            diiaDocType = diiaDocuments[0].value;
            break;
        case websiteDocuments[2].value:
            diiaDocType = diiaDocuments[1].value;
            break;
        case websiteDocuments[3].value:
            diiaDocType = diiaDocuments[2].value;
            break;
        default:
            diiaDocType = "";
    }

    return diiaDocType;
};

const findWebsiteDocumentLabelByType = (documentType) => {
    return websiteDocuments.find((document) => document.value === documentType)?.label;
};

const findDocumentServiceLabelByType = (documentService) => {
    return documentServices.find((service) => service.value === documentService)?.label;
};

const checkGreencardINGOCompany = (insuranceType, franchiseID) => {
    return insuranceType === "greencard" && franchiseID === Number(webConfig.greencardINGOId);
};

const filterBankIDDocuments = (insuranceType) => {
    return insuranceType === "epolicy" ? bankIDDocuments.filter((document) => document.value !== bankIDDocuments[2].value) : bankIDDocuments;
};

const filterDiiaDocuments = (insuranceType) => {
    const diiaDocsOrder = [diiaDocuments[0].label, diiaDocuments[2].label, diiaDocuments[1].label];
    return insuranceType === "epolicy" ? diiaDocuments.filter((document) => document.value !== diiaDocuments[2].value && document.value != diiaDocuments[3].value) : diiaDocuments.filter((document) => document.value !== diiaDocuments[3].value).sort((a, b) => diiaDocsOrder.indexOf(a.label) - diiaDocsOrder.indexOf(b.label));
};

const filterWebsiteDocuments = (insuranceType) => {
    return insuranceType === "epolicy" ? websiteDocuments.filter((document) => document.value !== websiteDocuments[3].value) : websiteDocuments;
};

const findInsuranceLabelByType = (insuranceType) => {
    return insuranceTypes.find((type) => type.value === insuranceType)?.label;
};

const findInsuranceStepLabelByValue = (insuranceStep) => {
    return `${insuranceSteps.find((step) => step.value === insuranceStep)?.label} - ${insuranceStep} крок`;
};

const dialPhoneNumber = (number: string) => {
    return `<a href="tel:${number}">${number}</a>`;
};

const isWebsiteDocumentServiceSelected = (documentService: string) => {
    return documentService === "PESS" ? true : false;
};

const isDiiaDocumentServiceSelected = (documentService: string) => {
    return documentService === "DIIA" ? true : false;
};

const isBankIDDocumentServiceSelected = (documentService: string) => {
    return documentService === "BANKID" ? true : false;
};

const findBankIDCustomerDocument = (documents: BankIDData["customer"]["documents"], type: string) => {
    const bankIDDocument = documents.find((document) => document.type === type);
    return bankIDDocument ? {...bankIDDocument, dateIssue: validateAndFormatDate(bankIDDocument.dateIssue), dateExpiration: validateAndFormatDate(bankIDDocument.dateExpiration), dateModification: validateAndFormatDateTime(bankIDDocument.dateModification), 
                             type: checkWebsiteDocumentByBankID(type)} : undefined;
};

const findBankIDCustomerAddress = (addresses: BankIDData["customer"]["addresses"], type: string) => {
    const bankIDAddress = addresses.find((address) => address.type === type);
    return bankIDAddress ? {...bankIDAddress, dateModification: validateAndFormatDateTime(bankIDAddress.dateModification)} : undefined;
};

const validatePhoneNumberFormat = (number: string) => {
    return number.includes("+") ? number : `+${number}`;
};

const validateDocumentService = (service: string) => {
    return service ? findDocumentServiceLabelByType(service) : "Сайт";
};

const validateDocumentType = (documentType: string, customerDocType: string) => {
    return ((documentType ? findWebsiteDocumentLabelByType(documentType) : undefined) ?? (customerDocType ? findWebsiteDocumentLabelByType(customerDocType) : "Невідомо"));
};

export {
    filterRecords, 
    countRecords, 
    formatPhoneNumber,
    formatCardNumber,
    formatEmail,
    getInsuranceType, 
    getInsuranceTypeName, 
    getPrivilegeType,
    hasInsurance,
    refreshPage,
    checkEditAction,
    findVehicleModel,
    findDBVehicleModel,
    fetchVehicleModelByBrandID,
    checkValue,
    navToNewletter,
    navToAdminPanel,
    navToCustomersPage,
    navToCustomersEpolicyPage,
    navToCustomersGreencardPage,
    navToGreencardCompaniesPage,
    navToEpolicyCompaniesPage,
    navToReportsPage,
    navToReportsEpolicyPage,
    navToReportsGreencardPage,
    navToVehiclesPage,
    navToAnalyticsPage,
    navToEpolicyCompany,
    navToHomePage,
    navToEpolicyPage,
    navToGreencardPage,
    checkEuaVehicleBrand,
    fetchVehicleBrandByName,
    checkVehicleBrand,
    fetchVehicleData,
    fetchVehicleBrand,
    fetchVehicleDataByParams,
    validateINNCode,
    convertINNToBirthDate,
    validatePhoneNumber,
    validateOrderData,
    navToGeneralSettings,
    findVehicle,
    fetchEuaVehicleBrands,
    findEuaVehicleBrandByModel,
    findVehicleBrandByModel,
    isDoubleBrandNames,
    capitalizeFirstLetter,
    checkAuthToken,
    checkOrderDiscount,
    checkOrderCoverageArea,
    checkOrderCashback,
    checkOrderPeriod,
    clearCardNumber,
    getPolicyStatus,
    removeWhitespace,
    checkRequestStatus,
    checkAnalyticsData,
    isMatchCaseSearch,
    isCompaniesSearchVisible,
    parseEUAErrorMessageByResponse,
    parseEUAErrorMessage,
    handleInsuranceNav,
    navToEpolicyValidation,
    navToGreencardValidation,
    isSettingItemActive,
    validateHtml,
    validateQuotes,
    getCompanyYear,
    htmlToText,
    bbCodeToText,
    generateUUID,
    isVehicleOtkRequired,
    checkDiiaCustomerDocuments,
    checkDocumentLabel,
    checkWebsiteDocumentByBankID,
    checkWebsiteDocumentByDiia,
    checkDiiaDocumentType,
    findWebsiteDocumentLabelByType,
    findDocumentServiceLabelByType,
    checkGreencardINGOCompany,
    filterBankIDDocuments,
    filterDiiaDocuments,
    filterWebsiteDocuments,
    findInsuranceLabelByType,
    findInsuranceStepLabelByValue,
    dialPhoneNumber,
    isWebsiteDocumentServiceSelected,
    isDiiaDocumentServiceSelected,
    isBankIDDocumentServiceSelected,
    findBankIDCustomerDocument,
    findBankIDCustomerAddress,
    validatePhoneNumberFormat,
    validateDocumentService,
    validateDocumentType
};