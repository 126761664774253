import React from "react";
import {isMobile} from "react-device-detect";

const AnalyticsMap = ({position}) => {
    const mobileUrl = `https://www.bing.com/maps/embed/viewer.aspx?v=3&cp=${position[0]}~${position[1]}&lvl=13&w=400&h=400&sty=r&typ=d&pp=&ps=&dir=0&mkt=en-us&src=SHELL&form=BMEMJS`;
    const desktopUrl = `https://www.bing.com/maps/embed/viewer.aspx?v=3&cp=${position[0]}~${position[1]}&lvl=13&w=800&h=600&sty=r&typ=d&pp=&ps=&dir=0&mkt=en-us&src=SHELL&form=BMEMJS`;

    return (
         <div className="analytics-map">
            <iframe src={isMobile ? mobileUrl : desktopUrl} className={`${isMobile ? "analytics-map-mobile-container" : "analytics-map-desktop-container"}`}></iframe>
         </div>
    );
};

export default AnalyticsMap;