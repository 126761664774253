const config = {
  isDev: process.env.REACT_APP_DEV!,
  liqPayPrivateKey: process.env.REACT_APP_LIQPAY_PRIVATE_KEY!,
  liqPayPublicKey: process.env.REACT_APP_LIQPAY_PUBLIC_KEY!,
  liqPayPrivateKeyTest: process.env.REACT_APP_LIQPAY_PRIVATE_KEY_TEST!,
  liqPayPublicKeyTest: process.env.REACT_APP_LIQPAY_PUBLIC_KEY_TEST!,
  wfpMerchantLogin: process.env.REACT_APP_WFP_MERCHANT_LOGIN!,
  wfpSecretKey: process.env.REACT_APP_WFP_SECRET_KEY!,
  wfpSandboxMerchantLogin: process.env.REACT_APP_WFP_SANDBOX_MERCHANT_LOGIN!,
  wfpSandboxSecretKey: process.env.REACT_APP_WFP_SANDBOX_SECRET_KEY!,
  wfpCurrency: process.env.REACT_APP_WFP_CURRENCY!,
  wfpTransactionType: process.env.REACT_APP_WFP_TRANSACTION_TYPE!,
  wfpTransactionSecureType: process.env.REACT_APP_WFP_TRANSACTION_SECURE_TYPE!,
  wfpAuthType: process.env.REACT_APP_WFP_AUTH_TYPE!,
  wfpPayTypes: process.env.REACT_APP_WFP_PAY_TYPES!,
  wfpLang: process.env.REACT_APP_WFP_LANG!,
  wfpApiVersion: process.env.REACT_APP_WFP_API_VERSION!,
  wfpTransactionStatus: process.env.REACT_APP_WFP_TRANSACTION_STATUS!,
  wfpReason: process.env.REACT_APP_WFP_REASON!,
  wfpReasonCode: process.env.REACT_APP_WFP_REASON_CODE!,
  wfpAcceptStatus: process.env.REACT_APP_WFP_ACCEPT_STATUS!,
  companyName: process.env.REACT_APP_COMPANY_NAME!,
  companyEmail: process.env.REACT_APP_COMPANY_EMAIL!,
  companyRegion: process.env.REACT_APP_COMPANY_REGION!,
  companyDistrict: process.env.REACT_APP_COMPANY_DISTRICT!,
  companyLocation: process.env.REACT_APP_COMPANY_LOCATION!,
  companyStreet: process.env.REACT_APP_COMPANY_STREET!,
  companyHouse: process.env.REACT_APP_COMPANY_HOUSE!,
  domainName: process.env.REACT_APP_DOMAIN_NAME!,
  euaBaseUrl: process.env.REACT_APP_EUA_BASE_URL!,
  pessWebsiteIP: process.env.REACT_APP_WEBSITE_IP!,
  pessWebsite: process.env.REACT_APP_PESS_WEBSITE!,
  pessLocal: process.env.REACT_APP_PESS_LOCAL!,
  insuranceLocalUrl: process.env.REACT_APP_INSURANCE_LOCAL_URL!,
  insuranceBaseUrl: process.env.REACT_APP_INSURANCE_BASE_URL!,
  pessTitle: process.env.REACT_APP_PESS_TITLE!,
  pessDescription: process.env.REACT_APP_PESS_DESCRIPTION!,
  pessLogo: process.env.REACT_APP_PESS_LOGO_PATH!,
  maxPrivilege: process.env.REACT_APP_MAX_PRIVILEGE_VALUE!,
  disallowedPrivilegeCategories: process.env.REACT_APP_DISALLOWED_PRIVILEGE_CATEGORIES!,
  pessCompanyAddress: process.env.REACT_APP_COMPANY_ADDRESS!,
  pessAdminCredentials: process.env.REACT_APP_ADMIN_CREDENTIALS!,
  smtpServer: process.env.REACT_APP_SMTP_SERVER!,
  smtpPort: process.env.REACT_APP_SMTP_PORT!,
  infoEmailServer: process.env.REACT_APP_INFO_EMAIL_SERVER!,
  infoEmailPassword: process.env.REACT_APP_INFO_EMAIL_PASSWORD!,
  carID: process.env.REACT_APP_CAR_ID!,
  adminEmail: process.env.REACT_APP_ADMIN_EMAIL!,
  contentPerPage: process.env.REACT_APP_CONTENT_PER_PAGE!,
  passPhrase: process.env.REACT_APP_PASS_PHRASE!,
  guardianProductCode: process.env.REACT_APP_GUARDIAN_PRODUCT_CODE!,
  allowAccess: process.env.REACT_APP_ALLOW_ACCESS!,
  publicToken: process.env.REACT_APP_PUBLIC_TOKEN!,
  adminPhoneNumbers: process.env.REACT_APP_ADMIN_PHONE_NUMBERS!,
  customerPhoneNumLength: process.env.REACT_APP_CUSTOMER_PHONE_NUM_LENGTH!,
  customerINNCodeLength: process.env.REACT_APP_CUSTOMER_INN_CODE_LENGTH!,
  epolicyItemID: process.env.REACT_APP_EPOLICTY_ITEM_ID!,
  greencardItemID: process.env.REACT_APP_GREENCARD_ITEM_ID!,
  matchCaseSearchItemID: process.env.REACT_APP_MATCH_CASE_SEARCH_ITEM_ID!,
  displayCompaniesSearchID: process.env.REACT_APP_DISPLAY_COMPANIES_SEARCH_ID!,
  enableLiqPaySandboxID: process.env.REACT_APP_ENABLE_LIQPAY_SANDBOX_ID!,
  enableWfpSandboxID: process.env.REACT_APP_ENABLE_WFP_SANDBOX_ID!,
  disableLiqPayTabID: process.env.REACT_APP_DISABLE_LIQPAY_TAB_ID!,
  disableWfpTabID: process.env.REACT_APP_DISABLE_WFP_TAB_ID!,
  enableDevModeID: process.env.REACT_APP_ENABLE_DEV_MODE_ID!,
  enableWfpWidgetID: process.env.REACT_APP_ENABLE_WFP_WIDGET_ID!,
  enableTestDiiaID: process.env.REACT_APP_ENABLE_TEST_DIIA_ID!,
  displayDiiaID: process.env.REACT_APP_DISPLAY_DIIA_ID!,
  displayBankID: process.env.REACT_APP_DISPLAY_BANKID_ID!,
  displayDiiaBanner: process.env.REACT_APP_DISPLAY_DIIA_BANNER!,
  enableTestBankID: process.env.REACT_APP_ENABLE_TEST_BANKID_ID!,
  diiaTestHost: process.env.REACT_APP_DIIA_TEST_HOST!,
  diiaHost: process.env.REACT_APP_DIIA_HOST!,
  diiaTestAcquirerToken: process.env.REACT_APP_DIIA_TEST_ACQUIRER_TOKEN!,
  diiaTestAuthAcquirerToken: process.env.REACT_APP_DIIA_TEST_AUTH_ACQUIRER_TOKEN!,
  diiaAcquirerToken: process.env.REACT_APP_DIIA_ACQUIRER_TOKEN!,
  doubleBrandNames: process.env.REACT_APP_DOUBLE_BRAND_NAMES!,
  customerCardNumLength: process.env.REACT_APP_CARD_NUM_LENGTH!,
  greencardINGOId: process.env.REACT_APP_GREENCARD_INGO_ID!,
  mtsbuPolicyValidationLink: process.env.REACT_APP_MTSBU_POLICY_VALIDATION_LINK!,
  diiaAppStore: process.env.REACT_APP_DIIA_APP_STORE!,
  diiaGooglePlay: process.env.REACT_APP_DIIA_GOOGLE_PLAY!,
  diiaAllDocuments: process.env.REACT_APP_DIIA_ALL_DOCUMENTS!,
  diiaQRCodeSize: process.env.REACT_APP_DIIA_QR_CODE_SIZE!,
  diiaSharedDocuments: process.env.REACT_APP_DIIA_SHARED_DOCUMENTS!,
  otkVehicleKinds: process.env.REACT_APP_OTK_VEHICLE_KINDS!,
  otkVehicleType: process.env.REACT_APP_OTK_VEHICLE_TYPE!,
  policyNotificationCronSchedule: process.env.REACT_APP_POLICY_NOTIFICATION_CRON_SCHEDULE!,
  localIPv6Address: process.env.REACT_APP_LOCAL_IPv6_ADDRESS!,
  localIPv6NotationAddress: process.env.REACT_APP_LOCAL_IPv6_NOTATION_ADDRESS!,
  customerVerificationCodeLength: process.env.REACT_APP_CUSTOMER_VERIFICATION_CODE_LENGTH!,
  bankIDTestHost: process.env.REACT_APP_BANKID_TEST_HOST!,
  bankIDHost: process.env.REACT_APP_BANKID_HOST!,
  bankIDClientID: process.env.REACT_APP_BANKID_CLIENT_ID!,
  bankIDSecret: process.env.REACT_APP_BANKID_SECRET!
};

export default config;