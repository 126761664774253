import config from "../config";

const expired_date = () => {
  const date = new Date();
  date.setHours(date.getHours() + 10);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const h = date.getHours();
  const m = date.getMinutes();
  const s = date.getSeconds();

  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${h
    .toString()
    .padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
};

interface IgetPayloadLiqpay {
  amount: number;
  order_id: string;
  isTest: boolean;
  customerEmail: string;
  policyName: string;
  policyFranchise: number;
  type: string;
  token: string;
}

const getPayloadLiqpay = ({amount, order_id, isTest, customerEmail, policyName, policyFranchise, type, token}: IgetPayloadLiqpay) => {
  const insuranceType = type === "epolicy" ? "Автоцивілка" : "Зелена карта";

  return {
    action: "pay",
    version: 3,
    public_key: isTest ? config.liqPayPublicKeyTest : config.liqPayPublicKey,
    amount: amount,
    currency: "UAH",
    expired_date: expired_date(),
    language: "uk",
    order_id: order_id,
    email: customerEmail,
    description: `Оплата страхового полісу ${insuranceType} ${policyName} - Франшиза ${policyFranchise} грн`,
    info: token
  };
};

export {getPayloadLiqpay};
