import React from "react";
import diiaBannerLogo from "../../../assets/img/logos/diia.svg";

const DiiaBanner = () => {
    return (
        <div className="diia-banner-wrapper">
            <div className="diia-banner-container">
                <img src={diiaBannerLogo} alt="diiaLogoBanner" />
                Зручне оформлення полісу з автозаповненням даних через Дію
            </div>
        </div>
    );
};

export default DiiaBanner;