import {getDaysDate, getMonthsDate, getYearsDate} from "../utils/utilsDate";

export type VehicleTypeList = {
    id: number;
    label: string;
    code: string;
    subcategories: string[];
    value: string;
}

export type PeriodsTypeList = {
    id: number;
    label: string;
    code: string;
    data: number;
    value: (date: Date) => Date;
}

export type AreasTypeList = {
    id: number;
    label: string;
    value: string;
}

export const vehicleTypes: VehicleTypeList[] = [
    {
        id: 0,
        label: "Легкові автомобілі",
        code: "A",
        value: "CAR",
        subcategories: ["B"],
    },
    {
        id: 1,
        label: "Мотоцикли",
        code: "B",
        value: "MOTO",
        subcategories: ["A"]
    },
    {
        id: 2,
        label: "Вантажні автомобілі",
        code: "C",
        value: "FREIGHT",
        subcategories: ["C"]
    },
    {
        id: 3,
        label: "Автобуси",
        code: "E",
        value: "PASSENGER",
        subcategories: ["D"]
    },
    {
        id: 4,
        label: "Причіпи, напівпричіпи",
        code: "F",
        value: "TRAILER",
        subcategories: ["E", "F"]
    },
]

export const periods: PeriodsTypeList[] = [
    {
        id: 0,
        code: "FIFTEEN_DAYS",
        label: "15 днів",
        data: 15,
        value: (date: Date) => getDaysDate(date, 15)
    },
    {
        id: 1,
        code: "ONE_MONTH",
        label: "1 місяць",
        data: 1,
        value: (date: Date) => getMonthsDate(date, 1)
    },
    {
        id: 2,
        code: "TWO_MONTHS",
        label: "2 місяці",
        data: 2,
        value: (date: Date) => getMonthsDate(date, 2)
    },
    {
        id: 3,
        code: "THREE_MONTHS",
        label: "3 місяці",
        data: 3,
        value: (date: Date) => getMonthsDate(date, 3)
    },
    {
        id: 4,
        code: "FOUR_MONTHS",
        label: "4 місяці",
        data: 4,
        value: (date: Date) => getMonthsDate(date, 4)
    },
    {
        id: 5,
        code: "FIVE_MONTHS",
        label: "5 місяців",
        data: 5,
        value: (date: Date) => getMonthsDate(date, 5)
    },
    {
        id: 6,
        code: "SIX_MONTHS",
        label: "6 місяців",
        data: 6,
        value: (date: Date) => getMonthsDate(date, 6)
    },
    {
        id: 7,
        code: "SEVEN_MONTHS",
        label: "7 місяців",
        data: 7,
        value: (date: Date) => getMonthsDate(date, 7)
    },
    {
        id: 8,
        code: "EIGHT_MONTHS",
        label: "8 місяців",
        data: 8,
        value: (date: Date) => getMonthsDate(date, 8)
    },
    {
        id: 9,
        code: "NINE_MONTHS",
        label: "9 місяців",
        data: 9,
        value: (date: Date) => getMonthsDate(date, 9)
    },
    {
        id: 10,
        code: "TEN_MONTHS",
        label: "10 місяців",
        data: 10,
        value: (date: Date) => getMonthsDate(date, 10)
    },
    {
        id: 11,
        code: "ELEVEN_MONTHS",
        label: "11 місяців",
        data: 11,
        value: (date: Date) => getMonthsDate(date, 11)
    },
    {
        id: 12,
        code: "YEAR",
        label: "рік",
        data: 12,
        value: (date: Date) => getYearsDate(date, 1)
    }
]

export const areas: AreasTypeList[] = [
    {
        id: 0,
        label: "Молдова Азербайджан",
        value: "BMR"
    },
    {
        id: 1,
        label: "Європа",
        value: "EUROPE"
    }
]
