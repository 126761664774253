import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {checkAuthToken, navToAdminPanel, navToVehiclesPage} from "../../../utils/utilsGeneral";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {getEagentToken} from "../../../redux-data/misc/miscReducer";
import _ from "lodash";
import {InsuranceLocationState} from "../../../typings/InsuranceLocationState";

const EditedVehiclePage = (props) => {
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const eAgentToken = useSelector(getEagentToken);
    const dashboardNavigate = useNavigate();
    const {id, externalID, stateNumber, vehicleID} = useParams<{
        id: string;
        externalID: string;
        stateNumber: string;
        vehicleID: string;
    }>();
    const vehicleLocation = useLocation();
    const {isSuccess, successMsg, errorMsg} = vehicleLocation.state as InsuranceLocationState;
    const [authInProgress, setAuthInProgress] = useState(false);
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken(eAgentToken) && !_.isEmpty(vehicleLocation.state)) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardNavigate("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardNavigate("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        if (isSuccess) {
            setSuccessNotificationHidden(false);
            setErrorNotificationHidden(true);
        } else {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(false);
        }
    }, []);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification, hideSuccessNotification]);

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
    };
    
    const backToViewVehicle = () => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/view-vehicle/${stateNumber!.toLowerCase()}/${vehicleID}`);
    };

    const backToEditVehicle = () => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/edit-vehicle/${stateNumber!.toLowerCase()}/${vehicleID}`);
    };
    
    return (
        <div className="container">
            <div className="section-white">
            {authInProgress ?
                <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                :
                <>
                <div className="back-icon">
                    <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                </div>
                <div className="dashboard-container">
                    {!hideSuccessNotification && successMsg && <div className="admin-success-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{successMsg}</div>}
                    {!hideErrorNotification && errorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{errorMsg}</div>}
                    <div>
                        <button type="button" className="admin-btn" onClick={backToViewVehicle}><i className="fa-regular fa-circle-info notification-icon"></i>Переглянути</button>
                        <button type="button" className="admin-btn" onClick={backToEditVehicle}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="button" className="admin-btn" onClick={() => navToVehiclesPage(dashboardNavigate, id, externalID)}>Транспортні засоби</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                    </div>
                </div>
                </>
            }
            </div>
        </div>
    );
};

export default EditedVehiclePage;