import React, {useEffect, useState} from "react";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {useSelector} from "react-redux";
import {useParams, useNavigate, Link} from "react-router-dom";
import { 
    getInsuranceType, 
    getPrivilegeType, 
    refreshPage, 
    checkEditAction, 
    checkValue, 
    navToAdminPanel, 
    navToCustomersPage,
    navToCustomersEpolicyPage,
    navToCustomersGreencardPage,
    validateINNCode,
    convertINNToBirthDate,
    checkAuthToken,
    checkRequestStatus,
    validatePhoneNumber
} from "../../../utils/utilsGeneral";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import CustomField from "../../../components/MainPage/CustomField";
import useForm from "react-hook-form";
import {patternEmail} from "../../../utils/getInstanceError";
import CustomSelect from "../../../components/common/CustomSelect";
import {insuranceTypes, websiteDocuments, privilegeTypes} from "../../../mock-data/insuranceTypes";
import {getMaxDate, validateDBDateTime, formatDBDateTime, getCurrentDateTime, validateDBDate} from "../../../utils/utilsDate";
import PassportPartForm from "../../../components/MainPage/PassportPartForm";
import IDCardPartForm from "../../../components/MainPage/IDCardPartForm";
import DriverLicensePartForm from "../../../components/MainPage/DriverLicensePartForm";
import PensionCertificateForm from "../../../components/MainPage/PrivilegeForms/PensionCertificate";
import EPensionCertificateForm from "../../../components/MainPage/PrivilegeForms/EPensionCertificate";
import DisabilityCertificateForm from "../../../components/MainPage/PrivilegeForms/DisabilityCertificate";
import VeteranCertificateForm from "../../../components/MainPage/PrivilegeForms/VeteranCertificate";
import ChernobylCertificateForm from "../../../components/MainPage/PrivilegeForms/ChernobylCertificate";
import {ICustomer} from "../../../redux-data/user/userType";
import {IVehicle} from "../../../redux-data/vehicle/vehicleTypes";
import SwitchComponent from "react-switch";
import webConfig from "../../../config";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import ExternalPassportPartForm from "../../../components/MainPage/ExternalPassportPartForm";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {getEagentToken} from "../../../redux-data/misc/miscReducer";

const ManageCustomerPage = (props) => {
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const eAgentToken = useSelector(getEagentToken);
    const dashboardNavigate = useNavigate();
    const {id, externalID, insuranceType, phoneNumber, customerID, action} = useParams<{
        id: string;
        externalID: string;
        insuranceType: string;
        phoneNumber: string; 
        customerID: string; 
        action: string
    }>();
    const [authInProgress, setAuthInProgress] = useState(false);
    const [editCustomerData, setCustomerDataEdited] = useState<ICustomer>({
        phoneNumber: "",
        email: "",
        firstName: "",
        middleName: "",
        lastName: "",
        birthDate: "",
        innCode: "",
        dontHaveInnCode: false,
        isPrivilege: false,
        documentType: "",
        documentSeries: "",
        documentNumber: "",
        documentIssuedDate: null,
        documentExpirationDate: null,
        documentIssuedBy: "",
        addressSettlementName: "",
        addressStreet: "",
        addressHouseNumber: "",
        addressApartmentNumber: "",
        vehicleStateNumber: "",
        insuranceType: "",
        lastModified: null,
        authCode: null
    });
    const [customerErrorMsg, setCustomerErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);
    const [vehicleData, setVehicleData] = useState<IVehicle>({
        id: 0,
        engineVolume: 0,
        year: 0,
        vin: "",
        category: "",
        kind: "",
        brand: "",
        model: "",
        modelText: "",
        stateNumber: "",
        dontHaveVIN: false,
        lastModified: "",
        autoColor: "",
        isForeignReg: false,
        registrationPlace: {
            id: 0,
            placeCode: 0,
            zone: 0, 
            nameFull: "", 
            country: "",
            name: "",
            nameRus: "", 
            nameFullRus: "", 
            cdbMtibu: false,
            cdbMtibuCode: 0,
            lastModified: ""
        }
    });
    const [currentInsuranceType, setCurrentInsuranceType] = useState(getInsuranceType(insuranceType!));
    const [currentCustomerDocument, setCurrentCustomerDocument] = useState("");
    const [lastModified, setLastModified] = useState("");
    const [isVehicleFound, setVehicleFound] = useState(false);
    const [isEditMode, setEditMode] = useState(checkEditAction(action!));
    const [customerBirthDate, setCustomerBirthDate] = useState("");
    const [invalidInnMsg, setInvalidInnMsg] = useState("");
    const [isINNInputDisabled, setINNInputDisabled] = useState(false);
    const [customerInnCode, setCustomerInnCode] = useState("");
    
    const {register, errors, clearError} = useForm<any>({
        reValidateMode: "onChange",
        mode: "onChange"
    });

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken(eAgentToken)) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardNavigate("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardNavigate("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);
    
    useEffect(() => {
        fetchCustomer();
    }, []);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification, hideSuccessNotification]);

    const fetchCustomer = async () => {
        try {
            const customerResponse = await dbAxiosInstance.get(`/customer/${encodeURIComponent(phoneNumber!)}/${encodeURIComponent(customerID!)}`);
            const customer = customerResponse.data[0];

            const vehicleResponse = await dbAxiosInstance.get(`/vehicle/${encodeURIComponent(customer.vehicle_state_number.toUpperCase())}`);
            const vehicle = vehicleResponse.data[0];

            const regPlaceResponse = await dbAxiosInstance.get(`/vehicle/reg-location/${encodeURIComponent(vehicle.reg_place_id)}/${encodeURIComponent(vehicle.reg_place_code)}`);
            const regPlace = regPlaceResponse.data[0];

            if (customer) {
                setCustomerDataEdited({phoneNumber: customer.phone_number, email: customer.email, firstName: customer.first_name, middleName: customer.middle_name, lastName: customer.last_name, 
                                       birthDate: validateDBDate(customer.birth_date), innCode: customer.inn_code, dontHaveInnCode: customer.dont_have_inn_code, isPrivilege: customer.is_privilege, 
                                       documentType: customer.document_type, documentSeries: customer.document_series, documentNumber: customer.document_number, documentIssuedDate: validateDBDate(customer.document_issued_date), 
                                       documentExpirationDate: validateDBDate(customer.document_expiration_date), documentIssuedBy: customer.document_issued_by, addressSettlementName: customer.address_settlement_name, addressStreet: customer.address_street, 
                                       addressHouseNumber: customer.address_house_number, addressApartmentNumber: customer.address_apartment_number, vehicleStateNumber: customer.vehicle_state_number, insuranceType: customer.insurance_type, 
                                       lastModified: validateDBDateTime(customer.last_modified), authCode: customer.auth_code});
                setCurrentCustomerDocument(customer.document_type);
                setLastModified(customer.last_modified);
                setCurrentInsuranceType(getInsuranceType(customer.insurance_type));
                setCustomerBirthDate(validateDBDate(customer.birth_date));
                setCustomerInnCode(customer.inn_code);
                setINNInputDisabled(customer.dont_have_inn_code);
                setCustomerErrorMsg("");
                setErrorNotificationHidden(true);
            } else {
                setCustomerDataEdited({phoneNumber: "", email: "", firstName: "", middleName: "", lastName: "", birthDate: "", innCode: "", dontHaveInnCode: false, isPrivilege: false, documentType: "", documentSeries: "", documentNumber: "", 
                                       documentIssuedDate: null, documentExpirationDate: null, documentIssuedBy: "", addressSettlementName: "", addressStreet: "", addressHouseNumber: "", addressApartmentNumber: "", vehicleStateNumber: "", 
                                       insuranceType: "", lastModified: null, authCode: null});
                setCurrentCustomerDocument("");
                setCustomerErrorMsg("Помилка БД! Не вдалося завантажити дані клієнта.");
                setErrorNotificationHidden(false);
            }

            if (vehicle) {
                setVehicleData((prev) => ({...prev, id: vehicle.id, engineVolume: vehicle.engine_volume, year: vehicle.year, vin: vehicle.vin, category: vehicle.category, kind: vehicle.kind, brand: vehicle.brand, model: vehicle.model, modelText: vehicle.model_text, 
                                           stateNumber: vehicle.state_number, dontHaveVIN: vehicle.dont_have_vin, lastModified: vehicle.dont_have_vin, autoColor: vehicle.auto_color, isForeignReg: vehicle.is_foreign}));
                setCustomerErrorMsg("");
                setErrorNotificationHidden(true);
                setVehicleFound(true);
            } else {
                setVehicleData((prev) => ({...prev, id: 0, engineVolume: 0, year: 0, vin: "", category: "", kind: "", brand: "", model: "", modelText: "", stateNumber: "", dontHaveVIN: false, lastModified: "", autoColor: "", isForeignReg: false}));
                setCustomerErrorMsg("Помилка БД! Не вдалося завантажити транспортний засіб.");
                setVehicleFound(false);
                setErrorNotificationHidden(false);
            }

            if (regPlace) {
                setVehicleData((prev) => ({...prev, registrationPlace: {
                    id: regPlace.id,
                    placeCode: regPlace.place_code,
                    zone: regPlace.zone, 
                    nameFull: regPlace.name_full, 
                    country: regPlace.country,
                    name: regPlace.name,
                    nameRus: regPlace.name_rus, 
                    nameFullRus: regPlace.name_full_rus, 
                    cdbMtibu: regPlace.cdb_mtibu,
                    cdbMtibuCode: regPlace.cdb_mtibu_code,
                    lastModified: regPlace.last_modified
                }}));
            } else {
                setVehicleData((prev) => ({...prev, registrationPlace: {
                    id: 0,
                    placeCode: 0,
                    zone: 0, 
                    nameFull: "", 
                    country: "",
                    name: "",
                    nameRus: "", 
                    nameFullRus: "", 
                    cdbMtibu: false,
                    cdbMtibuCode: 0,
                    lastModified: ""
                }}));
            }
        } catch (error: any) {
            setCustomerDataEdited({phoneNumber: "", email: "", firstName: "", middleName: "", lastName: "", birthDate: "", innCode: "", dontHaveInnCode: false, isPrivilege: false, documentType: "", documentSeries: "", documentNumber: "", 
                                   documentIssuedDate: null, documentExpirationDate: null, documentIssuedBy: "", addressSettlementName: "", addressStreet: "", addressHouseNumber: "", addressApartmentNumber: "", vehicleStateNumber: "", insuranceType: "", 
                                   lastModified: null, authCode: null});
            setCurrentCustomerDocument("");
            setVehicleData({id: 0, engineVolume: 0, year: 0, vin: "", category: "", kind: "", brand: "", model: "", modelText: "", stateNumber: "", dontHaveVIN: false, lastModified: "", autoColor: "", isForeignReg: false, registrationPlace: {
                id: 0,
                placeCode: 0,
                zone: 0, 
                nameFull: "", 
                country: "",
                name: "",
                nameRus: "", 
                nameFullRus: "", 
                cdbMtibu: false,
                cdbMtibuCode: 0,
                lastModified: ""
            }});
            setCustomerErrorMsg("Помилка БД! Не вдалося завантажити дані клієнта і ТЗ.");
            setVehicleFound(false);
            setErrorNotificationHidden(false);
        }
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
    };

    const handleInsuranceTypesChange = (type: any) => {
        if (insuranceType === type.value && editCustomerData.insuranceType === type.value) {
            setCurrentInsuranceType(type);
            setCustomerDataEdited((prev) => ({...prev, insuranceType: type.value}));
        }
    };

    const viewVehicleInfo = (event) => {
        if (isVehicleFound) {
            dashboardNavigate(`/admin/dashboard/${id}/${externalID}/view-vehicle/${vehicleData.stateNumber.toLowerCase()}/${vehicleData.id}`);
        } else {
            setErrorNotificationHidden(false);
            setCustomerErrorMsg("Помилка БД! Не знайдено транспортного засобу за таким державним номером.");
        }
    };

    const handleDocumentChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, documentType: checkValue(event.target.value), isPrivilege: false, lastModified: getCurrentDateTime()}));
    };

    const handlePrivilegeTypeChange = (type) => {
        setCustomerDataEdited((prev) => ({...prev, documentType: checkValue(type.value), isPrivilege: true, lastModified: getCurrentDateTime()}));
    };

    const handleBirthDateChange = (name, date) => {
        setCustomerDataEdited((prev) => ({...prev, birthDate: checkValue(date), lastModified: getCurrentDateTime()}));
        clearError(name);
    };

    const handleDocumentIssuedDateChange = (name, date) => {
        setCustomerDataEdited((prev) => ({...prev, documentIssuedDate: checkValue(date), lastModified: getCurrentDateTime()}));
        clearError(name);
    };

    const handleDocumentExpirationDateChange = (name, date) => {
        setCustomerDataEdited((prev) => ({...prev, documentExpirationDate: checkValue(date), lastModified: getCurrentDateTime()}));
        clearError(name);
    };

    const handleCustomerEdit = async (event) => {
        event.preventDefault();

        if (Object.keys(errors).length === 0 && editCustomerData.phoneNumber.length > 0 && editCustomerData.email.length > 0 && editCustomerData.firstName.length > 0 && editCustomerData.lastName.length > 0 && editCustomerData.birthDate && 
            ((insuranceType === "epolicy" && editCustomerData.middleName.length > 0 && editCustomerData.innCode.length > 0 && validateINNCode(editCustomerData.innCode)) || 
            (insuranceType === "greencard" && !isINNInputDisabled && editCustomerData.innCode.length > 0 && validateINNCode(editCustomerData.innCode)) || (insuranceType === "greencard" && isINNInputDisabled)) && 
            editCustomerData.documentType.length > 0 && editCustomerData.documentNumber.length > 0 && editCustomerData.addressSettlementName.length > 0 && editCustomerData.addressStreet.length > 0 && 
            editCustomerData.addressHouseNumber.toString().length > 0 && editCustomerData.addressApartmentNumber.toString().length > 0 && editCustomerData.vehicleStateNumber.length > 0 && editCustomerData.insuranceType.length > 0 && 
            editCustomerData.lastModified) {
            try {
                const res = await dbAxiosInstance.put(`/edit-customer/${encodeURIComponent(customerID!)}`, editCustomerData);
                const editStatus = res.status;
                dashboardNavigate(`/admin/dashboard/${id}/${externalID}/customer/${insuranceType}/${validatePhoneNumber(editCustomerData.phoneNumber)}/${customerID}/item/edited`, {
                    state: {
                        isSuccess: checkRequestStatus(editStatus),
                        status: editStatus,
                        successMsg: res.data,
                        errorMsg: ""
                    }
                });
                setCustomerErrorMsg("");
                setSuccessNotificationHidden(false);
                setErrorNotificationHidden(true);
            } catch (error: any) {
                const userDataError = error.toJSON();
                setCustomerErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
                setSuccessNotificationHidden(true);
                setErrorNotificationHidden(false);
            }
        } else {
            setCustomerErrorMsg("Будь ласка, заповніть усі поля, щоб оновити дані клієнта!");
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(false);
        }
    };

    const handlePhoneNumberChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, phoneNumber: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleEmailChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, email: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleLastNameChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, lastName: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleNameChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, firstName: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleMiddleNameChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, middleName: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleInnCodeChange = (event) => {
        const innCode = event.target.value;
    
        if (innCode.length === Number(webConfig.customerINNCodeLength)) {
            if (validateINNCode(innCode)) {
                const {year, month, day} = convertINNToBirthDate(innCode);
                setCustomerBirthDate(`${year}-${month}-${day}`);
                setInvalidInnMsg("");
                setCustomerDataEdited((prev) => ({...prev, innCode: checkValue(innCode), dontHaveInnCode: false, lastModified: getCurrentDateTime()}));
            } else {
                setInvalidInnMsg("Невірний ідентифікаційний номер");
            }
        } else {
            setInvalidInnMsg("");
        }
    };

    const handleSettlementNameChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, addressSettlementName: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleAddressStreetChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, addressStreet: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleAddressHouseNumberChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, addressHouseNumber: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleAddressApartmentNumberChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, addressApartmentNumber: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleVehicleStateNumberChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, vehicleStateNumber: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const deleteCustomer = async (id) => {
        try {
            await dbAxiosInstance.delete(`/customer/${encodeURIComponent(id)}`);
            dashboardNavigate(`/admin/dashboard/${id}/${externalID}/customers/${insuranceType}`);
        } catch (error: any) {
            const userDataError = error.toJSON();
            setCustomerErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setCustomerDataEdited({phoneNumber: "", email: "", firstName: "", middleName: "", lastName: "", birthDate: "", innCode: "", dontHaveInnCode: false, isPrivilege: false, documentType: "", documentSeries: "", documentNumber: "", 
                                   documentIssuedDate: null, documentExpirationDate: null, documentIssuedBy: "", addressSettlementName: "", addressStreet: "", addressHouseNumber: "", addressApartmentNumber: "", vehicleStateNumber: "", insuranceType: "", 
                                   lastModified: null, authCode: null});
        }
    };

    const handleDocumentSeriesChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, documentSeries: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleDocumentNumberChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, documentNumber: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleDocumentIssuedByChange = (event) => {
        setCustomerDataEdited((prev) => ({...prev, documentIssuedBy: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleEditModeChange = (mode) => {
        setEditMode(mode);
    };

    const handleInnStateChange = (isInnDisabled) => {
        setINNInputDisabled(isInnDisabled);
        setCustomerDataEdited((prev) => ({...prev, innCode: isInnDisabled ? "" : customerInnCode, dontHaveInnCode: isInnDisabled, lastModified: getCurrentDateTime()}));
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                  <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo;&nbsp;
                            <CustomTooltip msg="Перейти до Клієнтів"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/customers`}}>Клієнти</Link></CustomTooltip> &laquo;&nbsp; 
                            <CustomTooltip msg={`Перейти до ${currentInsuranceType.label}`}><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/customers/${currentInsuranceType.value}`}}>{currentInsuranceType.label}</Link></CustomTooltip> &laquo; {phoneNumber}</h1>
                        <div>
                             {editCustomerData ?
                                <>
                                    <div className="row">
                                        <div className="col-md-10">
                                            <div className="switch-wrapper">
                                                <label className="switch-container">
                                                    <SwitchComponent onChange={handleEditModeChange} checked={isEditMode} onColor="#1BA876" />
                                                    <div className="switch-text">Дозволити редагування</div>
                                                </label>
                                            </div>
                                            <p className="item-title">Додано/оновлено: <span className="font-bold">{formatDBDateTime(lastModified)}</span></p>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Тип страхування</label>
                                                <CustomSelect
                                                    options={insuranceTypes}
                                                    handleChange={handleInsuranceTypesChange}
                                                    value={currentInsuranceType}
                                                    isCreatable={false}
                                                    isDisabled={!isEditMode}
                                                    placeholder="Тип страхування"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                            type="tel"
                                            register={register({
                                                required: "Це поле обов'язкове",
                                                pattern: {
                                                    value: /[+]\d{12}/,
                                                    message: "Введіть вірний телефон",
                                                },
                                                maxLength: {
                                                    value: 13,
                                                    message: "Введіть вірний телефон",
                                                }
                                            })}
                                            errors={errors}
                                            name="phone_number"
                                            label="Номер телефона"
                                            defaultValue={editCustomerData.phoneNumber}
                                            readOnly={!isEditMode}
                                            onChange={handlePhoneNumberChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                            type="email"
                                            register={register({
                                                required: "Це поле обов'язкове",
                                                pattern: {
                                                    value: patternEmail,
                                                    message: "Введіть вірний email"
                                                }
                                            })}
                                            errors={errors}
                                            name="email"
                                            label="Email"
                                            defaultValue={editCustomerData.email}
                                            readOnly={!isEditMode}
                                            onChange={handleEmailChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                            register={register({
                                                required: "Це поле обов'язкове",
                                                pattern: {
                                                    value: insuranceType === "epolicy" ? /^[а-яa-z-ієї'\s]+$/i : /^[a-z\s]+$/i,
                                                    message: insuranceType === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                                }
                                            })}
                                            errors={errors}
                                            name="surname"
                                            label="Прізвище"
                                            defaultValue={editCustomerData.lastName}
                                            readOnly={!isEditMode}
                                            onChange={handleLastNameChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                            register={register({
                                                required: "Це поле обов'язкове",
                                                pattern: {
                                                    value: insuranceType === "epolicy" ? /^[а-яa-z-ієї'\s]+$/i : /^[a-z\s]+$/i,
                                                    message: insuranceType === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                                }
                                            })}
                                            errors={errors}
                                            name="name"
                                            label="Iм'я"
                                            defaultValue={editCustomerData.firstName}
                                            readOnly={!isEditMode}
                                            onChange={handleNameChange}
                                            />
                                        </div>
                                        {insuranceType === "epolicy" &&
                                            <div className="col-md-4">
                                                <CustomField
                                                register={register({
                                                    required: "Це поле обов'язкове",
                                                    pattern: {
                                                        value: insuranceType === "epolicy" ? /^[а-яa-z-ієї'\s]+$/i : /^[a-z\s]+$/i,
                                                        message: insuranceType === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                                    }
                                                })}
                                                errors={errors}
                                                name="middle_name"
                                                label="По батькові"
                                                defaultValue={editCustomerData.middleName}
                                                readOnly={!isEditMode}
                                                onChange={handleMiddleNameChange}
                                                />
                                            </div>
                                        }
                                        <div className="col-md-4">
                                            {((insuranceType === "epolicy") || (insuranceType === "greencard" && !isINNInputDisabled)) &&
                                                <CustomField
                                                register={register({
                                                    required: "Це поле обов'язкове",
                                                    pattern: {
                                                        value: /\d{10}/,
                                                        message: "Ідентифікаційний номер містить 10 чисел"
                                                    },
                                                    maxLength: {
                                                        value: 10,
                                                        message: "Ідентифікаційний номер містить 10 чисел"
                                                    }
                                                })}
                                                errors={errors}
                                                name="INN"
                                                label="Ідентифікаційний номер"
                                                defaultValue={customerInnCode}
                                                placeholder="1234567890"
                                                mask="9999999999"
                                                readOnly={!isEditMode}
                                                onChange={handleInnCodeChange}
                                                />
                                            }
                                            {currentInsuranceType.value === "greencard" &&
                                                <div className="switch-wrapper">
                                                    <label className="switch-container">
                                                        <SwitchComponent onChange={handleInnStateChange} checked={isINNInputDisabled} disabled={!isEditMode} onColor="#1BA876" />
                                                        <div className="switch-text">У мене немає ІПН</div>
                                                    </label>
                                                </div>
                                            }
                                            {invalidInnMsg !== "" && <div className="item-error">{invalidInnMsg}</div>}
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                            register={register({
                                                required: "Це поле обов'язкове"
                                            })}
                                            errors={errors}
                                            name="date_of_birth"
                                            label="Дата народження"
                                            type="date"
                                            placeholder="РРРР.ММ.ДД"
                                            max={getMaxDate()}
                                            autoComplete="off"
                                            dateReadOnly={!isEditMode}
                                            dateValue={customerBirthDate}
                                            onDateChange={handleBirthDateChange}
                                            />
                                        </div>
                                        {editCustomerData.isPrivilege ?
                                            <>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Тип пільги</label>
                                                        <CustomSelect
                                                            options={privilegeTypes}
                                                            handleChange={handlePrivilegeTypeChange}
                                                            value={getPrivilegeType(editCustomerData.documentType)}
                                                            isCreatable={false}
                                                            isDisabled={!isEditMode}
                                                            placeholder="Тип пільги"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    {privilegeTypes[0].value === editCustomerData.documentType && <PensionCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" isReadOnly={!isEditMode} 
                                                                                                                    documentSeriesValue={privilegeTypes[0].value === currentCustomerDocument ? editCustomerData.documentSeries : ""} documentNumberValue={privilegeTypes[0].value === currentCustomerDocument ? editCustomerData.documentNumber : ""} 
                                                                                                                    documentIssuedDateValue={privilegeTypes[0].value === currentCustomerDocument ? editCustomerData.documentIssuedDate : ""} documentIssuedByValue={privilegeTypes[0].value === currentCustomerDocument ? editCustomerData.documentIssuedBy : ""} 
                                                                                                                    onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                                    }
                                                    {privilegeTypes[1].value === editCustomerData.documentType && <EPensionCertificateForm errors={errors} register={register} numberClass="col-md-8" expirationDateClass="col-md-8" isReadOnly={!isEditMode} 
                                                                                                                    documentNumberValue={privilegeTypes[1].value === currentCustomerDocument ? editCustomerData.documentNumber : ""} 
                                                                                                                    documentExpirationDateValue={privilegeTypes[1].value === currentCustomerDocument ? editCustomerData.documentExpirationDate : ""} 
                                                                                                                    onDocumentNumberChange={handleDocumentNumberChange} onDocumentExpirationDateChange={handleDocumentExpirationDateChange} />
                                                    }
                                                    {privilegeTypes[2].value === editCustomerData.documentType && <DisabilityCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" isReadOnly={!isEditMode} 
                                                                                                                    documentSeriesValue={privilegeTypes[2].value === currentCustomerDocument ? editCustomerData.documentSeries : ""} documentNumberValue={privilegeTypes[2].value === currentCustomerDocument ? editCustomerData.documentNumber : ""} 
                                                                                                                    documentIssuedDateValue={privilegeTypes[2].value === currentCustomerDocument ? editCustomerData.documentIssuedDate : ""} documentIssuedByValue={privilegeTypes[2].value === currentCustomerDocument ? editCustomerData.documentIssuedBy : ""}
                                                                                                                    onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                                    }
                                                    {privilegeTypes[3].value === editCustomerData.documentType && <VeteranCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" isReadOnly={!isEditMode} 
                                                                                                                    documentSeriesValue={privilegeTypes[3].value === currentCustomerDocument ? editCustomerData.documentSeries : ""} documentNumberValue={privilegeTypes[3].value === currentCustomerDocument ? editCustomerData.documentNumber : ""} 
                                                                                                                    documentIssuedDateValue={privilegeTypes[3].value === currentCustomerDocument ? editCustomerData.documentIssuedDate : ""} documentIssuedByValue={privilegeTypes[3].value === currentCustomerDocument ? editCustomerData.documentIssuedBy : ""}
                                                                                                                    onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                                    }
                                                    {privilegeTypes[4].value === editCustomerData.documentType && <ChernobylCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" isReadOnly={!isEditMode} 
                                                                                                                    documentSeriesValue={privilegeTypes[4].value === currentCustomerDocument ? editCustomerData.documentSeries : ""} documentNumberValue={privilegeTypes[4].value === currentCustomerDocument ? editCustomerData.documentNumber : ""} 
                                                                                                                    documentIssuedDateValue={privilegeTypes[4].value === currentCustomerDocument ? editCustomerData.documentIssuedDate : ""} documentIssuedByValue={privilegeTypes[4].value === currentCustomerDocument ? editCustomerData.documentIssuedBy : ""}
                                                                                                                    onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                                    }
                                                </div>
                                            </>
                                          :
                                            <>
                                                <div className="col-md-10">
                                                  <div className="customer-document-type-wrapper">
                                                    {websiteDocuments.map((document) => (
                                                        <div className="policy-document-type" key={document.value}>
                                                            <div className="form-group form-radio">
                                                                <input
                                                                    ref={register}
                                                                    onChange={handleDocumentChange}
                                                                    type="radio"
                                                                    id={document.value}
                                                                    value={document.value}
                                                                    checked={document.value === editCustomerData.documentType}
                                                                    hidden
                                                                    disabled={!isEditMode}
                                                                    name="document_type"
                                                                />
                                                                <label htmlFor={document.value}>{document.label}</label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                  </div>
                                                </div>
                                                <div className="col-md-8">
                                                    {websiteDocuments[0].value === editCustomerData.documentType && <PassportPartForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" isReadOnly={!isEditMode} 
                                                                                                                documentSeriesValue={websiteDocuments[0].value === currentCustomerDocument ? editCustomerData.documentSeries : ""} documentNumberValue={websiteDocuments[0].value === currentCustomerDocument ? editCustomerData.documentNumber : ""} 
                                                                                                                documentIssuedDateValue={websiteDocuments[0].value === currentCustomerDocument ? editCustomerData.documentIssuedDate : ""} documentIssuedByValue={websiteDocuments[0].value === currentCustomerDocument ? editCustomerData.documentIssuedBy : ""} 
                                                                                                                onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                                    }
                                                    {websiteDocuments[1].value === editCustomerData.documentType && <IDCardPartForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" expirationDateClass="col-md-8" issuedByClass="col-md-8" isReadOnly={!isEditMode} 
                                                                                                                documentSeriesValue={websiteDocuments[1].value === currentCustomerDocument ? editCustomerData.documentSeries : ""} documentNumberValue={websiteDocuments[1].value === currentCustomerDocument ? editCustomerData.documentNumber : ""} 
                                                                                                                documentIssuedByValue={websiteDocuments[1].value === currentCustomerDocument ? editCustomerData.documentIssuedBy : ""} documentIssuedDateValue={websiteDocuments[1].value === currentCustomerDocument ? editCustomerData.documentIssuedDate : ""} 
                                                                                                                documentExpirationDateValue={websiteDocuments[1].value === currentCustomerDocument ? editCustomerData.documentExpirationDate : ""} onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} 
                                                                                                                onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} onDocumentExpirationDateChange={handleDocumentExpirationDateChange} />
                                                    }
                                                    {websiteDocuments[2].value === editCustomerData.documentType && <DriverLicensePartForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" isReadOnly={!isEditMode} 
                                                                                                                documentSeriesValue={websiteDocuments[2].value === currentCustomerDocument ? editCustomerData.documentSeries : ""} documentNumberValue={websiteDocuments[2].value === currentCustomerDocument ? editCustomerData.documentNumber : ""} 
                                                                                                                documentIssuedDateValue={websiteDocuments[2].value === currentCustomerDocument ? editCustomerData.documentIssuedDate : ""} documentIssuedByValue={websiteDocuments[2].value === currentCustomerDocument ? editCustomerData.documentIssuedBy : ""} 
                                                                                                                onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                                    }
                                                    {websiteDocuments[3].value === editCustomerData.documentType && <ExternalPassportPartForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" 
                                                                                                                documentSeriesValue={websiteDocuments[3].value === currentCustomerDocument ? editCustomerData.documentSeries : ""} 
                                                                                                                documentNumberValue={websiteDocuments[3].value === currentCustomerDocument ? editCustomerData.documentNumber : ""} 
                                                                                                                documentIssuedDateValue={websiteDocuments[3].value === currentCustomerDocument ? editCustomerData.documentIssuedDate : ""} 
                                                                                                                documentIssuedByValue={websiteDocuments[3].value === currentCustomerDocument ? editCustomerData.documentIssuedBy : ""}  
                                                                                                                onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} 
                                                                                                                onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                                    }
                                                </div>
                                            </>
                                        }
                                        <div className="col-md-6">
                                            <CustomField
                                            register={register({
                                                required: "Це поле обов'язкове",
                                                pattern: {
                                                    value: insuranceType === "epolicy" ? /^[а-яa-z-ієї'\s]+$/i : /^[a-z\s]+$/i,
                                                    message: insuranceType === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                                }
                                            })}
                                            errors={errors}
                                            name="settlementName"
                                            label="Назва населеного пункта"
                                            defaultValue={editCustomerData.addressSettlementName}
                                            readOnly={!isEditMode}
                                            onChange={handleSettlementNameChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <CustomField
                                            register={register({
                                                required: "Це поле обов'язкове",
                                                pattern: {
                                                    value: insuranceType === "epolicy" ? /^[а-яa-z-ієї.'\s]+$/i : /^[a-z.\s]+$/i,
                                                    message: insuranceType === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                                }
                                            })}
                                            errors={errors}
                                            name="address_street"
                                            label="Вулиця"
                                            defaultValue={editCustomerData.addressStreet}
                                            readOnly={!isEditMode}
                                            onChange={handleAddressStreetChange}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <CustomField
                                            register={register({
                                                required: "Це поле обов'язкове",
                                                pattern: {
                                                    value: /\d/,
                                                    message: "Тільки цифри"
                                                }
                                            })}
                                            errors={errors}
                                            name="address_house_number"
                                            label="Номер будинку"
                                            placeholder="12"
                                            defaultValue={editCustomerData.addressHouseNumber}
                                            readOnly={!isEditMode}
                                            onChange={handleAddressHouseNumberChange}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <CustomField
                                            register={register({
                                                required: "Це поле обов'язкове",
                                                pattern: {
                                                    value: /\d/,
                                                    message: "Тільки цифри"
                                                }
                                            })}
                                            errors={errors}
                                            name="address_apartment_number"
                                            label="Номер квартири"
                                            placeholder="12"
                                            defaultValue={editCustomerData.addressApartmentNumber}
                                            readOnly={!isEditMode}
                                            onChange={handleAddressApartmentNumberChange}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <CustomField
                                            register={register({
                                                required: "Це поле обов'язкове",
                                                pattern: {
                                                    value: /[A-zА-я-І-і-Ї-ї0-9]/,
                                                    message: "Реєстраційний номер ТЗ не відповідає правилам перевірки"
                                                }
                                            })}
                                            errors={errors}
                                            name="vehicle_state_number"
                                            label="Державний номер ТЗ"
                                            placeholder="AA1234BB"
                                            defaultValue={editCustomerData.vehicleStateNumber}
                                            readOnly={!isEditMode}
                                            onChange={handleVehicleStateNumberChange}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Деталі ТЗ</label>
                                                <input type="button" className="view-vehicle-info-btn" onClick={viewVehicleInfo} value="Переглянути" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            {isEditMode && <button type="submit" className="edit-btn" onClick={handleCustomerEdit}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>}
                                            <button type="button" className="admin-btn" onClick={() => deleteCustomer(customerID)}><i className="fa fa-trash notification-icon"></i>Видалити</button>
                                            <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                                            <button type="button" className="admin-btn" onClick={() => navToCustomersPage(dashboardNavigate, id, externalID)}>Клієнти</button>
                                            <button type="button" className="admin-btn" onClick={() => navToCustomersEpolicyPage(dashboardNavigate, id, externalID)}>Клієнти (Автоцивілка)</button>
                                            <button type="button" className="admin-btn" onClick={() => navToCustomersGreencardPage(dashboardNavigate, id, externalID)}>Клієнти (Зелена карта)</button>
                                            <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                                        </div>
                                        {!hideErrorNotification && customerErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{customerErrorMsg}</div>}
                                      </div>
                                </>
                             :
                                <div>
                                  {!hideErrorNotification && customerErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{customerErrorMsg}</div>}
                                </div>
                            }
                        </div>
                    </div>
                  </>
                }
                </div>
            </div>
        </div>
    );
};

export default ManageCustomerPage;