export interface IBankID {
    id?: number;
    pessDomain: string;
    insuranceType: string;
    stepID: string;
    isTestBankIDDomain: boolean;
    document?: BankIDData;
    isError: boolean;
    created: string;
}

export interface BankIDResult {
    id?: number;
    pess_domain: string;
    insurance_type: string;
    step_id: string;
    is_test_bankid_domain: boolean;
    document: string;
    is_error: boolean;
    created: string;
}

export interface BankIDTokenData {
    access_token: string;
    token_type: string;
    refresh_token: string;
    expires_in: number;
    scope: string;
}

export interface BankIDDocumentError {
    stepID: string;
    isError: boolean;
}

export interface BankIDDocument extends BankIDDocumentError {
    document: string;
}

export interface BankIDData {
    state: string;
    customer: {
        type: string;
        lastName: string;
        firstName: string;
        middleName: string;
        phone: string;
        email: string;
        birthDay: string;
        inn: string;
        edrpou: string;
        resident: string;
        dateModification: string;
        signature: string;
        addresses: {
            type: string;
            country: string;
            state: string;
            city: string;
            street: string;
            houseNo: string;
            flatNo: string;
            dateModification: string;
        }[];
        documents: {
            type: string;
            series: string;
            number: string;
            issue: string;
            dateIssue: string;
            dateExpiration: string;
            issueCountryIso2: string;
            dateModification: string;
        }[];
    }
}

export interface BankIDCustomer {
    lastName: string;
    firstName: string;
    middleName: string;
    phone: string;
    email: string;
    birthDay: string;
    inn: string;
    dateModification: string;
    signature: string;
    document: {
        type: string;
        series: string;
        number: string;
        issue: string;
        dateIssue: string;
        dateExpiration: string;
        issueCountryIso2: string;
        dateModification: string;
    } | undefined,
    address: {
        type: string;
        country: string;
        state: string;
        city: string;
        street: string;
        houseNo: string;
        flatNo: string;
        dateModification: string;
    } | undefined;
}

export interface BankIDAnalytics {
    month: string;
    page_visits: number; 
    success_requests: number; 
    failed_requests: number;
}

export enum BankIDAnalyticsEvent {
    PageVisits = "page_visits",
    SuccessRequests = "success_requests",
    FailedRequests = "failed_requests"
}