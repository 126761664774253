import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams, Link} from "react-router-dom";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {checkAuthToken, countRecords, filterRecords, getInsuranceTypeName, isMatchCaseSearch, validatePhoneNumber} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {insuranceTypes} from "../../../mock-data/insuranceTypes";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import DataFilter from "../../../utils/DataFilter";
import {formatDBDate} from "../../../utils/utilsDate";
import FilterPagination from "../../../utils/FilterPagination";
import webConfig from "../../../config";
import {validateOfferStatus, StatusOffer} from "../../../redux-data/offer/offerReducer";
import PolicyExpirationDate from "../../../components/Forms/common/PolicyExpirationDate";
import {getEagentToken} from "../../../redux-data/misc/miscReducer";

const GreencardExpiration = (props) => {
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const eAgentToken = useSelector(getEagentToken);
    const dashboardNavigate = useNavigate();
    const {id, externalID} = useParams<{
        id: string; 
        externalID: string;
    }>();
    const [authInProgress, setAuthInProgress] = useState(false);
    const [epoliciesCount, setEpoliciesCount] = useState(0);
    const [epoliciesErrorMsg, setEpoliciesErrorMsg] = useState("");
    const [epolicies, setEpolicies] = useState<any[]>([]);
    const [filteredItems, setFilteredItems] = useState<any[]>([]);
    const [filterCompleted, setFilterCompleted] = useState(false);
    const [processingFilter, setProcessingFilter] = useState(false);
    const [isMatchCaseFilter, setMatchCaseFilter] = useState(false);
    const [recordsOnPage, setRecordsOnPage] = useState<any[]>([]);
    const greencardType = insuranceTypes[1].value;
    
    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken(eAgentToken)) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardNavigate("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardNavigate("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        const fetchGreencards = async () => {
            try {
                const res = await dbAxiosInstance.get(`/policies/${encodeURIComponent(greencardType)}/${encodeURIComponent(validateOfferStatus(StatusOffer.EMITTED))}`);
                const dbReportsData = res.data;

                if (dbReportsData) {
                    if (dbReportsData.length > 0) {
                        setEpolicies(dbReportsData);
                        setFilteredItems(dbReportsData);
                        setEpoliciesErrorMsg("");
                    } else {
                        setEpolicies([]);
                        setFilteredItems([]);
                        setEpoliciesErrorMsg("Немає доданих даних полісів Зеленої карти. Застрахуйте ТЗ, щоб додати поліс.");
                    }
                } else {
                    setEpolicies([]);
                    setFilteredItems([]);
                    setEpoliciesErrorMsg("Помилка БД! Не вдалося завантажити дані полісів Зеленої карти.");
                }
            } catch (error: any) {
                setEpolicies([]);
                setFilteredItems([]);
                setEpoliciesErrorMsg("Помилка БД! Не вдалося завантажити дані полісів Зеленої карти.");
            }
        };

        const countGreencards = async () => {
            try {
                const res = await dbAxiosInstance.get(`/count-policies/${encodeURIComponent(greencardType)}/${encodeURIComponent(validateOfferStatus(StatusOffer.EMITTED))}`);
                const dbCountCustomersData = res.data;

                if (dbCountCustomersData) {
                    if (dbCountCustomersData.length > 0) {
                        setEpoliciesCount(dbCountCustomersData[0].counter);
                    } else {
                        setEpoliciesCount(0);
                    }
                } else {
                    setEpoliciesCount(0);
                }
            } catch (error: any) {
                setEpoliciesCount(0);
            }
        };

        const checkSearchType = async () => {
            const isOn = await isMatchCaseSearch();
            setMatchCaseFilter(isOn);
        };

        fetchGreencards();
        countGreencards();
        checkSearchType();
    }, []);

    const handleRecordsOnPage = (records) => {
        setRecordsOnPage(records);
    };

    const handleItemDelete = async (id) => {
        try {
            await dbAxiosInstance.delete(`/report/${encodeURIComponent(id)}`);
            setRecordsOnPage(filterRecords(recordsOnPage, id));
            const allRecords = countRecords(epoliciesCount);
            setEpoliciesCount(allRecords);

            if (allRecords === 0) {
                setEpoliciesErrorMsg("Немає доданих даних полісів Зеленої карти. Застрахуйте ТЗ, щоб додати поліс.");
            }
        } catch (error: any) {
            const userDataError = error.toJSON();
            setEpoliciesErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setEpolicies([]);
        }
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo;&nbsp;
                            <CustomTooltip msg="Перейти до терміну дії поліса"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/policy-expiration`}}>Термін дії поліса</Link></CustomTooltip> &laquo; Зелена карта</h1>
                        <div className="itemsCounter">
                            <p>Полісів ({getInsuranceTypeName(greencardType)}): <span className="font-bold">{epoliciesCount}</span></p>
                        </div>
                        <DataFilter items={epolicies} matchCase={isMatchCaseFilter} placeholderText="Введіть дані поліса для пошуку..." onFilterCompleted={(state) => setFilterCompleted(state)} onProcessingFilter={(state) => setProcessingFilter(state)} onFilteredItems={(filteredItems) => setFilteredItems(filteredItems)} itemNotFoundText="За Вашим запитом такого поліса не знайдено!" />
                        <div className="dashboard">
                            {epolicies.length > 0 && epoliciesCount > 0 ?
                                <div className="reports-wrapper">
                                    <table className="reports">
                                    <thead>
                                        <tr>
                                            <th>ID замовлення</th>
                                            <th>Початок дії</th>
                                            <th>Завершення дії</th>
                                            <th>Період</th>
                                            <th>Залишилось</th>
                                            <th>Управління</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {recordsOnPage.map((greencard, index) =>
                                            <tr key={index}>
                                                <td><CustomTooltip msg="Натисність, щоб переглянути деталі"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/report/${greencardType}/${validatePhoneNumber(greencard.customer_phone_number)}/${greencard.vehicle_state_number.toLowerCase()}/${greencard.id}/view`}}>{`#${greencard.offer_id}`}</Link></CustomTooltip></td>
                                                <td><CustomTooltip msg="Натисність, щоб переглянути деталі"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/report/${greencardType}/${validatePhoneNumber(greencard.customer_phone_number)}/${greencard.vehicle_state_number.toLowerCase()}/${greencard.id}/view`}}>{formatDBDate(greencard.from_date)}</Link></CustomTooltip></td>
                                                <td><CustomTooltip msg="Натисність, щоб переглянути деталі"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/report/${greencardType}/${validatePhoneNumber(greencard.customer_phone_number)}/${greencard.vehicle_state_number.toLowerCase()}/${greencard.id}/view`}}>{formatDBDate(greencard.expiration_date)}</Link></CustomTooltip></td>
                                                <td><CustomTooltip msg="Натисність, щоб переглянути деталі"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/report/${greencardType}/${validatePhoneNumber(greencard.customer_phone_number)}/${greencard.vehicle_state_number.toLowerCase()}/${greencard.id}/view`}}>{greencard.policy_period}</Link></CustomTooltip></td>
                                                <td><CustomTooltip msg="Натисність, щоб переглянути деталі"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/report/${greencardType}/${validatePhoneNumber(greencard.customer_phone_number)}/${greencard.vehicle_state_number.toLowerCase()}/${greencard.id}/view`}}><PolicyExpirationDate date={greencard.expiration_date} isStyle={false} /></Link></CustomTooltip></td>
                                                <td>
                                                   <div className="manage-item-container">
                                                        <CustomTooltip msg="Відправити лист">
                                                            <Link className="send-item" to={{pathname: `/admin/dashboard/${id}/${externalID}/${greencardType}/newsletter/email/${validatePhoneNumber(greencard.customer_phone_number)}/${greencard.vehicle_state_number.toLowerCase()}`}}>
                                                                <i className="fa fa-envelope"></i>
                                                            </Link>
                                                        </CustomTooltip>
                                                        <CustomTooltip msg="Редагувати">
                                                            <Link className="edit-item" to={{pathname: `/admin/dashboard/${id}/${externalID}/report/${greencardType}/${validatePhoneNumber(greencard.customer_phone_number)}/${greencard.vehicle_state_number.toLowerCase()}/${greencard.id}/edit`}}>
                                                                <i className="fa fa-pencil-alt"></i>
                                                            </Link>
                                                        </CustomTooltip>
                                                        <CustomTooltip msg="Переглянути деталі">
                                                            <Link className="view-item" to={{pathname: `/admin/dashboard/${id}/${externalID}/report/${greencardType}/${validatePhoneNumber(greencard.customer_phone_number)}/${greencard.vehicle_state_number.toLowerCase()}/${greencard.id}/view`}}>
                                                                <i className="fa fa-circle-info"></i>
                                                            </Link>
                                                        </CustomTooltip>
                                                        <CustomTooltip msg="Видалити"><a className="delete-item" onClick={() => handleItemDelete(greencard.id)}><i className="fa fa-trash"></i></a></CustomTooltip>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    </table>
                                    <FilterPagination data={filteredItems} recordsPerPage={Number(webConfig.contentPerPage)} dataOnPage={handleRecordsOnPage} isDataLoaded={filterCompleted} setDataLoaded={(state) => setFilterCompleted(state)} isFilterProcessing={processingFilter} setFilterState={(state) => setProcessingFilter(state)} />
                                </div>
                              : <div>
                                    <div className="no-dashboard-item">{epoliciesErrorMsg}</div>
                                </div>
                            }
                        </div>
                    </div>
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default GreencardExpiration;