export const customStyles = (isError: boolean, isOptionStyle: boolean) => {
    return {
        container: (provide: any) => ({
            ...provide,
            backgroundColor: "#fff",
            borderRadius: 0,
            fontSize: "1.7rem",
            height: "4.5rem"
        }),
        control: (provide: any, state: any) => {
            const isSelect = state.getValue().length > 0;
            let color = "#B4C9C1";

            if (isError) {
                if (!isSelect) {
                    color = "red";
                }
            }

            return ({
                display: "flex",
                border: `0.2rem solid ${color}`,
                height: "4.5rem"
            });
        },
        valueContainer: (provide: any) => ({
            ...provide,
            flexWrap: "nowrap"
        }),
        menuList: (provide: any) => ({
            backgroundColor: "#fff",
            border: "0.2rem solid #B4C9C1",
            borderRadius: 0,
            fontSize: "1.7rem",
            marginTop: "-2px",
            maxHeight: "36rem",
            overflow: "auto"
        }),
        indicatorSeparator: () => ({
            display: "none"
        }),
        placeholder: (provide: any) => ({
            ...provide,
            color: "#B4C9C1"
        }),
        option: (provide: any, state: any) => ({
            ...provide,
            color: isOptionStyle ? state.data.value : provide.color,
            backgroundColor: isOptionStyle ? "#1ba876" : provide.backgroundColor,
            ...(isOptionStyle && {
                ":hover": {
                    backgroundColor: "#B4C9C1"
                }
            })
        }),
        menu: (provide: any) => ({
            ...provide,
            marginTop: 0,
            width: "calc(100%)",
            boxShadow: "none",
            zIndex: "9999"
        })
    }
};

export const customTheme = (theme: any) => {
    return {
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary25: "#1BA876",
            primary: "#B4C9C1"
        }
    }
};
