import ICustomField from "../../typings/ICustomField";
import React, {useEffect, useState} from "react";
import {getInstanceError} from "../../utils/getInstanceError";
import {YearPicker, MonthPicker, DayPicker} from "react-dropdown-date";
import {getCorrectDate, validateDBDate, formatDBDate, validateDate} from "../../utils/utilsDate";
import {useDetectClickOutside} from "react-detect-click-outside";

const formatDate = (date) => {
  let d = new Date(date),
    day = "" + d.getDate(),
    month = "" + (d.getMonth() + 1),
    year = d.getFullYear();

  if (day.length < 2) day = "0" + day;
  if (month.length < 2) month = "0" + month;

  return [year, month, day].join("-");
}

const CustomField = ({register, errors, name, label, disabled, type = "text", defaultValue = "", placeholder = "", styles = {}, setError, onDateChange, dateValue, dateReadOnly, ...rest}: ICustomField) => {
  const [touch, setTouch] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [touchYear, setTouchYear] = useState(false);
  const [touchMonth, setTouchMonth] = useState(false);
  const [touchDay, setTouchDay] = useState(false);
  const [selectDate, setSelectDate] = useState<any>(null);
  const [year, setYear] = useState<any>();
  const [month, setMonth] = useState<any>();
  const [day, setDay] = useState<any>();
  const minDate = new Date();
  const maxDate = new Date();
  minDate.setFullYear(maxDate.getFullYear() - 100);
  maxDate.setFullYear(maxDate.getFullYear() + 20);

  const {
    getMessageError,
    getClassError
  } = getInstanceError(errors);

  const handelBlur = () => {
    setTouch(true);
  };

  useEffect(() => {
    if (year && month && day) {
        const date = new Date(year, month, day);
        setSelectDate(date);
        setIsOpen(false);
        setTouch(true);
        onDateChange(name, validateDBDate(date));
    }
  }, [year, month, day]);

  useEffect(() => {
    if (dateValue) {
        const documentDate = validateDate(dateValue);
        setYear(documentDate.year());
        setMonth(documentDate.month());
        setDay(documentDate.date());
        setSelectDate(documentDate.toDate());
        setIsOpen(false);
    }
  }, [dateValue]);

  const dropdownRef = useDetectClickOutside({
    onTriggered: (event: Event) => {
      if (isOpen && event.target instanceof HTMLDivElement) {
          setIsOpen(false);
      }
    }
  });

  const mes = getMessageError(name);
  if (disabled) return null;
  return (
    <div className={getClassError(name, touch)}>
      {label && <label className="date-label">{selectDate ? `${label} - ${formatDBDate(selectDate)}` : dateValue ? `${label} - ${formatDBDate(dateValue)}` : label}</label>}
      {type !== "date" && (
        <input
          name={name}
          ref={register}
          type={type}
          placeholder={placeholder}
          onBlur={handelBlur}
          defaultValue={defaultValue}
          {...rest}
        />
      )}
      {type === "date" && (
        <>
          <input
            name={name}
            ref={register}
            value={selectDate ? getCorrectDate(selectDate) : ""}
            onClick={() => setIsOpen(true)}
            onBlur={handelBlur}
            placeholder={placeholder}
            readOnly={true}
          />
          {isOpen &&
            <div className="dateDropDown-container-modal" ref={dropdownRef}>
              <div className="dateDropDown-close" onClick={() => setIsOpen(false)}>x</div>
              <YearPicker
                defaultValue="Рік"
                start={+formatDate(minDate).split("-")[0]}
                end={+formatDate(maxDate).split("-")[0]}
                reverse
                required={true}
                disabled={dateReadOnly}
                value={year}
                onChange={(year) => {
                  setYear(year);
                  setTouchYear(true);
                }}
                id="year"
                name="year"
                classes={touchYear ? "dateDropDown-touch" : "dateDropDown"}
                optionClasses="dateDropDown-option"
              />
              <MonthPicker
                defaultValue="Місяць"
                numeric
                short
                caps
                endYearGiven
                year={year}
                required={true}
                disabled={dateReadOnly}
                value={month}
                onChange={(month) => {
                  setMonth(month);
                  setTouchMonth(true);
                }}
                id="month"
                name="month"
                classes={touchMonth ? "dateDropDown-touch" : "dateDropDown"}
                optionClasses="dateDropDown-option"
              />
              <DayPicker
                defaultValue="День"
                year={year}
                month={month}
                endYearGiven
                required={true}
                disabled={dateReadOnly}
                value={day}
                onChange={(day) => {
                  setDay(day);
                  setTouchDay(true);
                }}
                id="day"
                name="day"
                classes={touchDay ? "dateDropDown-touch" : "dateDropDown"}
                optionClasses="dateDropDown-option"
              />
            </div>
          }
        </>
      )}
      {mes && <p>{mes}</p>}
    </div>
  )
};

export default CustomField;
