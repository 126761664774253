import React, {useState} from "react";
import CustomTooltip from "../components/Forms/common/CustomTooltip";

const InsurancePolicyFilter = ({data, onFilteredItems}) => {
    const [search, setSearch] = useState("");
    const [updatedItems, setUpdatedItems] = useState<any[]>([]);

    const handleChangeSearch = (e: any) => {
        const searchData = e.target.value;
        setSearch(searchData);

        if (searchData) {
            const rgx = new RegExp(searchData, "i");
            const filterItems = data.filter((item) => rgx.test(item.name));
            onFilteredItems(filterItems);
            setUpdatedItems(filterItems);
        } else {
            onFilteredItems(data);
        }
    };

    const resetSearch = () => {
        if (search) {
            setSearch("");
            onFilteredItems(data);
        }
    };

    return (
        <div className="search-items-wrapper">
            <input className="search-company" type="text" placeholder="Пошук страхових" value={search} onChange={handleChangeSearch} />
            <CustomTooltip msg="Очистити"><i className="fa-solid fa-xmark clear-search-icon" onClick={resetSearch}></i></CustomTooltip>
            {search && <p className="search-items-found-count">Знайдено: <span className="font-bold">{updatedItems.length}</span></p>}
            {search && data.length > 0 && updatedItems.length === 0 && <div className="customer-error-notification">За Вашим запитом такої страхової не знайдено!</div>}
        </div>
    );
};

export default InsurancePolicyFilter;