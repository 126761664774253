import React, {useEffect, useState} from "react";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {useNavigate, Link, useParams} from "react-router-dom";
import webConfig from "../../../config";
import Pagination from "../../../utils/Pagination";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {useSelector} from "react-redux";
import {filterRecords, countRecords, checkAuthToken} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {getEagentToken} from "../../../redux-data/misc/miscReducer";

const SocialsPage = (props) => {
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const eAgentToken = useSelector(getEagentToken);
    const dashboardNavigate = useNavigate();
    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();
    const [socials, setSocials] = useState<any[]>([]);
    const [socialErrorMsg, setSocialErrorMsg] = useState("");
    const [socialsCount, setSocialsCount] = useState(0);
    const [recordsOnPage, setRecordsOnPage] = useState<any[]>([]);
    const [authInProgress, setAuthInProgress] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken(eAgentToken)) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardNavigate("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardNavigate("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        const fetchAllSocialItems = async () => {
            try {
                const res = await dbAxiosInstance.get("/social");
                const dbSocialData = res.data;

                if (dbSocialData) {
                    if (dbSocialData.length > 0) {
                        setSocials(dbSocialData);
                        setSocialErrorMsg("");
                    } else {
                        setSocials([]);
                        setSocialErrorMsg("Немає доданих соціальних мереж. Натисніть на кнопку, щоб додати соц. мережу.");
                    }
                } else {
                    setSocials([]);
                    setSocialErrorMsg("Помилка БД! Не вдалося завантажити соц. мережі.");
                }
            } catch (error: any) {
                setSocials([]);
                setSocialErrorMsg("Помилка БД! Не вдалося завантажити соц. мережі.");
            }
        };

        const countAllSocialItems = async () => {
            try {
                const res = await dbAxiosInstance.get("/count-socials");
                const dbCountSocialData = res.data;

                if (dbCountSocialData) {
                    if (dbCountSocialData.length > 0) {
                        setSocialsCount(dbCountSocialData[0].counter);
                    } else {
                        setSocialsCount(0);
                    }
                } else {
                    setSocialsCount(0);
                }
            } catch (error: any) {
                setSocialsCount(0);
            }
        };

        fetchAllSocialItems();
        countAllSocialItems();
    }, []);

    const handleItemDelete = async (id) => {
        try {
            await dbAxiosInstance.delete(`/social-items/${encodeURIComponent(id)}`);
            setRecordsOnPage(filterRecords(recordsOnPage, id));
            const allRecords = countRecords(socialsCount);
            setSocialsCount(allRecords);

            if (allRecords === 0) {
                setSocialErrorMsg("Немає доданих соціальних мереж. Натисніть на кнопку, щоб додати соц. мережу.");
            }
        } catch (error: any) {
            const userDataError = error.toJSON();
            setSocialErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setSocials([]);
        }
    }

    const handleRecordsOnPage = (records) => {
        setRecordsOnPage(records);
    };

    return (
         <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo; Соціальні мережі</h1>
                        <div className="itemsCounter">
                            <p>Всього: <span className="font-bold">{socialsCount}</span></p>
                        </div>
                        <div className="dashboard">
                            <ul className="dashboard-items">
                                <li>
                                    <div className="dashboard-item">
                                        <CustomTooltip msg="Додати"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/add-social-item`}}><i className="fa fa-plus"></i></Link></CustomTooltip>
                                    </div>
                                </li>
                            {
                                recordsOnPage.length > 0 ? recordsOnPage.map((social, index) => 
                                <li key={index}>
                                    <div className="manage-item-wrapper">
                                        <div className="manage-item-container">
                                            <CustomTooltip msg="Редагувати">
                                                <Link className="edit-item" to={{pathname: `/admin/dashboard/${id}/${externalID}/edit-social-item/${social.name.toLowerCase()}/${social.id}`}}>
                                                    <i className="fa fa-pencil-alt"></i>
                                                </Link>
                                            </CustomTooltip>
                                            <CustomTooltip msg="Видалити"><a className="delete-item" onClick={() => handleItemDelete(social.id)}><i className="fa fa-trash"></i></a></CustomTooltip>
                                        </div>
                                    </div>
                                    <div className="dashboard-item"><a href={social.url} key={social.id} aria-label={social.name} target="_blank" rel="noopener noreferrer">{social.name}</a></div>
                                </li>) : <li><div className="dashboard-error">{socialErrorMsg}</div></li>
                            }
                            </ul>
                        </div>
                        <Pagination data={socials} recordsPerPage={Number(webConfig.contentPerPage)} dataOnPage={handleRecordsOnPage} />
                    </div>
                  </>
                }
               </div>
            </div>
        </div>
    );
};

export default SocialsPage;