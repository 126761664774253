import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams, useLocation, Link} from "react-router-dom";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {checkAuthToken, getInsuranceType, navToAdminPanel, navToCustomersEpolicyPage, navToCustomersGreencardPage, navToCustomersPage} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {getEagentToken} from "../../../redux-data/misc/miscReducer";
import _ from "lodash";
import {InsuranceLocationState} from "../../../typings/InsuranceLocationState";

const EditedCustomerPage = (props) => {
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const eAgentToken = useSelector(getEagentToken);
    const dashboardNavigate = useNavigate();
    const {id, externalID, insuranceType, phoneNumber, customerID} = useParams<{
        id: string;
        externalID: string;
        insuranceType: string;
        phoneNumber: string; 
        customerID: string; 
    }>();
    const customerLocation = useLocation();
    const {isSuccess, successMsg, errorMsg} = customerLocation.state as InsuranceLocationState;
    const [authInProgress, setAuthInProgress] = useState(false);
    const currentInsuranceType = getInsuranceType(insuranceType!);
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken(eAgentToken) && !_.isEmpty(customerLocation.state)) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardNavigate("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardNavigate("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        if (isSuccess) {
            setSuccessNotificationHidden(false);
            setErrorNotificationHidden(true);
        } else {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(false);
        }
    }, []);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification, hideSuccessNotification]);

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
    };

    const backToViewCustomer = () => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/customer/${insuranceType}/${phoneNumber}/${customerID}/view`);
    };
    
    const backToEditCustomer = () => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/customer/${insuranceType}/${phoneNumber}/${customerID}/edit`);
    };

    return (
        <div className="container">
            <div className="section-white">
            {authInProgress ?
                <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                :
                <>
                <div className="back-icon">
                    <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                </div>
                <div className="dashboard-container">
                    <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo;&nbsp;
                        <CustomTooltip msg="Перейти до Клієнтів"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/customers`}}>Клієнти</Link></CustomTooltip> &laquo;&nbsp; 
                        <CustomTooltip msg={`Перейти до ${currentInsuranceType.label}`}><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/customers/${currentInsuranceType.value}`}}>{currentInsuranceType.label}</Link></CustomTooltip> &laquo; {phoneNumber}</h1>
                    {!hideSuccessNotification && successMsg && <div className="admin-success-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{successMsg}</div>}
                    {!hideErrorNotification && errorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{errorMsg}</div>}
                    <div>
                        <button type="button" className="admin-btn" onClick={backToViewCustomer}><i className="fa-regular fa-circle-info notification-icon"></i>Переглянути</button>
                        <button type="button" className="admin-btn" onClick={backToEditCustomer}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="button" className="admin-btn" onClick={() => navToCustomersPage(dashboardNavigate, id, externalID)}>Клієнти</button>
                        <button type="button" className="admin-btn" onClick={() => navToCustomersEpolicyPage(dashboardNavigate, id, externalID)}>Клієнти (Автоцивілка)</button>
                        <button type="button" className="admin-btn" onClick={() => navToCustomersGreencardPage(dashboardNavigate, id, externalID)}>Клієнти (Зелена карта)</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                    </div>
                </div>
                </>
            }
            </div>
        </div>
    );
};

export default EditedCustomerPage;