import {RootState} from "../index";
import {CreatorReducer} from "../base/base";
import {clear} from "../token/tokenReducer";
import serviceOsago from "../../service/serviceOsago";
import {IVehicleData} from "./vehicleTypes";
import {dbAxiosInstance} from "../../core/configDBAxios";
import {getKindByType} from "../../core/typeAuto";
import {findVehicleBrandByModel} from "../../utils/utilsGeneral";

const creator = new CreatorReducer("vehicle");
creator.addAction(clear, (state) => {
  return {data: null, error: false, isLoading: false};
});

const {setLoading, setData, setError} = creator.createActions();

const getVehicle = (regNumber: string) => async (dispatch: any) => {
  dispatch(setLoading(true));

  try {
    let res = await serviceOsago.getVehicle(regNumber);
    let dataRedux: IVehicleData | null;
    let data: any;

    if (res.data && res.data.length > 0) {
        if (res.data.httpStatusCode === 404) {
            throw {message: "Такий номер не зареєстрований"};
        }

        data = res.data[0];

        if (data.model) {
            dataRedux = {
              kind: data.model?.kind,
              type: data.category,
              regNumber: data.stateNumber.toUpperCase(),
              vin: data.bodyNumber,
              description: (data.description || "").trim(),
              modelName: data.model.name,
              brandName: data.modelText,
              name: data.model!.autoMaker!.name,
              year: data.year,
              id: data.model!.autoMaker!.id,
              engineVolume: data.engineVolume,
              autoColor: data.autoColor,
              dontHaveBodyNumber: data.dontHaveBodyNumber,
              lastModified: data.model.lastModified,
              registrationPlace: data.registrationPlace ? data.registrationPlace : null
            };
        } else {
            if (data.modelText) {
                const vehicleBrand = await findVehicleBrandByModel(data.modelText);

                if (vehicleBrand) {
                    const parsedBrand = JSON.parse(vehicleBrand.auto_maker);

                    dataRedux = {
                      kind: getKindByType(data.category),
                      type: data.category,
                      regNumber: data.stateNumber.toUpperCase(),
                      vin: data.bodyNumber,
                      description: (data.description || "").trim(),
                      modelName: data.modelText.toUpperCase().split(`${parsedBrand.name.toUpperCase()} `)[1],
                      brandName: data.modelText,
                      name: parsedBrand.name,
                      year: data.year,
                      id: parsedBrand.id,
                      engineVolume: data.engineVolume,
                      autoColor: data.autoColor,
                      dontHaveBodyNumber: data.dontHaveBodyNumber,
                      lastModified: parsedBrand.lastModified,
                      registrationPlace: data.registrationPlace ? data.registrationPlace : null
                    }
              } else {
                   throw {message: "Транспортний засіб не знайдено, скористайтесь розрахунком за характеристиками"};
              }
          } else {
              throw {message: "Транспортний засіб не знайдено, скористайтесь розрахунком за характеристиками"};
          }
       }
    } else {
        res = await dbAxiosInstance.get(`/vehicle/${encodeURIComponent(regNumber)}`);
        const dbVehicleData = res.data;

        if (dbVehicleData && dbVehicleData.length > 0) {
            data = dbVehicleData[0];
            const vehicleRegLocationRes = await dbAxiosInstance.get(`/vehicle/reg-location/${encodeURIComponent(data.reg_place_id)}/${encodeURIComponent(data.reg_place_code)}`);
            const regPlaceData = vehicleRegLocationRes.data[0];

            dataRedux = {
              kind: data.kind,
              type: data.category,
              regNumber: data.state_number,
              vin: data.vin,
              description: "",
              modelName: data.model,
              brandName: data.model_text,
              name: data.brand,
              year: data.year,
              id: data.id,
              engineVolume: data.engine_volume,
              autoColor: data.auto_color,
              dontHaveBodyNumber: data.dont_have_vin,
              lastModified: data.last_modified,
              registrationPlace: {
                id: regPlaceData?.id,
                placeCode: regPlaceData?.place_code,
                zone: regPlaceData?.zone, 
                nameFull: regPlaceData?.name_full, 
                country: regPlaceData?.country,
                name: regPlaceData?.name,
                nameRus: regPlaceData?.name_rus, 
                nameFullRus: regPlaceData?.name_full_rus, 
                cdbMtibu: regPlaceData?.cdb_mtibu,
                cdbMtibuCode: regPlaceData?.cdb_mtibu_code,
                lastModified: regPlaceData?.last_modified
              }
            }
        } else {
            throw {message: "Транспортний засіб не знайдено, скористайтесь розрахунком за характеристиками"};
        }
    }    
    
    dispatch(setData({...dataRedux}));
    return res.data[0];
  } catch (e: any) {
    //@ts-ignore
    dispatch(setError(e.message));
  } finally {
    dispatch(setLoading(false));
  }
};

const getVehicleData = (state: RootState) => state.vehicle.data!;
const getVehicleLoading = (state: RootState) => state.vehicle.isLoading;
const getVehicleAutoType = (state: RootState) => state.vehicle.data!.type;
const getVehicleError = (state: RootState) => state.vehicle.error;
const getVehicleMark = (state: RootState) => state.vehicle.data!.brandName;
const getVehicleModel = (state: RootState) => state.vehicle.data!.modelName;
const getVehicleAutoKind = (state: RootState) => state.vehicle.data!.kind;

export {
  setData,
  getVehicle,
  getVehicleData,
  getVehicleLoading,
  getVehicleAutoType,
  getVehicleError,
  getVehicleMark,
  getVehicleModel,
  getVehicleAutoKind,
};
export default creator.createReducerFetch();
