import React, {createContext, useContext, useMemo} from 'react';
import useMedia from 'use-media';

export const MediaQueryContext = createContext(null);

const mediaQueries = {
  tablet: "(max-width: 1200px)",
  mobile: "(max-width: 767px)"
};

export default function ({children}: any) {
  const tabletView = useMedia(mediaQueries.tablet);
  const mobileView = useMedia(mediaQueries.mobile);

  const value: any = useMemo(() => ({tabletView, mobileView}), [
    tabletView,
    mobileView
  ]);
  
  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  )
}

export function useMediaQueryContext() {
  return useContext(MediaQueryContext);
}