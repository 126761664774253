import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Policy from "./Policy";
import DataOrder from "./DataOrder";
import {useStepsContext} from "../../utils/StepsProvider";
import {getOfferItems, getPolicyLoading, getSelectedItem, getAnalyticsId} from "../../redux-data/insurance/insuranceSelector";
import {loadPolicies, setItemId, setPolicy} from "../../redux-data/insurance/insuranceReducer";
import {ICompanyInsurance} from "../../typings/IDataOffers";
import CustomLoader from "../Forms/common/CustomLoader";
import {getOfferError} from "../../redux-data/offer/offerReducer";
import {useNavigate} from "react-router-dom";
import {handleInsuranceNav, isCompaniesSearchVisible, navToHomePage, isSettingItemActive, checkAnalyticsData} from "../../utils/utilsGeneral";
import InsurancePolicyFilter from "../../utils/InsurancePolicyFilter";
import webConfig from "../../config";
import {isMobile} from "react-device-detect";
import {dbAxiosInstance} from "../../core/configDBAxios";
import {getVehicleData} from "../../redux-data/vehicle/vehicleReducer";
import DiiaBanner from "../Forms/common/DiiaBanner";

const compareEpolicy = (a: ICompanyInsurance, b: ICompanyInsurance) => {
  const minA = Math.min(...a.data.map((d) => d.amount));
  const minB = Math.min(...b.data.map((d) => d.amount));
  return minA - minB;
};

const compareGreencard = (a, b) => {
  if (a.data[0].productCode === webConfig.guardianProductCode) {
      return -1;
  }

  if (b.data[0].productCode === webConfig.guardianProductCode) {
      return 1;
  }

  return a.name.localeCompare(b.name);
};

const StepTwo = ({type}: {type: "epolicy" | "greencard"}) => {
  const items = useSelector(getOfferItems);
  const isLoading = useSelector(getPolicyLoading);
  const mainItem = useSelector(getSelectedItem);
  const offerError = useSelector(getOfferError);
  const vehicleData = useSelector(getVehicleData);
  const analyticsID = useSelector(getAnalyticsId);
  const dispatch = useDispatch();
  const {goTo} = useStepsContext() as any;
  const navigate = useNavigate();
  const [filteredItems, setFilteredItems] = useState<ICompanyInsurance[]>(items);
  const [isSearchVisible, setSearchVisibility] = useState(false);
  const [isDiiaBannerVisible, setDiiaBannerVisible] = useState(false);

  useEffect(() => {
    const checkCompaniesSearch = async () => {
      const isOn = await isCompaniesSearchVisible();
      setSearchVisibility(isOn);
    };

    const checkDiiaBannerVisiblity = async () => {
        const diiaBannerState = await isSettingItemActive(webConfig.displayDiiaBanner);
        setDiiaBannerVisible(diiaBannerState);
    };

    const updateAnalyticsData = async () => {
      try {
        const analytics = {insuranceStep: 2, isVehicle: checkAnalyticsData(vehicleData.regNumber), vehicleStateNumber: vehicleData.regNumber};
        await dbAxiosInstance.put(`/update-analytics/insurance/vehicle/${encodeURIComponent(analyticsID!)}`, analytics);
      } catch (error: any) {}
    };

    dispatch(loadPolicies(type));
    checkCompaniesSearch();
    checkDiiaBannerVisiblity();
    updateAnalyticsData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [mainItem]);

  useEffect(() => {
    if (items.length > 0) {
        setFilteredItems(items);
    }
  }, [items]);

  const handleChangeDataOrder = () => {
    goTo(1);
  };

  const handleBuy = (id: any) => {
    dispatch(setItemId(id));
    dispatch(setPolicy(items[id]));
    goTo(3);
  };

  const NoItems = () => {
    return (
      <div className="col-sm-8">
        <h4>Нiчого не знайдено</h4>
        {offerError && 
          <div className="error-msg-visible"><p dangerouslySetInnerHTML={{__html: offerError}} />
            <button type="button" className="insurance-btn" onClick={() => handleInsuranceNav("epolicy", dispatch, navigate)}>Автоцивілка</button>
            <button type="button" className="insurance-btn" onClick={() => handleInsuranceNav("greencard", dispatch, navigate)}>Зелена карта</button>
            <button type="button" className="insurance-btn" onClick={() => navToHomePage(navigate)}>На головну</button>
          </div>
        }
      </div>
    );
  };

  const allVisibleItems = type === "epolicy" ? items.filter((item) => !item.isHidden) : items.filter((item) => !item.data[0].isHidden);

  return (
    <div className="row pay-choose">
      {isLoading ?
        <CustomLoader className="policy-loading" isSquare={false} isInfoOn={true} infoMsg="Триває підбір страхових компаній" isLoading={isLoading} />
       : allVisibleItems.length > 0 ?
        <>
          <div className="col-sm-6">
            <h4>
              Всього пропозицій <span className="green">{allVisibleItems.length}</span>
            </h4>
          </div>
          <div className="col-lg-8">
            {isMobile && isDiiaBannerVisible && <DiiaBanner />}
            {isSearchVisible && <InsurancePolicyFilter data={items} onFilteredItems={(records) => setFilteredItems(records)} />}
            {filteredItems.length > 0 && type === "epolicy" && filteredItems.sort(compareEpolicy).map((item) => (!item.isHidden && <Policy insuranceType={type} key={item.id} {...item} onBuy={handleBuy} />))}
            {filteredItems.length > 0 && type === "greencard" && filteredItems.sort(compareGreencard).map((item) => (!item.data[0].isHidden && <Policy insuranceType={type} key={item.id} {...item} onBuy={handleBuy} />))}
          </div>
        </>
       : 
        <NoItems />
      }
      <div className={`col-lg-4 ${isLoading ? "offset-lg-8" : ""}`}>
        {!isMobile && isDiiaBannerVisible &&
          <DiiaBanner />
        }
        <div className="pay-choose_info">
          <DataOrder type={type} handleChangeDataOrder={handleChangeDataOrder} />
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
