import React, {useState} from "react";
import CustomTooltip from "./CustomTooltip";
import {insuranceProxyAxiosInstance} from "../../../core/configInsuranceProxyAxios";

interface IImageZoomComponent {
    type?: string;
    content: string;
    alt: string;
    caption: string;
    size?: any;
    path?: string;
    url?: string;
    setImageDeleted?: (data: any) => void;
    setImageAdded?: (item: string) => void;
    isLocalImage: boolean;
}

const ImageZoomComponent = ({type, content, alt, caption, size, path, url, setImageDeleted, setImageAdded, isLocalImage}: IImageZoomComponent) => {
    const [isZoomed, setIsZoomed] = useState(false);

    const toggleZoom = () => {
        setIsZoomed(!isZoomed);
    };

    const handleItemAdd = (fileUrl) => {
        setImageAdded?.(`[img=Заголовок зображення]${fileUrl}[/img]`);
    };

    const handleItemDelete = async (filePath) => {
        try {
            const res = await insuranceProxyAxiosInstance.delete("/delete/image", {
                data: {
                    imagePath: filePath
                }
            });
            setImageDeleted?.(res.data);
        } catch (error: any) {
            setImageDeleted?.(error.response.data);
        }
    };

    return (
        <div className="image-zoom-component">
            {isLocalImage ?
                <>
                    <div className="manage-item-wrapper">
                        <div className="manage-item-container">
                            <CustomTooltip msg="Додати у текстове поле"><a className="add-item" onClick={() => handleItemAdd(url)}><i className="fa fa-file-plus"></i></a></CustomTooltip>
                            <CustomTooltip msg="Видалити"><a className="delete-item" onClick={() => handleItemDelete(path)}><i className="fa fa-trash"></i></a></CustomTooltip>
                        </div>
                    </div>
                    <CustomTooltip msg={caption}><img className="image-zoom-item" src={`data:${type};base64,${content}`} onClick={toggleZoom} alt={alt} /></CustomTooltip>
                </>
             :
                <CustomTooltip msg={caption}><img className="image-zoom-item" src={content} onClick={toggleZoom} alt={alt} /></CustomTooltip>
            }
            {isZoomed && (
                <div className="image-zoomed-wrapper" onClick={toggleZoom}>
                    <div className="image-zoomed-container">
                        <CustomTooltip msg={caption}><img className="image-zoomed-item" src={`${isLocalImage ? `data:${type};base64,${content}` : content}`} alt={alt} /></CustomTooltip>
                        {caption && size && isLocalImage && <p className="image-zoomed-caption">{`Зображення: ${caption.substring(0, caption.lastIndexOf("."))}. Роздільна здатність: ${size.width} x ${size.height}`}</p>}
                        {caption && !size && !isLocalImage && <p className="image-zoomed-caption">{`Зображення: ${caption}`}</p>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageZoomComponent;