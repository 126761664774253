import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams, Link} from "react-router-dom";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {getEagentToken} from "../../../redux-data/misc/miscReducer";
import {checkAuthToken} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";

const AnalyticsPage = (props) => {
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const eAgentToken = useSelector(getEagentToken);
    const dashboardNavigate = useNavigate();
    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();
    const [authInProgress, setAuthInProgress] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken(eAgentToken)) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardNavigate("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardNavigate("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                    :
                    <>
                        <div className="back-icon">
                            <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                        </div>
                        <div className="dashboard-container">
                            <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo; Аналітика</h1>
                            <div className="dashboard">
                                <ul className="dashboard-items">
                                    <li>
                                        <div className="dashboard-item">
                                            <CustomTooltip msg="БанкІД"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/analytics/bankid`}}>БанкІД</Link></CustomTooltip>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="dashboard-item">
                                            <CustomTooltip msg="Сайт"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/analytics/website`}}>Сайт</Link></CustomTooltip>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
                }
                </div>
            </div>
        </div>
    );
};

export default AnalyticsPage;