import React, {useState, useEffect, forwardRef} from "react";
import Autosuggest from "react-autosuggest";
import {useDispatch, useSelector} from "react-redux";
import {
  getErrorCity,
  getLoadingCity,
  setData
} from "../../../redux-data/city/cityReducer";
import {getInstanceError} from "../../../utils/getInstanceError";
import theme from "./AutoComplete.module.css";
import {config} from "../../../assets/config";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import CustomLoader from "../../Forms/common/CustomLoader";

interface Props {
  id?: string;
  className?: string;
  content: string;
  handleContent(event: any, {newValue, method}: any): void;
  isTouch: boolean;
  handleContentBlur(event: any, {newValue, method}: any): void;
  handleContentSuggestion?(suggestion: any): void;
}

export const AutoComplete = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoadingCity);
  const [suggestions, setSuggestions] = useState([] as any[]);
  const [cities, setCities] = useState([]);

  const getSuggestions = (value, cities) => {
    let res = []; 
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    
    if (inputValue && inputLength > 0 && cities && cities.length > 0) {
        res = cities.filter((city) => {
          return city.name.trim().toLowerCase().startsWith(inputValue);
        });
    }

    return res;
  };

  const renderSuggestion = (suggestion) => (
      <div>
        {suggestion.name_full}
      </div>
  );

  const getSuggestionValue = (suggestion) => {
    props.handleContentSuggestion?.(suggestion);
    props.handleContent(null, suggestion.name_full);
    return suggestion.name_full;
  };

  const error = useSelector(getErrorCity);
  const errors = {
    regCity: error
  };
  const {
    getClassError,
    getMessageError
  } = getInstanceError(errors);
  const mes = getMessageError("regCity");

  const onSuggestionsFetchRequested = ({value}) => {
    setSuggestions(getSuggestions(value, cities));
  };

  const inputProps = {
    placeholder: "Місто реєстрації автомобіля",
    value: props.content,
    onChange: props.handleContent,
    onBlur: props.handleContentBlur,
    disabled: loading,
    id: props.id,
    className: props.className,
    ref
  };

  useEffect(() => {
    const delayCityRequest = setTimeout(async () => {
      if (props.content.length > 0) {
          const res = await dbAxiosInstance.get(`/place/${encodeURIComponent(props.content)}`);
          const customerCities = res.data;
          setCities(customerCities);
          setSuggestions(getSuggestions(props.content, customerCities));
      }
    }, 1000);

    return () => clearTimeout(delayCityRequest);
  }, [props.content]);

  return (
    <div className={getClassError("regCity", props.isTouch)}>
      <Autosuggest 
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={(event, data) => {
          dispatch(setData(data.suggestion));
        }}
        theme={theme}
        highlightFirstSuggestion={true}
        alwaysRenderSuggestions={true}
      />
      {mes && <p>{mes}</p>}
      <CustomLoader isSquare={true} isInfoOn={false} isLoading={loading} color={config.color} css={config.css} />
    </div>
  );
});
