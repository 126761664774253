import serviceOsago from "../../service/serviceOsago";
import {RootState} from "../index";
import {ICityActions, ICityState} from "./cityTypes";
import {CreatorReducer} from "../base/base";
import {clear} from "../token/tokenReducer";

const initState: ICityState = {
  data: null,
  regCity: "",
  isLoading: false,
  error: false,
};

const creator = new CreatorReducer<ICityActions, ICityState>("city");
creator.addAction("SET_REG_CITY",
  (state, action) => {
    return {...state, regCity: action.payload};
  });
creator.addAction(clear,
  state => {
    return {...initState};
  });
const {setError, setData, setLoading, setRegCity} = creator.createActions();

const getCityFetch = (city: string) => async (dispatch: any) => {  
  dispatch(setLoading(true));
  try {
    const res = await serviceOsago.getCityCode(city);        
    dispatch(setData(res));
    dispatch(setRegCity(city));    
    return res;
  } catch (e) {        
    dispatch(setError(new Error("Невірно введено місто")));
  } finally {
    dispatch(setLoading(false));
  }
};

const getRegisterCity = (state: RootState) => state.city.regCity;
const getCityName = (state: RootState) => {
  const cityStateData = state.city.data;
  let cityInfo = "Завантаження даних...";

  if (cityStateData) {
      cityInfo = !cityStateData.name_full ? cityStateData.nameFull : cityStateData.name_full;
  } else {
      if (state.insurance.dataOrder.foreign) {
          cityInfo = "Іноземне авто";
      } else {
          if (!state.insurance.policies.isLoading) {
              cityInfo = "Невідомо";
          }
      }
  }

  return cityInfo;
}

const getVehicleRegLocation = (state: RootState) => {
  return state.city.data;
}

const getLoadingCity = (state: RootState) => state.city.isLoading;
const getErrorCity = (state: RootState) => state.city.error;
const hasCityZone = (state: RootState) => {
  try {
    return !!state.city.data!.zone;
  } catch (e) {
    return false;
  }
};

export {setData, setError, hasCityZone, getRegisterCity, getErrorCity, getLoadingCity, getCityFetch, setRegCity, getCityName, getVehicleRegLocation};
export default creator.createReducerFetch(initState);
