import React from "react";

const TabContent = ({tabs, currentTab, isPadding}) => {
    return (
      tabs.map((tab) => (
        <div key={tab.index} className={`${isPadding ? "tab-panel-padding" : "tab-panel"} ${currentTab === `tab-${tab.index}` ? "active" : ""}`}>
            {tab.children}
        </div>
      ))
    );
};

export default TabContent;