import React from "react";

const RegCities = (props) => {
    return (
        <div className="cities-wrapper">
          <ul className="cities-container">
            <li><button type="button" className="city-btn" value="м. Київ, Україна" data-suggestion="Київ" onClick={props.handleVechicleRegCity}>Київ</button></li>
            <li><button type="button" className="city-btn" value="м. Львів, Львівський р-н, Львівська обл., Україна" data-suggestion="Львів" onClick={props.handleVechicleRegCity}>Львів</button></li>
            <li><button type="button" className="city-btn" value="м. Одеса, Одеський р-н, Одеська обл., Україна" data-suggestion="Одеса" onClick={props.handleVechicleRegCity}>Одеса</button></li>
            <li><button type="button" className="city-btn" value="м. Дніпро, Дніпровський р-н, Дніпропетровська обл., Україна" data-suggestion="Дніпро" onClick={props.handleVechicleRegCity}>Дніпро</button></li>
            <li><button type="button" className="city-btn" value="м. Харків, Харківський р-н, Харківська обл., Україна" data-suggestion="Харків" onClick={props.handleVechicleRegCity}>Харків</button></li>
            <li><button type="button" className="city-btn" value="м. Суми, Сумський р-н, Сумська обл., Україна" data-suggestion="Суми" onClick={props.handleVechicleRegCity}>Суми</button></li>
          </ul>
        </div>
    );
};

export default RegCities;