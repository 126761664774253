import React, {useEffect, useState} from "react";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {checkAuthToken, checkValue, refreshPage, navToAdminPanel, navToGreencardCompaniesPage, checkRequestStatus, navToEpolicyCompaniesPage} from "../../../utils/utilsGeneral";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {IEpolicyCompany} from "../../../typings/IDataOffers";
import SwitchComponent from "react-switch";
import {getEagentToken} from "../../../redux-data/misc/miscReducer";

const EditEpolicyCompany = (props) => {
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const eAgentToken = useSelector(getEagentToken);
    const dashboardNavigate = useNavigate();
    const {id, externalID, productID, epolicyID} = useParams<{
        id: string;
        externalID: string; 
        productID: string; 
        epolicyID: string;
    }>();
    const [authInProgress, setAuthInProgress] = useState(false);
    const [editEpolicyCompanyData, setEpolicyCompanyDataEdited] = useState<IEpolicyCompany>({
        id: 0,
        name: "",
        productID: 0,
        isHidden: false
    });
    const [epolicyCompanyErrorMsg, setEpolicyCompanyErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [epolicyCompanyName, setEpolicyCompanyName] = useState("");
    const [isCompanyHidden, setCompanyHidden] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken(eAgentToken)) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardNavigate("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardNavigate("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        const fetchEpolicyCompany = async () => {
            try {
                const res = await dbAxiosInstance.get(`/epolicy/company/${encodeURIComponent(productID!)}/${encodeURIComponent(epolicyID!)}`);
                const dbEpolicyData = res.data;

                if (dbEpolicyData) {
                    if (dbEpolicyData.length > 0) {
                        const [editEpolicyCompany] = dbEpolicyData;
                        setEpolicyCompanyDataEdited({id: editEpolicyCompany.id, name: editEpolicyCompany.name, productID: editEpolicyCompany.product_id, isHidden: editEpolicyCompany.is_hidden});
                        setEpolicyCompanyName(editEpolicyCompany.name);
                        setCompanyHidden(Boolean(editEpolicyCompany.is_hidden));
                        setEpolicyCompanyErrorMsg("");
                        setErrorNotificationHidden(true);
                    } else {
                        setEpolicyCompanyDataEdited({id: 0, name: "", productID: 0, isHidden: false});
                        setEpolicyCompanyName("");
                        setCompanyHidden(false);
                        setEpolicyCompanyErrorMsg("Немає такої страхової компанії автоцивілки.");
                        setErrorNotificationHidden(false);
                    }
                } else {
                    setEpolicyCompanyDataEdited({id: 0, name: "", productID: 0, isHidden: false});
                    setEpolicyCompanyName("");
                    setCompanyHidden(false);
                    setEpolicyCompanyErrorMsg("Помилка БД! Не вдалося завантажити страхову компанію автоцивілки.");
                    setErrorNotificationHidden(false);
                }
            } catch (error: any) {
                setEpolicyCompanyDataEdited({id: 0, name: "", productID: 0, isHidden: false});
                setEpolicyCompanyName("");
                setCompanyHidden(false);
                setEpolicyCompanyErrorMsg("Помилка БД! Не вдалося завантажити страхову компанію автоцивілки.");
                setErrorNotificationHidden(false);
            }
        };
        
        fetchEpolicyCompany();
    }, []);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification]);

    const editCompanyNameChange = (event) => {
        setEpolicyCompanyDataEdited((prev) => ({...prev, name: checkValue(event.target.value)}));
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const editProductIDChange = (event) => {
        setEpolicyCompanyDataEdited((prev) => ({...prev, productID: checkValue(event.target.value)}));
    };

    const handleHiddenStateChange = (isCompanyHidden) => {
        setCompanyHidden(isCompanyHidden);
        setEpolicyCompanyDataEdited((prev) => ({...prev, isHidden: isCompanyHidden}));
    };

    const handleItemEdit = async (event) => {
        event.preventDefault();

        if (editEpolicyCompanyData.name.toString().trim().length > 0 && editEpolicyCompanyData.productID > 0) {
            try {
                const res = await dbAxiosInstance.put(`/epolicy/edit-company/${encodeURIComponent(epolicyID!)}`, editEpolicyCompanyData);
                const editStatus = res.status;
                dashboardNavigate(`/admin/dashboard/${id}/${externalID}/epolicy/company/${productID}/${epolicyID}/item/edited`, {
                    state: {
                        isSuccess: checkRequestStatus(editStatus),
                        status: editStatus,
                        successMsg: res.data,
                        errorMsg: ""
                    }
                });
                setEpolicyCompanyErrorMsg("");
                setErrorNotificationHidden(true);
            } catch (error: any) {
                const userDataError = error.toJSON();
                setEpolicyCompanyErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
                setErrorNotificationHidden(false);
            }
        } else {
            setEpolicyCompanyErrorMsg("Будь ласка, заповніть усі поля, щоб оновити страхову компанію автоцивілки!");
            setErrorNotificationHidden(false);
        }
    };

    const clearFields = (event) => {
        event.preventDefault();
        setErrorNotificationHidden(true);
        setEpolicyCompanyDataEdited({id: 0, name: "", productID: 0, isHidden: false});
        setCompanyHidden(false);
        setEpolicyCompanyErrorMsg("");
    };

    return (
        <div className="container">
            <div className="section-white">
            {authInProgress ?
                <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                :
                <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1>Редагувати страхову компанію &#8212; {epolicyCompanyName}</h1>
                        <div className="edit-dashboard-item-form">
                            <input type="text" name="name" placeholder="Назва" onChange={editCompanyNameChange} value={editEpolicyCompanyData.name} />
                            <input type="number" name="productID" placeholder="Ідентифікатор продукту" onChange={editProductIDChange} value={editEpolicyCompanyData.productID} readOnly={true} />
                            <div className="switch-wrapper">
                                <label className="switch-container">
                                    <SwitchComponent onChange={handleHiddenStateChange} checked={isCompanyHidden} onColor="#1BA876" />
                                    <div className="switch-text">Є прихованою</div>
                                </label>
                            </div>
                        </div>
                        <button type="submit" className="edit-btn" onClick={handleItemEdit}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                        <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                        <button type="button" className="admin-btn" onClick={() => navToEpolicyCompaniesPage(dashboardNavigate, id, externalID)}>Автоцивілка</button>
                        <button type="button" className="admin-btn" onClick={() => navToGreencardCompaniesPage(dashboardNavigate, id, externalID)}>Зелена карта</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                    </div>
                    {!hideErrorNotification && epolicyCompanyErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{epolicyCompanyErrorMsg}</div>}
                </>
            }
            </div>
        </div>
    );
};

export default EditEpolicyCompany;