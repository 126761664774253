import React from "react";
import CustomField from "./CustomField";

const externalPassport = (name: string) => `externalPassport.${name}`;

const ExternalPassportPartForm = (props) => {
  return (
    <>
      <div className={props.seriesClass}>
        <CustomField
          register={props.register({
            required: "Це поле обов'язкове",
            pattern: {
              value: /^[A-Za-z]{2}$/,
              message: "Серія складається з двох літер латиниці"
            },
          })}
          errors={props.errors}
          name={externalPassport("series")}
          label="Серія"
          placeholder="AB"
          readOnly={props.isReadOnly}
          defaultValue={props.documentSeriesValue}
          onChange={props.onDocumentSeriesChange}
        />
      </div>
      <div className={props.numberClass}>
        <CustomField
          register={props.register({
            required: "Це поле обов'язкове",
            pattern: {
              value: /\d{6}/,
              message: "Номер складається з шести цифр",
            },
            maxLength: {
              value: 6,
              message: "Номер складається з шести цифр"
            },
          })}
          errors={props.errors}
          name={externalPassport("number")}
          label="Номер"
          placeholder="123456"
          readOnly={props.isReadOnly}
          defaultValue={props.documentNumberValue}
          onChange={props.onDocumentNumberChange}
        />
      </div>
      <div className={props.issuedDateClass}>
        <CustomField
          register={props.register({
            required: "Це поле обов'язкове"
          })}
          errors={props.errors}
          name={externalPassport("date")}
          label="Коли видано"
          type="date"
          placeholder="РРРР.ММ.ДД"
          autoComplete="off"
          dateReadOnly={props.isReadOnly}
          dateValue={props.documentIssuedDateValue}
          onDateChange={props.onDocumentIssuedDateChange}
        />
      </div>
      <div className={props.issuedByClass}>
        <CustomField
          register={props.register({
            required: "Це поле обов'язкове"
          })}
          errors={props.errors}
          name={externalPassport("issued_by")}
          label="Ким видано"
          placeholder="4622"
          readOnly={props.isReadOnly}
          defaultValue={props.documentIssuedByValue}
          onChange={props.onDocumentIssuedByChange}
        />
      </div>
    </>
  );
};

export default ExternalPassportPartForm;
