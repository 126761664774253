import React from "react";

const PolicyStatus = ({status}) => {
    return (
        <>
        {
            status == "DRAFT" && <span className="policy-draft-status">Чернетка</span>
        }
        {
            status == "REQUEST" && <span className="policy-request-status">Заявлений</span>
        }
        {
            status == "SENDING_OTP" && <span className="policy-sending-otp-status">Відправлення коду</span>
        }
        {
            status == "SENDED_OTP" && <span className="policy-sended-otp-status">Код відправлено</span>
        }
        {
            status == "PAY_OFFER" && <span className="policy-pay-offer-status">Оплата поліса</span>
        }
        {
            status == "PAID_OFFER" && <span className="policy-paid-offer-status">Поліс оплачено</span>
        }
        {
            status == "EMITTED" && <span className="policy-emitted-status">Укладений</span>
        }
        </>
    );
};

export default PolicyStatus;