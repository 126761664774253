import React, {useState} from "react";
import {IPolicy, FranchiseItem} from "../../typings/IDataOffers";
import {useDispatch} from "react-redux";
import {getDiscountPercentage, getCashback} from "../../redux-data/insurance/insuranceSelector";
import {companyImage, companyImages} from "../../constants/companyImages";
import CustomSelect from "../common/CustomSelect";
import {setPolicyId, setAmountId} from "../../redux-data/insurance/insuranceReducer";
import CustomTooltip from "../Forms/common/CustomTooltip";

const Policy = ({name, data, formId, id, onBuy, insuranceType, companyId}: IPolicy) => {
  const dispatch = useDispatch();
  const franchises: FranchiseItem[] = data.filter((item) => !item.isHidden).sort((a, b) => b.franchise - a.franchise).map((item) => {
    return {data: {...item}, value: item.franchiseID.toString(), label: `${item.franchise.toString()} грн`, disabled: false};
  });
  const maxFranchise = Math.max(...franchises.map((item) => item.data.franchise));
  const defaultFranchise = franchises.find((item) => item.data.franchise === maxFranchise);
  const [selectedFranchise, setSelectedFranchise] = useState<FranchiseItem | undefined>(defaultFranchise);
  const min = Math.min(...data.map((d) => d.amount));
  const max = Math.max(...data.map((d) => d.amount));

  const minDiscount = Math.min(...data.map((d) => d.discountedAmount));
  const maxDiscount = Math.max(...data.map((d) => d.discountedAmount));
  const discountPercentage = getDiscountPercentage(selectedFranchise?.data.amount, selectedFranchise?.data.discountedAmount);

  const handleFranchiseChange = (item) => {
    setSelectedFranchise(item);
  };

  const handleClick = () => {
    dispatch(setPolicyId(selectedFranchise?.data.franchiseID));
    dispatch(setAmountId(selectedFranchise?.data.id));
    onBuy(id);
  };
  
  const img = companyImages.find((company: companyImage) => company.id === companyId);
 
  return (
    <div className="search-result_one">
      <figure className="search-result_pic">
        <img key={img?.id} src={img ? require(`../../assets/img/logos/${img.key}`).default : "\\img\\base.webp"} alt="" />
      </figure>
      {insuranceType === "epolicy" ?
       <>
        <article className="search-result_info">
          <h4>{name}</h4>
          <div className="franshise-title-wrapper">
            <CustomTooltip msg="Частина збитку, яка не відшкодовується страховою компанією">
              <span className="franshise-title">Франшиза
                <i className="fa-sharp fa-regular fa-circle-question insurance-notification-icon"></i>
              </span>
            </CustomTooltip>
          </div>
          <div className="franchise-control">
            <CustomSelect
              options={franchises}
              isSearchable={false}
              handleChange={handleFranchiseChange}
              isCreatable={false}
              defaultValue={selectedFranchise}
              value={selectedFranchise}
              placeholder="Вибрати"
            />
          </div>
        </article>
        {selectedFranchise?.data.amount &&
          <>
            {min > minDiscount || max > maxDiscount ? 
              <div className="search-result-epolicy_price">
                <div className="old-price">Вартість {selectedFranchise?.data.amount.toFixed(2)} ₴</div>
                <p>Знижка {discountPercentage.toFixed(0)}%</p>
                <div className="price-item">Вартість {selectedFranchise?.data.discountedAmount.toFixed(2)} ₴</div>
              </div> 
            : 
              <div className="search-result-epolicy_price">
                <div className="price-item">Вартість {selectedFranchise?.data.amount.toFixed(2)} ₴</div>
              </div>
            }
          </>
        }
       </>
       :
        <article className="search-result_info">
          <h4>{name}</h4>
          {data.map((item) => (
            <div className="search-result-greencard_price" key={item.id}>
              <div className="price-item">Вартість {item.amount.toFixed(2)} ₴</div>
              {item.isCashback && item.cashbackPercentage > 0 && 
                <CustomTooltip msg="Гарантовано кешбек повертаємо протягом трьох робочих днів">
                  <div key={item.franchiseID} className="cashback-item">Кешбек {item.cashbackPercentage}% на Вашу карту {getCashback(item.amount, item.cashbackPercentage).toFixed(2)} ₴</div>
                </CustomTooltip>
              }
            </div>
          ))}
        </article>
      }
      {selectedFranchise?.data.amount &&
        <div className="search-result_right">
          <button
            form={formId}
            type="submit"
            className="btn btn-link btn-primary btn-buy"
            onClick={handleClick}
          >
            Купити
            <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
          </button>
        </div>
      }
    </div>
  );
};

export default Policy;
