import React from "react";
import CustomField from "../CustomField";

const ePensionCertificate = (name: string) => `ePensionCertificate.${name}`;

const EPensionCertificateForm = (props) => {
  return (
    <>
      <div className={props.numberClass}>
        <CustomField
          register={props.register({
            required: "Це поле обов'язкове",
            pattern: {
              value: /\d{6}/,
              message: "Номер складається з шести цифр",
            },
            maxLength: {
              value: 6,
              message: "Номер складається з шести цифр",
            },
          })}
          errors={props.errors}
          name={ePensionCertificate("number")}
          label="Номер"
          placeholder="123456"
          readOnly={props.isReadOnly}
          defaultValue={props.documentNumberValue}
          onChange={props.onDocumentNumberChange}
        />
      </div>
      <div className={props.expirationDateClass}>
        <CustomField
          register={props.register({
            required: "Це поле обов'язкове"
          })}
          errors={props.errors}
          name={ePensionCertificate("date_end")}
          label="Дата закінчення"
          type="date"
          placeholder="РРРР.ММ.ДД"
          autoComplete="off"
          dateReadOnly={props.isReadOnly}
          dateValue={props.documentExpirationDateValue}
          onDateChange={props.onDocumentExpirationDateChange}
        />
      </div>
    </>
  );
};

export default EPensionCertificateForm;
