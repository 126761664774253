import React from "react";

interface IMstbuPolicyValidation {
    link: string;
}

const MstbuPolicyValidation = ({link}: IMstbuPolicyValidation) => {
    return (
        <div className="mstbu-policy-validation">
            <a href={`${link}`} className="btn btn-link btn-white-border">Перевірка чинності полісу</a>
        </div>
    );
};

export default MstbuPolicyValidation;