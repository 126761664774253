import React from "react";
import {Link} from "react-router-dom";
import CustomTooltip from "../components/Forms/common/CustomTooltip";
import {ILinkComponent} from "../typings/ICustomLink";

const LinkComponent = ({url, content, tooltipMsg}: ILinkComponent) => {
    return (
        <CustomTooltip msg={tooltipMsg}>
            <Link className="link-component" to={{pathname: url}}>{content}</Link>
        </CustomTooltip>
    );
};

export default LinkComponent;