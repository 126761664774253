import React, {useState, useEffect} from "react";
import CustomTooltip from "./CustomTooltip";
import ReactDOM from "react-dom";
import ImageZoomComponent from "./ImageZoomComponent";

const InfoSpoiler = ({isDefaultSpoilerTitle, spoilerContentVisibility, spoilerTitle, spoilerContent}) => {  
    const [isSpoilerVisible, setSpoilerVisible] = useState(spoilerContentVisibility);

    useEffect(() => {
        const handleTooltip = () => {
            const tooltipWrappers = document.querySelectorAll(".tooltip-wrapper");
            tooltipWrappers.forEach((wrapper) => {
                const title = wrapper.getAttribute("data-title");
                const src = wrapper.getAttribute("data-src");

                if (title && src) {
                    ReactDOM.render(<ImageZoomComponent content={src} caption={title} alt={title} isLocalImage={false} />, wrapper);
                }
            });
        };

        if (isSpoilerVisible) {
            handleTooltip();
        }
    }, [isSpoilerVisible]);


    const handleSpoilerState = (event) => {
        setSpoilerVisible(!isSpoilerVisible);
    };

    return (
        <div className="spoiler">
            <CustomTooltip msg="Натисніть, щоб відкрити/закрити">
                <p className={`${isDefaultSpoilerTitle ? "spoiler-title" : "spoiler-title-centered"}`} onClick={handleSpoilerState}><i className={`notification-icon fa-solid ${isSpoilerVisible ? "fa-minus" : "fa-plus"}`}></i>{spoilerTitle}</p>
            </CustomTooltip>
            {typeof spoilerContent === "object" ?
              isSpoilerVisible && <div className="spoiler-content">{spoilerContent}</div>
             :
              isSpoilerVisible && <div className="spoiler-content" dangerouslySetInnerHTML={{__html: spoilerContent}} />
            }
        </div>
    );
};

export default InfoSpoiler;