export const bbcodeColors = [
    {value: "Назва", label: "Стандартний"},
    {value: "#FFFFFF", label: "Білий"},
    {value: "#FF0000", label: "Червоний"},
    {value: "#008000", label: "Зелений"},
    {value: "#FFA500", label: "Оранжевий"},
    {value: "#FFFF00", label: "Жовтий"},
    {value: "#800000", label: "Бордовий"},
    {value: "#0000FF", label: "Синій"},
    {value: "#800080", label: "Фіолетовий"},
    {value: "#7FFFD4", label: "Аквамарин"},
    {value: "#A52A2A", label: "Коричневий"},
    {value: "#00FF00", label: "Лайм"},
    {value: "#808080", label: "Сірий"},
    {value: "#FFA07A", label: "Світло-лососевий"},
    {value: "#FFC0CB", label: "Рожевий"},
    {value: "#00008B", label: "Темно-синій"},
    {value: "#FFC0CB", label: "Оливковий"},
    {value: "#000000", label: "Чорний"}
];