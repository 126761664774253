import React from "react";
import "react-tippy/dist/tippy.css";
import {Tooltip, TooltipProps} from "react-tippy";

export type Position =
  | "top"
  | "top-start"
  | "top-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "left"
  | "left-start"
  | "left-end"
  | "right"
  | "right-start"
  | "right-end";
export type Size = "small" | "regular" | "big";
export type Theme = "dark" | "light" | "transparent";

interface IToolTip extends TooltipProps {
    children: React.ReactNode;
    msg: string;
    pos?: Position, 
    tooltipSize?: Size, 
    tooltipTheme?: Theme
}

const CustomTooltip = ({children, msg, pos = "top", tooltipSize = "big", tooltipTheme = "light"} : IToolTip) => {
    return (
        <Tooltip title={msg} position={pos} size={tooltipSize} theme={tooltipTheme} followCursor={true}>{children}</Tooltip>
    );
};

export default CustomTooltip;